import {
  CommentContainer,
  CommentLabel,
  Comment,
  ItemAccordion,
  ItemAccordionDetails,
  ItemAccordionSummary,
  MainAccordion,
  MainAccordionSummary,
  MealName,
  MealTime,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { Chip, Stack } from "@mui/material";
import {
  ArrowMonitoring,
  CheckedMonitoring,
  CommentMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import MonitoringDivider from "@views/dietician/PatientMonitoring2/components/MonitoringDivider";
import { Dispatch, useMemo } from "react";
import { useTagsUtils } from "@hooks/useTagsUtils";
import MealItemRecipe from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItemRecipe";
import MealItemProduct from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItemProduct";
import NestedAccordion from "@views/dietician/PatientMonitoring2/components/MealsBox/NestedAccordion";
import MealItemListItem from "@views/dietician/PatientMonitoring2/components/MealsBox/MealItemListItem";
import { uniq } from "lodash";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import dayjs from "dayjs";
import { useAppTranslation } from "@hooks";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import { DrawerMealItem } from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealsBoxClient";

type MealItemProp = {
  meal: Pick<PatientMealsMonitoringResource, "meals">["meals"][0];
  onClick: Dispatch<DrawerMealItem>;
  expanded: boolean;
  toggleExpanded: () => void;
};

const MealItem = ({
  meal,
  onClick,
  expanded,
  toggleExpanded,
}: MealItemProp) => {
  const { isPolishChosen } = useAppTranslation();
  const { selectedDays } = useMonitoring();

  const { getTagById } = useTagsUtils();
  const handleChangeExpanded = () => {
    toggleExpanded();
  };

  const allMealsEaten = useMemo(
    () =>
      meal.items.length &&
      !meal.items.find(i => !i.eaten) &&
      selectedDays.selectDays.from.isBefore(dayjs()),
    [meal],
  );

  const tags = useMemo(() => {
    const tagsId = uniq(meal.tags.map(tag => tag.id));
    return tagsId.map((id: number) => {
      const tag = getTagById(id);
      return (
        <Chip
          key={id}
          color="primary"
          label={isPolishChosen ? tag?.namePl : tag?.nameEn}
        />
      );
    });
  }, [meal, getTagById, isPolishChosen]);

  return (
    <Stack spacing="12px" direction="column">
      <MainAccordion
        disableGutters={true}
        expanded={expanded}
        onChange={handleChangeExpanded}
        style={{ boxShadow: "none" }}
      >
        <MainAccordionSummary>
          <Stack direction="column" flex="1" spacing="8px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing="6px">
                {allMealsEaten ? (
                  <CheckedMonitoring fill="#3AB795" size="" />
                ) : (
                  <UncheckedMonitoring fill="#F7F7F7" size="" />
                )}
                <MealName
                  style={{
                    textDecoration: !allMealsEaten ? "line-through" : "",
                  }}
                >
                  {meal.name}
                </MealName>
                <MealTime
                  style={{
                    textDecoration: !allMealsEaten ? "line-through" : "",
                  }}
                >
                  {meal.time}
                </MealTime>
              </Stack>
              <Stack direction="row" alignItems="center" spacing="6px">
                {meal.comment && <CommentMonitoring fill="#5252E5" />}
                {meal.isCustom && <Chip color="primary" label={"Własny"} />}
                {meal.isEdited && <Chip color="primary" label={"Edytowany"} />}
                {meal.items.length > 0 && (
                  <ArrowMonitoring
                    style={{
                      color: "#727272",
                      transform: expanded ? "rotate(90deg)" : "",
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </MainAccordionSummary>
      </MainAccordion>
      <ul>
        {meal.items.map(item => {
          let ItemComponent = null;

          switch (item.type) {
            case "food_diary_meal_item_recipe":
              ItemComponent = (
                <MealItemRecipe
                  item={item}
                  onClickRecipe={(id: number) =>
                    onClick({ id, type: "recipe" })
                  }
                  expanded={expanded}
                  handleChangeExpanded={handleChangeExpanded}
                />
              );
              break;
            case "food_diary_meal_item_product":
              ItemComponent = (
                <MealItemProduct
                  item={item}
                  expanded={expanded}
                  handleChangeExpanded={handleChangeExpanded}
                  onClickProduct={(id: number) =>
                    onClick({ id, type: "product" })
                  }
                />
              );
              break;
          }

          return (
            <MealItemListItem key={item.id}>{ItemComponent}</MealItemListItem>
          );
        })}
      </ul>
      {(tags.length || meal.comment) && expanded && <MonitoringDivider />}
      {tags.length > 0 && (
        <Stack direction="row" gap="8px" flexWrap="wrap">
          {tags}
        </Stack>
      )}
      {meal.comment && (
        <NestedAccordion
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
        >
          <CommentContainer>
            <CommentLabel>Komentarz</CommentLabel>
            <Comment>{meal.comment}</Comment>
          </CommentContainer>
        </NestedAccordion>
      )}
    </Stack>
  );
};

export default MealItem;
