import React, { useMemo, useState } from "react";

import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";
import { Pacman } from "@assets/icons";

import { PCFValue } from "../../../../../PCFValue";
import { RecipeProductRowWrapper } from "../RecipeProductRowWrapper.styled";
import { RecipeNameWrapper } from "../RecipeNameWrapper.styled";
import { NutrientWrapper } from "../NutrientWrapper.styled";
import { RecipeServingsEditor } from "./components/RecipeServingsEditor";
import { RecipeUsesWrapper } from "../RecipeUsesWrapper.styled";
import { SimplifiedRecipeOrProductData } from "../../RecipeChangeSidePanel";

export const RecipeModalEntry = ({
  onSelect,
  recipe,
  optionsInModal,
  uses,
}: RecipeModalEntryProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  const {
    id,
    name: namePl,
    nameEn,
    nutrients,
    isComplex,
    servings: defServings,
  } = recipe;

  const [servings, setServings] = useState(defServings || 1);

  const name = useMemo(
    () => (isPolishChosen ? namePl : nameEn ?? namePl),
    [isPolishChosen],
  );
  const modifier = useMemo(
    () => servings / (defServings || 1),
    [servings, defServings],
  );

  const handleSelect = () => {
    onSelect({ id, name, servings });
  };

  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(
    nutrients,
    modifier,
  );

  return (
    <RecipeProductRowWrapper>
      <div className="w-full">
        <RecipeNameWrapper hover onClick={handleSelect}>
          {name || t("patient.schedule.unnamed_meal")}
          {isComplex ? <Pacman className="ml-2" /> : ""}
          {!!uses && uses > 0 && (
            <RecipeUsesWrapper>
              {`${t("common.used")}: ${uses}`}
            </RecipeUsesWrapper>
          )}
        </RecipeNameWrapper>
        <div className="flex text-sm">
          <NutrientWrapper>
            <PCFValue value={kcal} name="kcal" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={protein * modifier} name="protein" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={fat * modifier} name="fats" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={carbs * modifier} name="carbs" />
          </NutrientWrapper>
        </div>
      </div>
      {isComplex && !optionsInModal && (
        <RecipeServingsEditor
          servings={servings}
          totalServings={defServings || 1}
          setServings={setServings}
        />
      )}
    </RecipeProductRowWrapper>
  );
};

export interface RecipeModalEntryProps {
  onSelect: (recipe: SimplifiedRecipeOrProductData) => void;
  optionsInModal?: boolean;
  uses?: number;
  recipe: {
    id: number;
    name: string;
    nameEn?: string;
    isComplex?: boolean;
    servings?: number;
    nutrients: Nutrient[];
  };
}
