import {
  useFetchClientsQuery,
  useFetchSchedulesDictionariesQuery,
} from "@hooks/queries";

import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useEffect, useMemo } from "react";
import { useAppTranslation, useUserRoles } from "@hooks";
import dayjs from "dayjs";
import { useAllTags } from "@hooks/useAllTags";
import { debounce } from "lodash";
import { useTasksSuggested } from "@views/dietician/Tasks/components/TasksSuggested/TasksSuggestedPanel.context";
import { useFetchClientQuery } from "@hooks/queries";
import { useTasksCountQuery } from "@hooks/queries/tasks/useTasksCountQuery";

export enum SortByEnum {
  PriorityAsc = "priority=asc",
  PriorityDesc = "priority=desc",
  DeadlineAsc = "due_date=asc",
  DeadlineDesc = "due_date=desc",
}

export enum GroupByEnum {
  Tag = "tag",
  Status = "status",
}

const adjustDateByDays = (days: number) => {
  return dayjs().add(days, "day").format("YYYY-MM-DD");
};

export const useTaskFilterOptions = () => {
  const { t } = useAppTranslation();
  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();
  const { account } = useFetchDietitianAccountQuery();
  const { allTags } = useAllTags();
  const { isClinicAdmin, isWorker } = useUserRoles();
  const { clientId } = useTasksSuggested();
  const { submit: searchClients, clients } = useFetchClientsQuery({
    enabled: !!account?.clinic?.id,
  });

  const { data: tasksCount } = useTasksCountQuery();

  const { client } = useFetchClientQuery(clientId ?? "", {
    enabled: !!clientId,
  });

  const mapDeadlineWithDate = (currentId?: string) => {
    const deadlineByDate = [
      {
        id: "today",
        label: t("tasks.deadlineByDate.today"),
        from: adjustDateByDays(0),
        to: adjustDateByDays(0),
      },
      {
        id: "thisWeek",
        label: t("tasks.deadlineByDate.thisWeek"),
        from: adjustDateByDays(0),
        to: adjustDateByDays(6),
      },
      {
        id: "indefinite",
        label: t("tasks.deadlineByDate.indefinite"),
        from: undefined,
        to: " ",
      },
      {
        id: "dateRange",
        label: t("tasks.deadlineByDate.dateRange"),
        from: null,
        to: null,
      },
    ];

    const currentDeadlineOption = deadlineByDate.find(
      ({ id }) => id === currentId,
    );

    const dueDateFrom = currentDeadlineOption?.from;
    const dueDateTo = currentDeadlineOption?.to;

    return { deadlineByDate, dueDateFrom, dueDateTo };
  };

  const { deadlineByDate } = mapDeadlineWithDate();

  const deadlineOptions = deadlineByDate.map(({ id, label }) => ({
    id,
    label,
  }));

  const sortDeadlineDirection = [
    { value: "asc", label: t("tasks.deadlineSelect.ascending") },
    { value: "desc", label: t("tasks.deadlineSelect.descending") },
  ];

  const sortByOptions = [
    { value: SortByEnum.PriorityAsc, label: t("tasks.sortBy.priorityAsc") },
    { value: SortByEnum.PriorityDesc, label: t("tasks.sortBy.priorityDesc") },
    { value: SortByEnum.DeadlineAsc, label: t("tasks.sortBy.deadlineAsc") },
    { value: SortByEnum.DeadlineDesc, label: t("tasks.sortBy.deadlineDesc") },
  ];

  const groupByOptions = [
    { value: GroupByEnum.Tag, label: t("tasks.groupBy.tag") },
    { value: GroupByEnum.Status, label: t("tasks.groupBy.status") },
  ];

  const dietitiansOptionsForAdmin = schedulesDictionaries?.dietitians.map(
    ({ id, firstName, lastName }) => ({
      id: id.toString(),
      label: `${firstName} ${lastName}`,
    }),
  );

  const dietitianOptionsForWorker = dietitiansOptionsForAdmin?.filter(
    ({ id }) => id === String(account?.id),
  );

  const dietitiansOptions = isClinicAdmin
    ? dietitiansOptionsForAdmin
    : dietitianOptionsForWorker;

  const allDietitiansIds = dietitiansOptions?.map(({ id }) => id);
  const currentDietitianId = [String(account?.id)];

  const defaultDietitianIdsForAdmin = isClinicAdmin
    ? allDietitiansIds
    : currentDietitianId;

  const defaultDietitianIds = isWorker ? defaultDietitianIdsForAdmin : [];

  const handleSearchClients = useMemo(
    () =>
      debounce((searchValue: string) => {
        searchClients({ search: searchValue });
      }, 300),
    [searchClients],
  );

  const handleClearSearchedClients = () => {
    searchClients({ search: "" });
  };

  useEffect(() => {
    if (client) {
      const fullName = `${client.firstName} ${client.lastName}`;
      searchClients({ search: fullName });
    }
  }, [client, searchClients]);

  const clientsOptions = clients?.map(({ id, firstName, lastName }) => ({
    id: id.toString(),
    label: `${firstName} ${lastName}`,
  }));

  const filteredTagsOptions = useMemo(() => {
    const tasks = tasksCount?.data;

    if (tasks?.length) {
      return tasks.map(({ tag, count }) => {
        const tagName = allTags.find(({ id }) => id === tag.id)?.name;

        return {
          id: tag.id.toString(),
          label: `${tagName} (${count})`,
        };
      });
    }
    return [];
  }, [tasksCount?.data, allTags]);

  const tagsOptions = useMemo(() => {
    if (allTags.length) {
      return allTags.map(tag => ({
        id: tag.id.toString(),
        label: `${tag.name}`,
      }));
    }
    return [];
  }, [allTags]);

  return {
    deadlineOptions,
    sortByOptions,
    groupByOptions,
    sortDeadlineDirection,
    dietitiansOptions,
    dietitiansOptionsForAdmin,
    clientsOptions,
    tagsOptions,
    mapDeadlineWithDate,
    handleSearchClients,
    handleClearSearchedClients,
    defaultDietitianIds,
    allTags,
    account,
    isClinicAdmin,
    filteredTagsOptions,
  };
};
