import { useAppTranslation } from "@hooks";

import { CountdownTimerWrapper } from "./CountdownTimer.styled";
import { TimerDisplayItem } from "./TimerDisplayItem";
import { useCountdown } from "./useCountdown";

interface CountdownProps {
  targetDate: string;
}

export const CountdownTimer = ({ targetDate }: CountdownProps) => {
  const { t } = useAppTranslation();
  const timeLeft = useCountdown(targetDate);

  return (
    <CountdownTimerWrapper>
      {Object.entries(timeLeft).map(([unit, value]) => (
        <TimerDisplayItem
          key={unit}
          value={value}
          label={t(`countdown_timer.${unit}`)}
        />
      ))}
    </CountdownTimerWrapper>
  );
};
