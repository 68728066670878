import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useLoginMutation } from "@hooks/queries";
import { getCSRFToken } from "@utils/csrf";
import { LoginForm } from "@components/forms/LoginFormNew";
import { AlloweatLayout } from "@layouts/AlloweatLayout";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, isLoading } = useLoginMutation({
    onSuccess: () => navigate("/"),
    onError: e => {
      if (!(e.response.status >= 400 && e.response.status < 500))
        toast.error(t("auth.login.api_error"));
      console.log("err", e);
    },
  });

  useEffect(() => {
    getCSRFToken();
  }, []);

  return (
    <AlloweatLayout>
      <LoginForm onSubmit={login} isLoading={isLoading} />
    </AlloweatLayout>
  );
};
