import { Button, Card, styled } from "@mui/material";

export const LaunchBannerSmallWrapper = styled(Card)`
  display: flex;
  align-items: flex-start;
  position: relative;
  background: #150d22;
  box-shadow: 0px 4px 4px 0px #00000025;
  border-radius: 16px;
  padding: 16px 32px 0;
  justify-content: space-between;
`;

export const LaunchBannerTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fontFamily.rockSalt};
  font-size: 8px;
  line-height: 30px;
  color: #efe0ff;
  letter-spacing: 2.1px;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  width: 120px;
  height: 30px;
  padding: 8px 12px 8px 12px;
  left: 38px;
  bottom: 8px;
  background: linear-gradient(91.08deg, #485ad0 1.74%, #c977f0 107.23%);
  z-index: 2;
`;

export const OverlayImg = styled("img")`
  height: 152px;
`;

export const OverlayWrapper = styled("div")`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
`;
