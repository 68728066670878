import { Card, FormControlLabel, Slider, styled } from "@mui/material";

interface WrapperProps {
  checked: boolean;
}

export const Wrapper = styled(Card)<WrapperProps>`
  display: grid;
  gap: 24px;
  width: 380px;
  height: 300px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  outline: ${({ checked, theme }) =>
    checked && `2px solid ${theme.palette.primary.main}`};
  opacity: ${({ checked }) => (checked ? 1 : 0.9)};

  &.MuiPaper-root {
    border-radius: 16px;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  height: 24px;
  margin: unset;

  & .MuiFormControlLabel-label {
    margin-right: auto;
  }
`;

export const SliderStyled = styled(Slider)`
  width: 240px;
  padding: 0;

  & .MuiSlider-thumb.MuiSlider-thumbSizeMedium {
    margin-left: 8px;
  }
  & .MuiSlider-mark {
    opacity: unset;
    &[data-index="0"] {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    &[data-index="1"],
    &[data-index="2"] {
      display: none;
    }

    &[data-index="3"] {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
      opacity: 0.38;
    }
  }
`;

export const StyledMonth = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

const Section = styled("div")`
  display: grid;
  gap: 8px;
`;

export const TopSection = styled(Section)`
  height: 108px;
`;

export const PriceSection = styled(Section)`
  height: 68px;
`;

export const SliderSection = styled(Section)`
  height: 48px;
`;

const Item = styled("div")`
  display: flex;
  align-items: center;
`;

export const SliderItem = styled(Item)`
  gap: 28px;
`;

export const QuantityItem = styled(Item)`
  gap: 16px;
`;

export const CustomersSection = styled(Item)`
  gap: 8px;
  width: 100%;
  height: 52px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 8px;
  padding: 10px 18px;
`;
