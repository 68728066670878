import { Link, useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { DuplicateFiles, Eye, Pen, TrashNew } from "@assets/icons";
import { useAppTranslation, useModal } from "@hooks";
import {
  useDeleteSurveyMutation,
  usePostSurveyCloneMutation,
} from "@hooks/queries/surveys";
import { QuestionnaireAnswerPreviewModal } from "@views/public/QuestionnaireAnswerPreviewModal/QuestionnaireAnswerPreviewModal";

import { StyledIconButton } from "./ActionButtonsComponent.styled";
import { ConfirmationModal } from "@components/ConfirmationModal";

interface ActionButtonsComponentProps {
  id: number;
  name: string;
}

export const ActionButtonsComponent = ({
  id,
  name,
}: ActionButtonsComponentProps) => {
  const { t } = useAppTranslation();
  const { mutate: deleteSurvey, isLoading: isDeleteLoading } =
    useDeleteSurveyMutation();
  const { mutate: cloneSurvey } = usePostSurveyCloneMutation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const {
    modalOpened: previewOpen,
    onModalOpen: onPreviewOpen,
    onModalClose: onPreviewClose,
  } = useModal();
  const navigate = useNavigate();

  const handleDelete = () => deleteSurvey(id);

  const handleCopy = () =>
    cloneSurvey(id.toString(), {
      onSuccess: ({ data }) => data.id && navigate(`${data.id}/edit`),
    });

  return (
    <div className="flex gap-2">
      <StyledIconButton size="small" onClick={onPreviewOpen}>
        <Eye />
      </StyledIconButton>
      <StyledIconButton size="small" onClick={handleCopy}>
        <DuplicateFiles />
      </StyledIconButton>
      <Link to={`${id}/edit`}>
        <StyledIconButton size="small">
          <Pen />
        </StyledIconButton>
      </Link>
      <StyledIconButton
        size="small"
        onClick={onModalOpen}
        disabled={isDeleteLoading}
      >
        {isDeleteLoading ? <CircularProgress size="1rem" /> : <TrashNew />}
      </StyledIconButton>

      <ConfirmationModal
        open={modalOpened}
        onCancel={onModalClose}
        onConfirm={handleDelete}
        confirmText={t("questionnaires.delete_modal_survey.button")}
        description={t("questionnaires.delete_modal_survey.desc", {
          variable: name,
        })}
        title={t("questionnaires.delete_modal_survey.title")}
      />
      <QuestionnaireAnswerPreviewModal
        id={id}
        open={previewOpen}
        onClose={onPreviewClose}
      />
    </div>
  );
};
