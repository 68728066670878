import * as yup from "yup";

import {
  ActionsDto,
  MediaExtendedDto,
  NutrientDto,
  actionsSchema,
  mediaExtendedSchema,
  nutrientSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietMealRecipe = async (
  dietId: string,
  mealId: string,
  recipeId: string,
  version: string,
): Promise<ApiResponse<CreatorDietMealRecipeDto>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/recipes/${recipeId}`,
    APIMethods.GET,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );

  return reponseSchema.validate(response);
};

export interface CreatorDietMealRecipeDto {
  id: number;
  servings: number;
  recipe: RecipeCreatorDto;
}

interface TagDto {
  id: number;
}

interface FoodFrom {
  id: number;
  description: string;
  descriptionEn: string;
}
interface FoodExchangeDto {
  foodIdTo: number;
  foodFrom: FoodFrom;
}

interface MeasureDto {
  description: string;
  descriptionEn: string;
  grams: number;
  id: number;
  sortOrder: number;
}

interface FoodDto {
  description: string;
  descriptionPl: string;
  exchange: FoodExchangeDto | null;
  id: number;
  measures: MeasureDto[];
  nutrients: NutrientDto[];
}
interface RecipeProductCreatorDto {
  id: number;
  grams: number | null;
  foodMeasureId: number;
  food: FoodDto;
}

interface RecipeCreatorDto {
  id: number;
  name: string;
  nameEn: string;
  description: string;
  descriptionEn: string;
  reviewed: boolean;
  servings: number;
  tags: TagDto[];
  nutrients: NutrientDto[];
  foodRecipe: RecipeProductCreatorDto[];
  version: number;
  media: MediaExtendedDto | null;
  favorite: number[];
  actions: ActionsDto;
}

const tagSchema = yup.object().shape({
  id: yup.number().required(),
});

const foodFromSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().defined().ensure(),
});

const foodExchangeSchema = yup.object().shape({
  foodIdTo: yup.number().required(),
  foodFrom: foodFromSchema.required(),
});

const measureSchema = yup.object().shape({
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  grams: yup.number().required(),
  id: yup.number().required(),
  sortOrder: yup.number().required(),
});

const foodSchema = yup.object().shape({
  description: yup.string().defined().ensure(),
  descriptionPl: yup.string().required(),
  exchange: foodExchangeSchema.nullable().default(null),
  id: yup.number().required(),
  measures: yup.array().of(measureSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
});

const recipeProductCreatorSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().nullable().default(null),
  foodMeasureId: yup.number().required(),
  food: foodSchema.required(),
});

const recipeCreatorSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().defined().ensure(),
  description: yup.string().defined().ensure(),
  descriptionEn: yup.string().defined().ensure(),
  reviewed: yup.boolean().required(),
  servings: yup.number().required(),
  tags: yup.array().of(tagSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  foodRecipe: yup.array().of(recipeProductCreatorSchema).required(),
  version: yup.number().required(),
  media: mediaExtendedSchema.nullable(),
  favorite: yup.array().of(yup.number().required()).required(),
  actions: actionsSchema.required(),
});

const creatorDietMealRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: recipeCreatorSchema.required(),
});

const reponseSchema = yup.object().shape({
  data: creatorDietMealRecipeSchema.required(),
});
