import { RecipePreview } from "@components/PreviewDrawer/Recipe";
import { StyledDrawer } from "./RecipePreviewDrawer.styled";
import { ActionButtons } from "./ActionButtons";

interface RecipePreviewDrawerProps {
  onClose: () => void;
  onEdit: () => void;
  recipeId: string | null;
  open: boolean;
}
export const RecipePreviewDrawer = ({
  recipeId,
  onClose,
  onEdit,
  open,
}: RecipePreviewDrawerProps) => {
  const recipeIdParsed = recipeId ? Number(recipeId) : null;

  return (
    <StyledDrawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
    >
      <RecipePreview
        recipeId={recipeIdParsed}
        onClose={onClose}
        ActionButtons={({ hasImg }) => (
          <ActionButtons onClose={onClose} onEdit={onEdit} hasImg={hasImg} />
        )}
      />
    </StyledDrawer>
  );
};
