import { useMemo } from "react";

import { useTheme } from "@mui/material";

import { FlameFilled, FlameOutlined } from "@assets/icons";
import { ArrowInCircle } from "@assets/icons/Arrows";
import { useAppTranslation, useNutrients } from "@hooks";
import { ENERGY_ID, MACROS_ARRAY } from "@utils/macros";

import {
  LetterIcon,
  LinearProgressStyled,
  NutrientLabel,
  Text,
  ValueText,
} from "./Chart.styled";
import { round } from "lodash";

interface ChartProps {
  value?: number;
  target?: number;
  id: number;
}

export const Chart = ({ id, value = 0, target = 1 }: ChartProps) => {
  const { t } = useAppTranslation();
  const {
    palette: { error },
    colors: { neutral },
  } = useTheme();
  const { nutrientDict } = useNutrients();
  const nutrient = nutrientDict.get(id);
  const isMacro = MACROS_ARRAY.includes(id);
  const percentage = (value / target) * 100;
  const valueTooLow = value - target < 0;

  const getColor = (value: number, target: number) => {
    if (value >= target * 0.95 && value <= target * 1.05) return "success";
    if (value <= target * 1.1) return "warning";
    return "error";
  };

  const color = getColor(value, target);
  const icon = useMemo(() => {
    if (id === ENERGY_ID)
      return <FlameFilled fill={neutral.dark[700]} size="w-[16px] h-[16px]" />;

    return <LetterIcon color={color}>{nutrient?.short}</LetterIcon>;
  }, [id, color, nutrient?.short]);

  const getValueStatusText = (value: number, target: number, unit?: string) => {
    const difference = value - target;
    const tolerance = target * 0.05;

    if (difference >= -tolerance && difference <= tolerance)
      return t("diet.value_alert.well_done");
    if (difference > tolerance)
      return `${t("diet.value_alert.exceeded_by")} ${Math.abs(
        round(target - value),
      )} ${unit}`;
    return `${t("diet.value_alert.left")} ${Math.abs(
      round(target - value),
    )} ${unit}`;
  };
  const valueStatusText = useMemo(
    () => getValueStatusText(value, target, nutrient?.unit),
    [value, target, nutrient, t],
  );
  return (
    <div className="grid gap-[8px] flex-1 max-w-[320px]">
      <div className="flex gap-[6px] items-center">
        {isMacro && icon}
        {!isMacro && <NutrientLabel>{nutrient?.name}:</NutrientLabel>}

        <ValueText>{round(value)}</ValueText>
        <Text>/ {target}</Text>

        {color === "error" && (
          <ArrowInCircle
            fill={error.main}
            className={valueTooLow ? "" : "rotate-180"}
          />
        )}
      </div>

      <LinearProgressStyled
        value={Math.min(percentage, 100)}
        variant="determinate"
        color={color}
      />

      {isMacro && <Text color={color}>{valueStatusText}</Text>}
    </div>
  );
};
