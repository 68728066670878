import { SingleCardPageLayout } from "@components/PageLayout";
import { GridColumns } from "@mui/x-data-grid";
import { ThemeProviderWrapperNew } from "themeNew";
import { ActionButtonsComponent, QuestionnaireName } from "../components";
import { Button, Chip, useMediaQuery, useTheme } from "@mui/material";
import { useAppTranslation, useModal } from "@hooks";
import { useFetchSurveysQuery } from "@hooks/queries/surveys/useFetchSurveysQuery";
import { SurveyDto } from "@client/surveys/fetchSurveys";
import { useMemo, useState } from "react";
import { AddQuestionnaireModal } from "../AddQuestionnaireModal/AddQuestionnaireModal";
import { GridPaginationWrapper } from "@components/PaginationNew";
import {
  QuestionnaireListWrapper,
  StyledMuiDataGrid,
} from "./QuestionnairesList.styled";
import { PlusRounded } from "@assets/icons/PlusRounded";

export const DEFAULT_PER_PAGE = 10;

export const QuestionnairesList = () => {
  const { t } = useAppTranslation();
  const { surveys } = useFetchSurveysQuery();
  const { breakpoints } = useTheme();
  const isUpMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);
  const isOnePage = !!surveys && surveys.length <= DEFAULT_PER_PAGE;

  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("questionnaires.name"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: 240,
      flex: isUpMedium ? 1 : 2,
      renderCell: params => (
        <QuestionnaireName
          name={params.row.name}
          questionsAmount={params.row.questions}
          id={params.row.id}
        />
      ),
    },
    {
      field: "lang",
      headerName: t("questionnaires.language"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: 80,
      flex: 0.5,
      renderCell: params => (
        <div className="pl-2">
          <Chip label={t(`common.${params.row.lang}`)} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: t("questionnaires.actions"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: 168,
      headerAlign: "right",
      align: "right",
      renderCell: params => (
        <ActionButtonsComponent id={params.row.id} name={params.row.name} />
      ),
    },
  ];

  const mappedRows = useMemo(() => mapGridRows(surveys), [surveys]);

  return (
    <ThemeProviderWrapperNew>
      <QuestionnaireListWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("questionnaires.title")}
          childrenDefaultClasses={false}
          extra={
            <Button
              variant="contained"
              onClick={onModalOpen}
              startIcon={<PlusRounded size="w-3 h-3" />}
            >
              {t("questionnaires.add")}
            </Button>
          }
        >
          <StyledMuiDataGrid
            pageSize={perPage}
            columns={columns}
            rows={mappedRows}
            autoHeight
            getRowSpacing={() => ({
              top: 8,
            })}
            rowHeight={58}
            disableSelectionOnClick
            components={{ Pagination: GridPaginationWrapper }}
            componentsProps={{
              pagination: { setPerPage, perPage, isOnePage },
            }}
          />
          <AddQuestionnaireModal onClose={onModalClose} open={modalOpened} />
        </SingleCardPageLayout>
      </QuestionnaireListWrapper>
    </ThemeProviderWrapperNew>
  );
};

interface SurveyGridRow {
  id: number;
  name: string;
  questions: number;
  lang: "pl" | "en";
}
const mapGridRows = (surveys: SurveyDto[] | undefined): SurveyGridRow[] => {
  if (!surveys || !surveys.length) return [];
  return surveys.map(({ id, langId, name, numberOfQuestions }) => {
    return {
      id: id,
      lang: langId,
      name: name,
      questions: numberOfQuestions,
    };
  });
};
