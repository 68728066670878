import { RadioGroup } from "@mui/material";
import { SubscriptionCard } from "../SubscriptionCard";
import { useEffect, useState } from "react";
import { PaymentType } from "@utils";

interface SubscriptionTabViewProps {
  paymentType: PaymentType;
  setFlexValue?: (value: number) => void;
  setType: (value: string) => void;
}

export const SubscriptionTabView = ({
  paymentType,
  setFlexValue,
  setType,
}: SubscriptionTabViewProps) => {
  enum PLAN_TYPE {
    FLEX = "flex",
    UNLIMITED = "unlimited_growth",
  }
  const [value, setValue] = useState(PLAN_TYPE.FLEX);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as PLAN_TYPE);
  };

  useEffect(() => {
    setType(value);
  }, [value]);

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <div className="flex gap-[24px]">
        <SubscriptionCard
          label="Unlimited Growth"
          value={PLAN_TYPE.UNLIMITED}
          checked={value === PLAN_TYPE.UNLIMITED}
          paymentType={paymentType}
        />
        <SubscriptionCard
          current
          label="Flex"
          value={PLAN_TYPE.FLEX}
          checked={value === PLAN_TYPE.FLEX}
          paymentType={paymentType}
          setFlexValue={setFlexValue}
        />
      </div>
    </RadioGroup>
  );
};
