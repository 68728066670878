import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  updateCreatorMealTags,
  UpdateCreatorMealTagsRequest,
} from "@client/meals";
import { MutationOptions, fetchCreatorMealQueryKey } from "@hooks/queries";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { ApiResponse } from "@typeDefinitions";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useUpdateCreatorMealTagsMutation = (
  mealId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const version = useMealVersion();

  const mutation = useMutation<unknown, unknown, UpdateCreatorMealTagsRequest>(
    payload => updateCreatorMealTags(mealId, payload, version),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
      },
      onMutate: async () => {
        await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old.data,
                version: old.data.version + 1,
              },
            };
          },
        );
      },
    },
  );

  return mutation;
};
