import { Stack } from "@mui/material";
import {
  ItemImage,
  ItemName,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { SyntheticEvent, useCallback } from "react";
import NestedAccordion from "@views/dietician/PatientMonitoring2/components/MealsBox/NestedAccordion";
import Macros from "@views/dietician/PatientMonitoring2/components/MealsBox/Macros";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { ItemRecipe } from "@client/resources/DietitianMealsMonitoringResource";

type MealItemRecipeProps = {
  item: ItemRecipe;
  onClickRecipe: (id: number) => void;
  expanded: boolean;
  handleChangeExpanded: (event: SyntheticEvent, isExpanded: boolean) => void;
};
const MealItemRecipe = ({
  item,
  onClickRecipe,
  expanded,
  handleChangeExpanded,
}: MealItemRecipeProps) => {
  const itemRecipe = item.item;
  const getNutrientValue = useCallback(
    (id: number) =>
      Math.round(
        itemRecipe.recipe.nutrients.find(n => n.id === id)?.value ?? 0,
      ),
    [itemRecipe],
  );

  return (
    <Stack direction="column" spacing="8px">
      <ItemName onClick={() => onClickRecipe(itemRecipe.recipe.id)}>
        {itemRecipe.recipe.name}
      </ItemName>
      <NestedAccordion
        expanded={expanded}
        handleChangeExpanded={handleChangeExpanded}
      >
        <Stack direction="column" spacing="8px">
          <Macros
            kcal={getNutrientValue(ENERGY_ID)}
            protein={getNutrientValue(PROTEIN_ID)}
            fat={getNutrientValue(FATS_ID)}
            carb={getNutrientValue(CARBS_ID)}
          />
          <ItemImage src={itemRecipe.recipe.image ?? undefined} />
        </Stack>
      </NestedAccordion>
    </Stack>
  );
};

export default MealItemRecipe;
