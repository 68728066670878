import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

import {
  DietitianProgramDay,
  OptimizerSettingsBase,
  RecipeColor,
  VisibleNutrient,
} from "@typeDefinitions";
import { useAppParams, useAppTranslation } from "@hooks";
import { DraggedRecipeData } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid";
import { DietitianProgramScheduleDay } from "@client/schedule";

import { ScheduleGridDay } from "../ScheduleGridDay/ScheduleGridDay";
import { ScheduleGridSummary } from "../ScheduleGridSummary/ScheduleGridSummary";
import {
  ScheduleGridItem,
  UsedRecipe,
} from "../ScheduleGridItem/ScheduleGridItem";
import { ScheduleGridRowWrapper } from "./ScheduleGridRowWrapper.styled";

interface ScheduleGridRowProps {
  programId: number;
  scheduleDay: DietitianProgramScheduleDay;
  color: string;
  setDraggedRecipe?: (draggedRecipe?: DraggedRecipeData) => void;
  draggedRecipe?: DraggedRecipeData;
  recipeColors: RecipeColor[];
  dietDay?: DietitianProgramDay;
  readOnly?: boolean;
  variantName?: string;
  selectedVariant?: string | number;
  optimizerSettings?: OptimizerSettingsBase[];
  normId?: number;
  targetNutrients?: VisibleNutrient[];
  usedRecipes?: UsedRecipe[];
  startDate?: string;
}

export const ScheduleGridRow = (props: ScheduleGridRowProps) => {
  const {
    color,
    scheduleDay,
    draggedRecipe,
    recipeColors,
    dietDay,
    readOnly,
    programId,
    variantName,
    selectedVariant,
    optimizerSettings,
    targetNutrients,
    normId,
    usedRecipes,
    setDraggedRecipe,
    startDate,
  } = props;

  const { patientId } = useAppParams();
  const {
    id: dayId,
    date,
    programDay,
    meals,
    nutrients,
    actions: { canCopy },
  } = scheduleDay;
  const { t } = useAppTranslation();
  const pastDate =
    !!patientId &&
    dayjs(dayjs().endOf("day")).isAfter(dayjs(date).endOf("day"));

  return (
    <ScheduleGridRowWrapper>
      <ScheduleGridDay
        programId={programId}
        programDayId={programDay.id}
        id={dayId}
        date={date}
        dayName={dietDay?.name || ""}
        dayNameEn={dietDay?.nameEn || dietDay?.name || ""}
        color={color}
        normId={normId}
        scheduleDay={scheduleDay}
        recipeColors={recipeColors}
        readOnly={readOnly}
        pastDate={pastDate}
        variantName={variantName}
        selectedVariant={selectedVariant}
        optimizerSettings={optimizerSettings}
        startDate={startDate}
        canCopy={canCopy}
      />
      <div className="w-full flex">
        {meals?.map(meal => (
          <Tooltip
            key={meal.id}
            placement="top"
            title={t("common.day_passed")}
            disableInteractive={!pastDate}
            disableHoverListener={!pastDate}
            disableFocusListener={!pastDate}
            disableTouchListener={!pastDate}
          >
            <div className={pastDate ? "opacity-60" : ""}>
              <ScheduleGridItem
                canCopy={canCopy}
                meal={meal}
                dietId={programDay.diet.id ?? 0}
                programId={programId}
                programDayId={programDay.id}
                dayId={dayId}
                dayDate={date}
                color={color}
                setDraggedRecipe={setDraggedRecipe}
                draggedRecipe={draggedRecipe}
                usedRecipes={usedRecipes}
                recipeColor={
                  meal.recipe?.id
                    ? recipeColors.find(
                        color => color.recipeName === meal.recipe?.title,
                      )
                    : undefined
                }
                readOnly={readOnly || !meal.id}
                pastDate={pastDate}
              />
            </div>
          </Tooltip>
        ))}
      </div>
      <ScheduleGridSummary
        normId={normId}
        nutrients={nutrients}
        targetNutrients={targetNutrients}
      />
    </ScheduleGridRowWrapper>
  );
};
