import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import {
  mapRecipeRequest,
  RecipeEditInput,
  useRecipeEditForm,
} from "@components/RecipeForm/useRecipeEditForm";
import { useCreateRecipeMutation } from "@hooks/queries";

import { RecipeFormWrapper } from "./RecipeEdit.styled";

export const RecipeNew = () => {
  const [init, setInit] = useState(true);
  const navigate = useNavigate();

  const newRecipe: RecipeEditInput = {
    namePl: "Nowy przepis",
    nameEn: "",
    tags: [],
    ingredients: [],
    reviewed: false,
    numOfPortions: "1",
    mediaId: null,
    descriptionEn: "",
    descriptionPl: "",
  };
  const {
    handleSubmit,
    formState: { isValid },
  } = useRecipeEditForm(newRecipe);

  const { mutate, isLoading, isError } = useCreateRecipeMutation({
    onSuccess: data => {
      setInit(false);
      navigate(`/recipes/${data?.id}/edit`, { replace: true });
    },
  });

  useEffect(() => {
    if (init && isValid)
      handleSubmit(
        data => mutate({ payload: mapRecipeRequest(data, null, null) }),
        err => console.log(err),
      )();
  }, [init, isValid]);

  if (init || isLoading)
    return (
      <RecipeFormWrapper>
        <Spinner />
      </RecipeFormWrapper>
    );

  if (isError)
    return (
      <RecipeFormWrapper>
        <ApiError />
      </RecipeFormWrapper>
    );

  return <></>;
};
