import { Dispatch, SetStateAction } from "react";
import { RecipeWrapperDto } from "@client/meals";
import {
  RecipeWrapperIf,
  SelectedRecipeEdit,
} from "@views/dietician/MealEdit/components/SelectedRecipeEdit";
import { useFetchCreatorRecipeQuery } from "@hooks/queries/meals/useFetchCreatorRecipeQuery";
import { useAppParams } from "@hooks";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

interface SelectedRecipeEditProxyProps {
  openedRecipes: number[];
  setOpenedRecipes: Dispatch<SetStateAction<number[]>>;
  recipeWrapper: RecipeWrapperDto;
  version?: number;
}

export const SelectedRecipeEditProxy = ({
  recipeWrapper,
  ...rest
}: SelectedRecipeEditProxyProps) => {
  const { mealId } = useAppParams();

  const isPlaceholder = !!recipeWrapper.recipe.isPlaceholder;
  const { recipe, isLoading, isError } = useFetchCreatorRecipeQuery(
    mealId,
    recipeWrapper.id.toString(),
    { enabled: isPlaceholder },
  );

  if (!isPlaceholder)
    return (
      <SelectedRecipeEdit
        {...rest}
        recipeWrapper={recipeWrapper as RecipeWrapperIf}
      />
    );

  if (isLoading) return <Spinner />;

  if (isError || !recipe) return <ApiError />;

  return <SelectedRecipeEdit {...rest} recipeWrapper={recipe} />;
};
