import { QrCodeAppleMate, QrCodeGoogleMate } from "@assets/icons";
import img from "@assets/img/AlloweatMateTablet.png";
import AppStore from "@assets/img/AppStoreDownload.png";
import GooglePlay from "@assets/img/GooglePlayDownload.png";
import arrow from "@assets/img/TwistedArrow.png";
import { useAppTranslation } from "@hooks";
import {
  ArrowImg,
  LaunchBannerContent,
  LaunchBannerSmallWrapper,
  LaunchBannerTitle,
  QrCodeSection,
  QrCodeSectionWrapper,
  QrCodeWrapperSmall,
  StyledImg,
} from "./LaunchBannerSmall.styled";
import { RoadShowCardMobile } from "../RoadShowCard";

export const LaunchBannerSmall = () => {
  const { t } = useAppTranslation();
  return (
    <>
      <RoadShowCardMobile />

      <LaunchBannerSmallWrapper>
        <StyledImg src={img} alt="" />
        <div className="ml-[22px] text-start">
          <LaunchBannerTitle>{t("launch_banner.title")}</LaunchBannerTitle>
          <LaunchBannerContent>
            {t("launch_banner.content")}
          </LaunchBannerContent>
        </div>
        <QrCodeSectionWrapper>
          <ArrowImg src={arrow} alt="twisted arrow" className="mt-[8px]" />
          <QrCodeSection>
            <QrCodeWrapperSmall>
              <img src={AppStore} alt="apple store button" />
              <QrCodeAppleMate size="w-[64px] h-[64px]" />
            </QrCodeWrapperSmall>
            <QrCodeWrapperSmall>
              <img src={GooglePlay} alt="google play button" />
              <QrCodeGoogleMate size="w-[64px] h-[64px]" />
            </QrCodeWrapperSmall>
          </QrCodeSection>
        </QrCodeSectionWrapper>
      </LaunchBannerSmallWrapper>
    </>
  );
};
