import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { usePostCreateMealMutation } from "@hooks/queries/meals";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

import {
  MealNameInputs,
  createMealNameRequest,
  useMealNameForm,
} from "./forms";

export const MealNew = () => {
  const [init, setInit] = useState(true);
  const navigate = useNavigate();
  const { mutate, isLoading, isError } = usePostCreateMealMutation({
    onSuccess: data => {
      setInit(false);
      navigate(`/meals/${data.data.id}/edit`, { replace: true });
    },
  });
  const newMeal: MealNameInputs = {
    namePl: "Nowy posiłek",
    nameEn: "",
    reviewed: false,
  };
  const {
    handleSubmit,
    formState: { isValid },
  } = useMealNameForm(newMeal);

  useEffect(() => {
    if (init && isValid)
      handleSubmit(
        data => mutate(createMealNameRequest(data)),
        err => console.log(err),
      )();
  }, [init, isValid]);

  if (init || isLoading) return <Spinner />;

  if (isError) return <ApiError />;

  return <></>;
};
