import { ReactNode } from "react";
import { FormProvider } from "react-hook-form";

import { InputAdornment } from "@mui/material";
import { omit } from "lodash";

import { Loupe } from "@assets/icons";
import { ApiError } from "@components/ApiError";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { Pagination } from "@components/Pagination";
import { ProductsFiltersNew } from "@components/ProductsFiltersNew";
import {
  ProductsFiltersFormInput,
  useProductsFiltersForm,
} from "@components/ProductsFiltersNew/useProductsFiltersForm";
import { DEFAULT_MACROS_RANGE } from "@components/RecipesFiltersNew";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { Spinner } from "@components/Spinner";
import { useAppTranslation } from "@hooks";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";
import { clearFormInputs } from "@utils";
import { ProductInputElement } from "@views/dietician/Diet/components/MealsRecipesTabs/MealsTab";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";

import { ThemeProviderWrapperNew } from "themeNew";
import { HeaderWrapper, ResultsHeader } from "./SearchListElement.styled";

interface ProductsSearchViewProps {
  actionComponent: ReactNode;
  disabledProducts: number[];
}

export const ProductsSearchView = ({
  actionComponent,
  disabledProducts,
}: ProductsSearchViewProps) => {
  const { t } = useAppTranslation();
  const form = useProductsFiltersForm();
  const { products, submit, isLoading, isFetching, isError, meta } =
    useSearchFoodQuery(undefined, { disableParams: true });

  const handleClearInputs = () => clearFormInputs(form.reset, submit);

  const handleSubmitForm = (data: ProductsFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <FormProvider {...form}>
          <ThemeProviderWrapperNew>
            <div className="flex gap-[4px]">
              <FormTextFieldClearableStyled
                name="query"
                size="small"
                placeholder={t("filters.search_product_or_producer")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Loupe />
                    </InputAdornment>
                  ),
                }}
              />
              <ProductsFiltersNew
                total={meta?.total}
                onSubmit={values => submit(values)}
              />
            </div>
          </ThemeProviderWrapperNew>
          <AutosaveWatchComponent
            isLoading={isFetching}
            onSubmit={handleSubmitForm}
            isDirtyCondition={false}
          />
        </FormProvider>

        {actionComponent}
      </div>

      {isLoading && <Spinner />}

      {isError && <ApiError />}

      <HeaderWrapper>
        {!!products?.length && (
          <ResultsHeader>{t("meal.search_results")}:</ResultsHeader>
        )}

        {!products?.length && !isLoading && (
          <FiltersNoResults onClick={handleClearInputs} />
        )}
      </HeaderWrapper>

      {products?.map(product => (
        <ProductInputElement
          product={{
            ...product,
            clinicId: product.clinicId ?? undefined,
            nameEn: product.nameEn ?? undefined,
          }}
          key={product.id}
          disabled={disabledProducts.includes(product.id)}
        />
      ))}

      {products && products.length > 0 && meta?.links && (
        <Pagination
          links={meta.links}
          onClick={(url, page) => submit({ page })}
        />
      )}
    </>
  );
};
