import { useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { ApiResponse } from "@typeDefinitions";
import { ClientSearchInput } from "@typeDefinitions/types";
import { parseNumber, parseString } from "@utils/filters";
import { ClientDto, fetchClients } from "@client";

export const fetchClientsQueryKey = "fetchClientsQueryKey";

export const useFetchClientsQuery = (
  options?: QueryOptionsTyped<ApiResponse<ClientDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] = useState<ClientSearchInput>();

  const params = useMemo(
    () => parseClinicClientsSearch(committedInputs),
    [committedInputs],
  );

  const { data, ...rest } = useQuery(
    [fetchClientsQueryKey, params.toString()],
    () => fetchClients(params),
    buildDefaultQueryOptions(options),
  );
  return {
    clients: data?.data,
    submit: setCommittedInputs,
    ...rest,
  };
};

const parseClinicClientsSearch = (filters?: ClientSearchInput) => {
  const search = new URLSearchParams();
  parseNumber(filters?.active, "active", search);

  if (filters?.search?.trim()) {
    parseString(filters?.search, "search", search);
  }

  return search;
};
