import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Box, Dialog, Tooltip } from "@mui/material";

import {
  useAppTranslation,
  useClientParams,
  useUpdateClientMobileAppAccess,
  useUpdateClientPayments,
} from "@hooks";
import {
  fetchClientQueryKey,
  fetchClientsQueryKey,
  fetchPatientProgramQueryKey,
  useFetchClientQuery,
  useUpdateClientChatMutation,
  useUpdateClientFoodCreatorMutation,
  useUpdateClientRecipeBaseMutation,
} from "@hooks/queries";

import { Close } from "@assets/icons";

import {
  CancelButton,
  CloseButton,
  EditClientModalWrapper,
  StyledDialogActions,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  Title,
  TitleWrapper,
} from "./EditClientPaymentsModal.styled";
import { LoadingButton, TabContext } from "@mui/lab";
import {
  ClientPaymentsView,
  paymentsOptions,
} from "./components/ClientPaymentsView";
import { ClientMobileAppView } from "./components/ClientMobileAppView";
import {
  EditClientPaymentsFormInput,
  mapEditClientPaymentsForm,
  useEditClientPaymentsForm,
} from "./useEditClientPaymentsForm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { convertToUTC } from "@hooks/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { fetchClientEventsQueryKey } from "@hooks/queries/useFetchClientEventsQuery";
dayjs.extend(utc);

interface EditClientModalProps {
  open: boolean;
  onClose: () => void;
}

export const EditClientPaymentsModal = ({
  open,
  onClose,
}: EditClientModalProps) => {
  const { t } = useAppTranslation();

  const id = useClientParams();
  const { client } = useFetchClientQuery(id);
  const queryClient = useQueryClient();
  if (!client) return <></>;

  const clientsMapped = useMemo(
    () => mapEditClientPaymentsForm(client),
    [client],
  );

  const { control, handleSubmit, reset, watch } =
    useEditClientPaymentsForm(clientsMapped);

  enum ClientPaymentsTabs {
    COOPERATION = "1",
    MOBILE_APP = "2",
  }

  const email = client.email;
  const { mutate, isLoading } = useUpdateClientPayments({
    onSuccess: () => {
      null;
    },
  });
  const { mutate: mutateChat } = useUpdateClientChatMutation(id, {
    onSuccess: () => {
      null;
    },
  });

  const { mutate: mutateRecipeBase } = useUpdateClientRecipeBaseMutation(id, {
    onSuccess: () => {
      null;
    },
  });

  const { mutate: mutateFoodCreator } = useUpdateClientFoodCreatorMutation(id, {
    onSuccess: () => {
      null;
    },
  });
  const { mutate: mutateMobileAppAccess } = useUpdateClientMobileAppAccess({
    onSuccess: () => {
      null;
    },
  });

  const submit = useCallback(
    (data: EditClientPaymentsFormInput) => {
      mutate(
        {
          id: id.toString(),
          payload: {
            activeUntil:
              data.indefinite === paymentsOptions.INDEFINITE
                ? null
                : convertToUTC(data.activeUntil),
            isActive: true,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([fetchClientQueryKey, id]);
            onCloseWithReset();
          },
        },
      );
      if (!email || !client.active) return;
      mutateMobileAppAccess({
        id: id.toString(),
        payload: { hasAccess: data.isMobileAppActive },
      });
      mutateChat({ isActive: data.isMobileAppActive ? data.chat : false });
      mutateRecipeBase({
        alloweat: data.isMobileAppActive ? data.recipeAlloweat : false,
        clinic: data.isMobileAppActive ? data.recipeClinic : false,
      });
      mutateFoodCreator(
        { isActive: data.isMobileAppActive },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([fetchClientQueryKey, id]);
            queryClient.invalidateQueries([fetchPatientProgramQueryKey, id]);
            queryClient.invalidateQueries([
              fetchClientEventsQueryKey,
              id.toString(),
            ]);
            queryClient.invalidateQueries([fetchClientsQueryKey]);
          },
        },
      );
    },
    [
      mutateChat,
      mutateRecipeBase,
      mutateFoodCreator,
      mutateMobileAppAccess,
      mutate,
      email,
      client.active,
    ],
  );

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    open && setTab(ClientPaymentsTabs.COOPERATION);
  }, [open]);

  const [tab, setTab] = useState(ClientPaymentsTabs.COOPERATION);

  const handleChange = (
    event: SyntheticEvent,
    newValue: ClientPaymentsTabs,
  ) => {
    setTab(newValue);
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <EditClientModalWrapper>
        <TitleWrapper>
          <Title>
            {t("client_profile.edit.payments.payments_and_services")}
          </Title>
          <CloseButton size="small" onClick={onCloseWithReset}>
            <Close size="w-3 h-3" />
          </CloseButton>
        </TitleWrapper>
        <TabContext value={tab}>
          <Box className="mb-2">
            <StyledTabList onChange={handleChange}>
              <StyledTab
                disabled={!client?.active}
                label={t("client_profile.edit.payments.cooperation")}
                value={ClientPaymentsTabs.COOPERATION}
              />
              {!email ? (
                <Tooltip
                  placement="top"
                  title={t(
                    "client_profile.edit.payments.mobile_app_access_tooltip",
                  )}
                  disableHoverListener={!!email}
                  arrow
                >
                  <div>
                    <StyledTab
                      disabled={!client?.active || !email}
                      label={t("client_profile.edit.payments.mobile_app")}
                      value={ClientPaymentsTabs.MOBILE_APP}
                    />
                  </div>
                </Tooltip>
              ) : (
                <StyledTab
                  disabled={!client?.active || !email}
                  label={t("client_profile.edit.payments.mobile_app")}
                  value={ClientPaymentsTabs.MOBILE_APP}
                />
              )}
            </StyledTabList>
          </Box>
          <StyledTabPanel value={ClientPaymentsTabs.COOPERATION}>
            <ClientPaymentsView control={control} />
          </StyledTabPanel>
          <StyledTabPanel value={ClientPaymentsTabs.MOBILE_APP}>
            <ClientMobileAppView control={control} />
          </StyledTabPanel>
        </TabContext>
      </EditClientModalWrapper>
      <StyledDialogActions>
        <CancelButton variant="outlined" onClick={onCloseWithReset}>
          {t("common.cancel")}
        </CancelButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(submit, e => console.log("e", e))}
        >
          {t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};
