import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LangDto, RegisterClinicRequest } from "@client";
import { useAppTranslation } from "@hooks";
import { PaymentType, emailSchemaFactory } from "@utils";
import { useSearchParams } from "react-router-dom";

export const useRegisterClinicForm = () => {
  const { t, currentLanguage } = useAppTranslation();

  const [params] = useSearchParams();
  const paymentType = params.get("subscription-paid") as PaymentType;
  const subscriptionType = params.get("subscription-type");

  const resolver = yup.object().shape({
    firstName: yup.string().required(t("auth.register_clinic.validation.name")),
    lastName: yup
      .string()
      .required(t("auth.register_clinic.validation.last_name")),
    email: emailSchemaFactory(
      t("auth.register_clinic.validation.incorrect_email"),
    ).required(t("auth.register_clinic.validation.email")),
    policy: yup
      .boolean()
      .oneOf([true], t("auth.register_clinic.validation.policy"))
      .required(),
    lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  });

  return useForm<RegisterClinicFormInput>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      policy: false,
      paymentType: paymentType ?? null,
      subscriptionType: subscriptionType ?? null,
      lang: (currentLanguage as unknown as LangDto) ?? LangDto.PL,
    },
    resolver: yupResolver(resolver),
  });
};

export interface RegisterClinicFormInput {
  firstName: string;
  lastName: string;
  email: string;
  policy: boolean;
  paymentType: PaymentType | null;
  subscriptionType: string | null;
  lang: LangDto;
}

export const mapRegisterClinicFormRequest = (
  data: RegisterClinicFormInput,
): RegisterClinicRequest => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email ?? null,
    termsOfService: Number(data.policy),
    paymentType: data.paymentType,
    subscriptionType: data.subscriptionType,
    lang: data.lang,
  };
};
