import { useContext } from "react";
import { useLocalStorage } from "react-use";

import { useAppTranslation } from "@hooks";
import { ArrowDown } from "@assets/icons";
import { ScheduleViews } from "@typeDefinitions";
import { ProgramScheduleNavigationContext } from "@views/dietician/ProgramSchedule/context";
import { RadioTileGroup } from "@components/RadioTileGroup";
import { RadioTile } from "@components/RadioTile";
import { IconButton } from "@components/IconButton";

import { ButtonsWrapper } from "./ScheduleRangeSwitch.styled";

export const ScheduleRangeSwitch = () => {
  const {
    view,
    setView,
    onNextPage,
    onPrevPage,
    isNextPage,
    isPrevPage,
    navigationLabel,
  } = useContext(ProgramScheduleNavigationContext);

  const { t } = useAppTranslation();
  const [checkedDay, setCheckedDay] = useLocalStorage(
    "scheduleCheckedDay",
    ScheduleViews.WEEK,
  );

  const handleScheduleModeChange = (value: string | number | undefined) => {
    if (typeof value === "number") {
      setView(value as ScheduleViews);
      setCheckedDay(value);
    }
  };

  return (
    <div className="flex items-center">
      <p className="mr-4">{t("common.view")}</p>
      <RadioTileGroup
        name="visitsView"
        variant="outlined"
        defaultValue={checkedDay}
        onChange={handleScheduleModeChange}
        className="step-one"
      >
        <RadioTile
          label={`7 ${t("common.days")}`}
          value={ScheduleViews.WEEK}
          className="h-10"
        />
        <RadioTile
          label={`14 ${t("common.days")}`}
          value={ScheduleViews.TWO_WEEKS}
          className="h-10"
        />
        <RadioTile
          label={`28 ${t("common.days")}`}
          value={ScheduleViews.FOUR_WEEKS}
          className="h-10"
        />
      </RadioTileGroup>
      <ButtonsWrapper className="step-two">
        <IconButton disabled={!isPrevPage} onClick={onPrevPage}>
          <ArrowDown className="transform rotate-180" />
        </IconButton>
        {navigationLabel}
        <IconButton disabled={!isNextPage} onClick={onNextPage}>
          <ArrowDown />
        </IconButton>
      </ButtonsWrapper>
    </div>
  );
};
