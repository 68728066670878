import { useSearchParams } from "react-router-dom";

export const useTrackUserForm = (enabled = false) => {
  const [searchParams] = useSearchParams();
  const { href } = window.location;
  const utmContent = searchParams.get(UTM_CONTENT);
  const utmSource = searchParams.get(UTM_SOURCE);
  const utmMedium = searchParams.get(UTM_MEDIUM);
  const utmCampaign = searchParams.get(UTM_CAMPAIGN);

  interface UserDataIf {
    firstName: string;
    lastName: string;
    email: string;
    formId: number;
    userId: number;
  }

  const handleRegisterUser = ({
    firstName,
    lastName,
    email,
    formId,
    userId,
  }: UserDataIf) => {
    if (!enabled) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.userengage && window?.UE?.resetAuth) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.UE.resetAuth({
        apiKey: "kjcaol",
        name: `${firstName} ${lastName}`,
        user_id: userId,
        email,
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.userengage("event.autorejestracja-formularz", {
        first_name: firstName,
        last_name: lastName,
        email,
        form_id: formId,
        utm_content: utmContent,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        from_url: href,
      });
    }
  };

  return {
    register: handleRegisterUser,
  };
};

const UTM_CONTENT = "utm_content";
const UTM_SOURCE = "utm_source";
const UTM_MEDIUM = "utm_medium";
const UTM_CAMPAIGN = "utm_campaign";

export const REGISTER_CLINIC_FORM_ID = 1090;
