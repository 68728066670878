import { FormAutocomplete } from "@components/FormAutocomplete";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { FormTextField } from "@components/FormTextField";
import { IconButton, styled } from "@mui/material";

export const StyledButton = styled(IconButton)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: ${({ theme }) => `1px solid ${theme.palette.primary.medium}`};
`;

interface ClientsAutocompleteProps {
  unTransform?: boolean;
  wide?: boolean;
  placeholderColor?: boolean;
}

export const ClientsAutocomplete = styled(FormAutocomplete, {
  shouldForwardProp: propName =>
    !["unTransform", "wide", "placeholderColor"].includes(propName.toString()),
})<ClientsAutocompleteProps>`
  ${({ unTransform }) =>
    unTransform &&
    ` & .MuiAutocomplete-popupIndicator {
        transform: none;
      }

 `}
  min-width: ${({ wide }) => (wide ? "20.25rem" : "16.5rem")};
  max-width: 20.25rem;
  flex-grow: 1;

  ${({ placeholderColor, theme }) =>
    placeholderColor &&
    ` & input {
        &::placeholder {
          color: ${theme.colors.neutral.dark[800]};
          opacity: 1;
        }
      }
  `}
`;

export const FiltersWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const CheckCounter = styled("div")`
  display: grid;
  position: absolute;
  top: 0.55rem;
  right: 3rem;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.medium};
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.4px;
  z-index: 1;
`;

export const ProgramsTextField = styled(FormTextField)`
  max-width: 34.7rem;
  width: 100%;
`;

interface MobileAppCheckboxWrapperProps {
  placeholderColor?: boolean;
}

export const MobileAppCheckboxWrapper = styled(
  "div",
)<MobileAppCheckboxWrapperProps>`
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 100%;
  border-radius: 0.5rem;
  min-width: 16.5rem;
  max-width: 20.25rem;
  padding: 0.15rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  height: 2.5rem;
  color: ${({ placeholderColor, theme }) =>
    placeholderColor ? "currentColor" : theme.colors.neutral.dark[700]};

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledFormCheckboxMui = styled(FormCheckboxMui)`
  border-radius: 5px;
`;
