import { DollarSign } from "@assets/icons";
import img from "@assets/img/AlarmClock.png";
import imgX from "@assets/img/TabletWithXRound.png";
import { useAppTranslation, useModal } from "@hooks";
import { SubscriptionTrialModalWrapper } from "@components/ConfirmSubscriptionModals";
import { BuyPlanModal } from "@components/BuyPlanModal";

interface TrialModalProps {
  open: boolean;
  onClose: () => void;
  endsIn?: number | null;
  isTrial?: boolean;
}

export const TrialModal = ({
  onClose,
  open,
  endsIn,
  isTrial,
}: TrialModalProps) => {
  const { t } = useAppTranslation();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const handleBuyPlan = () => {
    onClose();
    onModalOpen();
  };

  const trialImg = endsIn === null ? imgX : img;

  const handleTitle = (days?: number | null) => {
    if (days && isTrial)
      return t("subscription.trial.step_one.ends_in", { count: days });
    if (days === 0 && isTrial)
      return t("subscription.trial.step_one.ends_today");
    if (!isTrial && days === null) return t("subscription.just_ends");
    return t("subscription.trial.step_one.just_ends");
  };

  const handleContent = (days?: number | null) => {
    if (days) return t("subscription.trial.step_one.content");
    return t("subscription.trial.step_one.content_extend");
  };

  return (
    <>
      <SubscriptionTrialModalWrapper
        showCloseButton={endsIn !== null}
        title={handleTitle(endsIn)}
        content={handleContent(endsIn)}
        open={open}
        onClose={onClose}
        img={trialImg}
        iconButton={<DollarSign />}
        onClick={handleBuyPlan}
        buttonText={t("subscription.trial.step_one.purchase_now")}
      />
      <BuyPlanModal
        open={modalOpened}
        onClose={onModalClose}
        endsIn={endsIn}
        isTrial={isTrial}
      />
    </>
  );
};
