import Macro from "@components/EnergyDecomposition/Macro";
import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";
import { MacrosContainer } from "@components/EnergyDecomposition/EnergyDecomposition.styled";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { getMacroKcal } from "@components/EnergyDecomposition/EnergyUtils";

type NutrientProps = {
  value: number;
  target?: number;
};

type MacrosProps = {
  protein: NutrientProps;
  fat: NutrientProps;
  carb: NutrientProps;
};

const Macros = ({ protein, fat, carb }: MacrosProps) => {
  const { t } = useAppTranslation();
  const macroKcal = useMemo(
    () => getMacroKcal({ protein, fat, carb }),
    [protein, fat, carb],
  );

  return (
    <MacrosContainer>
      <Macro
        label={t("components.energy_decomposition.protein")}
        color="#FADA69"
        macro={protein}
        macroKcal={ENERGY_PER_GRAM_OF_PROTEIN}
        kcal={macroKcal}
      />
      <Macro
        label={t("components.energy_decomposition.fat")}
        color="#FAB569"
        macro={fat}
        macroKcal={ENERGY_PER_GRAM_OF_FAT}
        kcal={macroKcal}
      />
      <Macro
        label={t("components.energy_decomposition.carb")}
        color="#3AB795"
        macro={carb}
        macroKcal={ENERGY_PER_GRAM_OF_CARB}
        kcal={macroKcal}
      />
    </MacrosContainer>
  );
};

export default Macros;
