import { Typography } from "@mui/material";

interface CustomRadioLabelProps {
  label: string;
}
export const CustomRadioLabel = ({ label }: CustomRadioLabelProps) => {
  return (
    <div className="flex">
      <Typography
        fontWeight={600}
        fontSize={18}
        lineHeight="20px"
        width="100%"
        color={({ palette }) => palette.primary.main}
      >
        {label}
      </Typography>
    </div>
  );
};
