import * as yup from "yup";

import {
  IdDto,
  LangDto,
  MeasureDto,
  NutrientDto,
  idSchema,
  measureSchema,
  nutrientSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  TagResourceDto,
  TranslationDto,
  tagResourceSchema,
  translationSchema,
} from "./searchDietsPreviewDiet";
import { ImageUrl, imageSchema } from "./searchDietsPreviewMeal";

export const searchDietsPreviewRecipeItem = async (
  dietId: string,
  mealId: string,
  itemId: string,
): Promise<ApiResponse<DietRecipeSearchPreviewResourceDto>> => {
  const response = await fetchData(
    `/dietitian/diets/search-preview/${dietId}/meals/${mealId}/recipe-items/${itemId}`,
    APIMethods.GET,
  );

  return apiResponseSchema.validate(response);
};

const translationExtendedSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
});

const foodItemSearchPreviewResourceSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  image: imageSchema.nullable().default(null),
  measures: yup.array().of(measureSchema.required()).required(),
});

const foodWrapperSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
  measure: idSchema.required(),
  product: foodItemSearchPreviewResourceSchema.required(),
});

const nestedRecipeItemSearchPreviewResourceSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  description: yup.string().nullable().default(null),
  translations: yup.array().of(translationExtendedSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  tags: yup.array().of(tagResourceSchema.required()).required(),
  image: imageSchema.nullable().default(null),
  products: yup.array().of(foodWrapperSchema.required()).required(),
});

const dietRecipeSearchPreviewResourceSchema = yup.object().shape({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: nestedRecipeItemSearchPreviewResourceSchema.required(),
});

const apiResponseSchema = yup.object().shape({
  data: dietRecipeSearchPreviewResourceSchema.required(),
});

export interface DietRecipeSearchPreviewResourceDto {
  id: number;
  servings: number;
  recipe: NestedRecipeSearchPreviewResourceDto;
}

interface NestedRecipeSearchPreviewResourceDto {
  id: number;
  name: string;
  description: string | null;
  translations: TranslationExtendedDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  image: ImageUrl | null;
  products: FoodWrapperDto[];
}

interface FoodItemSearchPreviewResourceDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  image: ImageUrl | null;
  measures: MeasureDto[];
}

interface FoodWrapperDto {
  id: number;
  product: FoodItemSearchPreviewResourceDto;
  grams: number;
  measure: IdDto;
}

interface TranslationExtendedDto extends TranslationDto {
  description: string | null;
}
