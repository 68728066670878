import { styled } from "@mui/material";

export const CountdownTimerWrapper = styled("div")`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const TimerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const TimerItem = styled("div")`
  display: grid;
  place-items: center;
  width: 47px;
  height: 50px;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.neutral.light[100]};
  background: #d9d9d91a;
  font-size: 20px;
  line-height: 48px;
  font-weight: 600;
  box-shadow: -9.53px 9.53px 9.53px 0px #ffffff1a inset;
  box-shadow: 9.53px -9.53px 9.53px 0px #a5a5a51a inset;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 60px;
    height: 64px;
    font-size: 28px;
  }
`;

export const TimerItemName = styled("p")`
  font-family: ${({ theme }) => theme.fontFamily.rockSalt};
  font-size: 6px;
  font-weight: 600;
  letter-spacing: 2.1px;
  line-height: 18px;
  color: #5dcfd0;
  text-transform: uppercase;
`;
