import { useAppTranslation, useClientParams } from "@hooks";
import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionLayout,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { Suspense, useState } from "react";
import PatientBodyMeasurementsGalleryModal from "@components/Client/PatientBodyMeasurementsGalleryModal/PatientBodyMeasurementsGalleryModal";
import useFetchPatientLastBodyMeasurementImages from "@hooks/queries/client/bodyMeasurement/useFetchPatientLastBodyMeasurementImages";
import { SectionTitle } from "@views/dietician/ClientHealth";
import { PaperPlaneIcon } from "@icons/index";
import { lazy } from "react";

const ImageEmptyState = lazy(() => import("./ImageEmptyState"));
const LastImageGallery = lazy(() => import("./LastImageGallery"));

const ImagesSection = () => {
  const { t } = useAppTranslation();
  const id = useClientParams();

  const [open, setOpen] = useState(false);

  const { data: lastMeasurement } = useFetchPatientLastBodyMeasurementImages({
    patientId: id,
  });

  return (
    <SectionLayout>
      <SectionHeadLayout>
        <SectionTitle>
          {t(
            "patient.health_and_goal.body_measurement_tab.section_images.section_title",
          )}
        </SectionTitle>
        <SectionHeadEditLayout
          sx={{ opacity: lastMeasurement?.data ? "unset" : "40%" }}
          onClick={() => {
            if (lastMeasurement?.data) {
              setOpen(true);
            }
          }}
        >
          <PaperPlaneIcon color="#7448D0" />
          <TabHeadActionEditLabel>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_images.title_button_show",
            )}
          </TabHeadActionEditLabel>
        </SectionHeadEditLayout>
      </SectionHeadLayout>
      <Suspense fallback={null}>
        {lastMeasurement?.data ? (
          <LastImageGallery lastMeasurement={lastMeasurement.data} />
        ) : (
          <ImageEmptyState />
        )}
      </Suspense>
      <PatientBodyMeasurementsGalleryModal
        open={open}
        setOpen={setOpen}
        patientId={id}
      />
    </SectionLayout>
  );
};

export default ImagesSection;
