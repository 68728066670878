import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export async function fetchUsedRecipes(
  programId: number,
): Promise<ApiResponse<UsedRecipes[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/recipes-used`,
    APIMethods.GET,
  );
  return await fetchUsedRecipesSchema.validate(data);
}

const fetchUsedRecipesSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(),
        quantity: yup.number().required(),
      }),
    )
    .required(),
});

interface UsedRecipes {
  title: string;
  quantity: number;
}
