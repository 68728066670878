import { NutrientValueResource } from "@client/resources/NutrientValueResource";
import { useAppParams } from "@hooks";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import useFetchPatientNormNutrients from "@hooks/queries/client/nutrient/useFetchPatientNormNutrients";
import { useMemo } from "react";

export const usePatientImportantNutrients = () => {
  const { patientId } = useAppParams();

  const { data: importants } = useFetchPatientImportantNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );
  const { data: norm } = useFetchPatientNormNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );

  const importantNutrients = useMemo(
    () =>
      norm?.data.nutrients
        .filter(({ id }) => importants?.data.map(({ id }) => id).includes(id))
        .map(({ id, value }) => ({ id, value: value ?? 0 })),
    [norm, importants],
  );

  return {
    importantNutrients,
  };
};
