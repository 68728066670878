import {
  CustomersSection,
  FormControlLabelStyled,
  PriceSection,
  QuantityItem,
  SliderItem,
  SliderSection,
  SliderStyled,
  StyledMonth,
  TopSection,
  Wrapper,
} from "./SubscriptionCard.styled";
import { Divider, Radio, Typography, useTheme } from "@mui/material";
import { CustomRadioLabel } from "../CustomRadioLabel";
import { useCallback, useState } from "react";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { HeadWithShoulders } from "@assets/icons";
import {
  FLEX_PRICES_MONTH,
  FLEX_PRICES_YEAR,
  FLEX_VALUES,
  PaymentType,
  UNLIMITED_MONTH,
  UNLIMITED_YEAR,
} from "@utils";
import { useAppTranslation } from "@hooks";
import { isArray } from "lodash";

interface PaymentPrice {
  month: FLEX_PRICES_MONTH;
  year: FLEX_PRICES_YEAR;
}

interface SubscriptionCardProps {
  label: string;
  value?: string;
  current?: boolean;
  checked: boolean;
  paymentType: PaymentType;
  setFlexValue?: (value: number) => void;
}

export const SubscriptionCard = ({
  label,
  value,
  checked,
  current,
  paymentType,
  setFlexValue,
}: SubscriptionCardProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const YEAR_MONTHS = 12;
  const VAT = 23;

  const [sliderValue, setSliderValue] = useState(10);
  const [price, setPrice] = useState(
    paymentType === "month" ? FLEX_PRICES_MONTH.TEN : FLEX_PRICES_YEAR.TEN,
  );

  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      if (isArray(newValue)) return;
      const paymentPrices: Record<number, PaymentPrice> = {
        10: {
          month: FLEX_PRICES_MONTH.TEN,
          year: FLEX_PRICES_YEAR.TEN,
        },
        25: {
          month: FLEX_PRICES_MONTH.TWENTY_FIVE,
          year: FLEX_PRICES_YEAR.TWENTY_FIVE,
        },
        40: {
          month: FLEX_PRICES_MONTH.FORTY,
          year: FLEX_PRICES_YEAR.FORTY,
        },
        60: {
          month: FLEX_PRICES_MONTH.SIXTY,
          year: FLEX_PRICES_YEAR.SIXTY,
        },
      };
      const price = paymentPrices[newValue];

      if (paymentType === "month" && price) setPrice(price.month);
      if (paymentType === "year" && price) setPrice(price.year);

      setSliderValue(newValue as number);
      if (setFlexValue && value === "flex") {
        setFlexValue(newValue as number);
      }
    },
    [paymentType, price, sliderValue, setFlexValue],
  );

  const marks = [
    { value: FLEX_VALUES.TEN },
    { value: FLEX_VALUES.TWENTY_FIVE },
    { value: FLEX_VALUES.FORTY },
    { value: FLEX_VALUES.SIXTY },
  ];
  const netPrice = current
    ? price
    : paymentType === "month"
    ? UNLIMITED_MONTH
    : UNLIMITED_YEAR;
  const grossPrice = (netPrice * VAT) / 100 + netPrice;
  const handledGrossPrice =
    paymentType === "month"
      ? grossPrice.toFixed(2).toString().replace(".", ",")
      : (grossPrice * YEAR_MONTHS).toFixed(2).toString().replace(".", ",");

  const grossPriceContent =
    paymentType === "month"
      ? t("subscription.trial.step_two.gross_per_month")
      : value === "flex"
      ? t("subscription.trial.step_two.gross_per_year")
      : t("subscription.trial.step_two.gross_per_year_once");
  return (
    <Wrapper checked={checked}>
      <TopSection>
        <FormControlLabelStyled
          value={value}
          control={<Radio />}
          label={<CustomRadioLabel label={label} />}
          labelPlacement="start"
        />
        <PriceSection>
          <Typography fontSize={32} fontWeight={600} lineHeight="40px">
            {netPrice}zł
            <StyledMonth>
              {" "}
              / {t("subscription.trial.step_two.month")}
            </StyledMonth>
          </Typography>
          <Typography
            lineHeight="20px"
            color={({ colors }) => colors.neutral.medium[600]}
          >
            {handledGrossPrice} {grossPriceContent}
          </Typography>
        </PriceSection>
      </TopSection>
      <Divider />
      {value === "flex" ? (
        <SliderSection>
          <Typography
            fontSize={12}
            fontWeight={600}
            lineHeight="18px"
            color={({ palette }) => palette.primary.main}
          >
            {t("subscription.trial.step_two.active_client_quantity")}
          </Typography>
          <SliderItem>
            <SliderStyled
              disabled={!checked}
              slots={{ thumb: MediumThumbComponent }}
              aria-label="Restricted values"
              defaultValue={10}
              min={10}
              max={60}
              step={null}
              marks={marks}
              onChange={handleSliderChange}
              value={sliderValue}
            />
            <QuantityItem>
              <HeadWithShoulders
                fill={palette.primary.main}
                size="w-[18px] h-[18px]"
              />
              <Typography fontSize={16} fontWeight={600} lineHeight="24px">
                {sliderValue}
              </Typography>
            </QuantityItem>
          </SliderItem>
        </SliderSection>
      ) : (
        <CustomersSection>
          <HeadWithShoulders
            fill={palette.primary.main}
            size="w-[18px] h-[18px]"
          />
          <span>{t("subscription.trial.step_two.unlimited")}</span>
        </CustomersSection>
      )}
      <Typography
        fontSize={12}
        lineHeight="20px"
        color={({ colors }) => colors.neutral.dark[700]}
      >
        {value === "flex"
          ? t("subscription.trial.step_two.net_dietician_in_the_team")
          : t("subscription.trial.step_two.net_another_dietician")}
      </Typography>
    </Wrapper>
  );
};
