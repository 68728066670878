import { Button, Card, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const LaunchCounterSection = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  background: #150d22;
  box-shadow: 0px 4px 10px 0px ${getHexColorOpacity("#000000", 15)};
  border-radius: 16px;
  padding: 16px;
  text-align: center;
`;

export const LaunchCounterTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fontFamily.rockSalt};
  font-size: 16px;
  line-height: 32px;
  color: #efe0ff;
  letter-spacing: 2.1px;
`;

export const StyledButton = styled(Button)`
  width: 120px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 8px;
  background: linear-gradient(91.08deg, #485ad0 1.74%, #c977f0 107.23%);
  z-index: 2;
`;

export const ImgStyled = styled("img")`
  position: absolute;
  top: 16px;
  right: 0;
`;

export const OverlayImg = styled("img")`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

export const Text = styled("p")`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const GradientText = styled("span")`
  background: linear-gradient(90deg, #b088b1 0%, #70d9d8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
