import { MuiDataGrid } from "@components/MuiDataGrid";
import { styled } from "@mui/material";
import { LARGE_DESKTOP_MAX_CONTENT_WIDTH } from "@utils";

export const StyledMuiDataGrid = styled(MuiDataGrid)`
  & .MuiDataGrid-cell {
    padding: 0 0.5rem;
  }
`;

export const QuestionnaireListWrapper = styled("div")`
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    max-width: ${LARGE_DESKTOP_MAX_CONTENT_WIDTH};
  }
`;
