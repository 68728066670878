import { Pen } from "@assets/icons";
import { ButtonsWrapper, IconButtonStyled } from "./ActionButtons.styled";
import { Close } from "@assets/icons/DesignSystem";

interface ActionButtonsProps {
  hasImg: boolean;
  onClose: () => void;
  onEdit: () => void;
}

export const ActionButtons = ({
  hasImg,
  onEdit,
  onClose,
}: ActionButtonsProps) => {
  if (hasImg)
    return (
      <ButtonsWrapper>
        <div className="flex gap-[8px]">
          <IconButtonStyled onClick={onEdit}>
            <Pen />
          </IconButtonStyled>
          <IconButtonStyled onClick={onClose}>
            <Close />
          </IconButtonStyled>
        </div>
      </ButtonsWrapper>
    );

  return (
    <div className="flex gap-[8px]">
      <IconButtonStyled onClick={onEdit}>
        <Pen />
      </IconButtonStyled>
      <IconButtonStyled onClick={onClose}>
        <Close />
      </IconButtonStyled>
    </div>
  );
};
