import {
  TimerContainer,
  TimerItem,
  TimerItemName,
} from "./CountdownTimer.styled";

interface TimerDisplayItemProps {
  value: number;
  label: string;
}

export const TimerDisplayItem = ({ value, label }: TimerDisplayItemProps) => (
  <TimerContainer>
    <TimerItem>{value}</TimerItem>
    <TimerItemName>{label}</TimerItemName>
  </TimerContainer>
);
