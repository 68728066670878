import { useMemo } from "react";

import { DietNutrientsNormDto } from "@client/diets/creator";
import { useAppParams } from "@hooks";
import { useFetchProgramDayNutrientsQuery } from "@hooks/queries";
import useFetchPatientNormNutrients from "@hooks/queries/client/nutrient/useFetchPatientNormNutrients";
import { useFetchDietNutrientsNormQuery as useFetchDietCreatorNutrientsNormQuery } from "@hooks/queries/diets/creator";

export const useFetchDietNutrientsNormQuery = (dietId: string) => {
  const { programId, dayId, patientId } = useAppParams();

  const isInProgram = useMemo(() => !!programId && !!dayId, [programId, dayId]);
  const isInPatient = !!patientId;

  const { norm: normData, ...rest } = useFetchDietCreatorNutrientsNormQuery(
    dietId,
    { enabled: !isInProgram },
  );
  const { nutrients, ...programRest } = useFetchProgramDayNutrientsQuery(
    programId,
    dayId,
    { enabled: isInProgram && !isInPatient },
  );
  const { data: patientData, ...patientRest } = useFetchPatientNormNutrients(
    { patientId: Number(patientId) },
    { enabled: isInPatient },
  );

  const nutrientsMappedToNorm: DietNutrientsNormDto | undefined = useMemo(
    () =>
      nutrients
        ? {
            norm: { id: Number(nutrients.data.norm?.id) },
            nutrients: nutrients.data.nutrients.map(n => ({
              nutrient: { id: n.id },
              show: n.visible,
              value: n.value,
            })),
          }
        : undefined,
    [nutrients],
  );

  const patientNutrientsMappedToNorm = useMemo(
    () =>
      isInPatient
        ? {
            norm: { id: Number(patientData?.data.norm?.id) },
            nutrients:
              patientData?.data.nutrients.map((n: any) => ({
                nutrient: { id: n.id },
                show: n.visible,
                value: n.value,
              })) ?? [],
          }
        : undefined,
    [patientData, isInPatient],
  );

  if (isInPatient)
    return { norm: patientNutrientsMappedToNorm, ...patientRest };

  if (isInProgram) return { norm: nutrientsMappedToNorm, ...programRest };

  return { norm: normData, ...rest };
};
