import { Link } from "react-router-dom";
import {
  GradientText,
  LaunchCounterSection,
  LaunchCounterTitle,
  OverlayImg,
  StyledButton,
  Text,
} from "./RoadShowCard.styled";
import { CountdownTimer } from "@components/CountdownTimer";
import overlayDesktop from "@assets/img/roadShowBanner/clientContext/overlayDesktop.png";
import { useAppTranslation } from "@hooks";

export const ROADSHOW_DATE = "2024-11-07T16:00:00Z";
export const ROADSHOW_TITLE = "OZNACZENIE PREFERENCJI KLIENTA - JUZ JEST!";
export const ROADSHOW_LINK =
  "https://alloweat.com/oznaczenia-kontekstu-klienta-i-funkcja-autogenerowania/";

export const RoadShowCard = () => {
  const { t } = useAppTranslation();
  return (
    <LaunchCounterSection>
      <div className="flex flex-col gap-[16px] justify-center w-[208px] h-[193px]">
        <LaunchCounterTitle>{ROADSHOW_TITLE}</LaunchCounterTitle>

        <Link to={ROADSHOW_LINK} target="_blank" rel="noopener noreferrer">
          <StyledButton variant="contained">
            {t("common.show_more")}
          </StyledButton>
        </Link>
      </div>

      <OverlayImg src={overlayDesktop} />

      <div className="grid gap-[12px]">
        <Text>
          A kolejna odsłona <GradientText>już za:</GradientText>
        </Text>
        <CountdownTimer targetDate={ROADSHOW_DATE} />
      </div>
    </LaunchCounterSection>
  );
};
