import {
  Circle,
  MacroContainer,
  MacroLabel,
  MacroLabelContainer,
  MacroTargetValue,
  MacroValue,
} from "@components/EnergyDecomposition/EnergyDecomposition.styled";

type NutrientProps = {
  value: number;
  target?: number;
};

type MacroProps = {
  label: string;
  color: string;
  kcal: NutrientProps;
  macro: NutrientProps;
  macroKcal: number;
};

const Macro = ({ label, color, kcal, macro, macroKcal }: MacroProps) => {
  const getTargetValue = (nutrient: NutrientProps) =>
    nutrient.target ? nutrient.target : nutrient.value;
  const getKcalValue = () => {
    const value = getTargetValue(kcal);
    return value === 0 ? 1 : value;
  };

  const getKcalPercent = () =>
    Math.round(((getTargetValue(macro) * macroKcal) / getKcalValue()) * 100);

  return (
    <MacroContainer>
      <MacroLabelContainer>
        <Circle style={{ background: color }} />
        <MacroLabel>
          {label} ({getKcalPercent()}%)
        </MacroLabel>
      </MacroLabelContainer>
      <MacroTargetValue>
        <MacroValue>{Math.round(macro.value)}</MacroValue>
        {macro.target ? ` / ${Math.round(macro.target)}` : ""} g
      </MacroTargetValue>
    </MacroContainer>
  );
};

export default Macro;
