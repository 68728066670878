import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  ActionsDto,
  actionsSchema,
  ClinicIdDto,
  clinicIdSchema,
  IdDto,
  idSchema,
  MediaExtendedDto,
  mediaExtendedSchema,
  NutrientDto,
  nutrientSchema,
  TagExtendedNewDto,
  tagExtendedNewSchema,
  TranslationDto,
  translationSchema,
} from "@client/common";

export const fetchCreatorMeal = async (
  mealId: string,
): Promise<ApiResponse<FetchMealResponse>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}`,
    APIMethods.GET,
  );

  return fetchMealResponseSchema.validate(response);
};

interface MediaDto {
  url: string;
  s3Url: string | null;
}
export interface FetchMealResponse {
  actions: ActionsDto;
  clinic: ClinicIdDto;
  id: number;
  numberOfItems: number;
  nutrients: NutrientDto[];
  products: (ProductDto | ProductPlaceholder)[];
  recipes: RecipeWrapperDto[];
  reviewed: boolean;
  tags: TagExtendedNewDto[];
  title: string;
  translations: TranslationDto[];
  version: number;
  favorite: number[];
  media: MediaDto | null;
}

export interface RecipeWrapperDto {
  id: number;
  recipe: RecipeDto | RecipePlaceholder;
  servings: number;
}

export interface RecipePlaceholder {
  id: number;
  isPlaceholder: true;
}

export interface RecipeDto {
  id: number;
  name: string;
  nameEn: string | null;
  description: string | null;
  descriptionEn: string | null;
  reviewed: boolean;
  isComplex: boolean;
  servings: number;
  tags: TagResourceDto[];
  nutrients: NutrientDto[];
  foodRecipe: FoodRecipeDto[];
  version: number;
  media: MediaExtendedDto | null;
  actions: ActionsDto;
  isPlaceholder?: undefined;
}

export interface FoodRecipeDto {
  id: number;
  grams: number | null;
  foodMeasureId: number;
  food: FoodRecipeDetailsDto;
}

interface FoodRecipeDetailsDto {
  id: number;
  description: string | null;
  descriptionPl: string | null;
  nutrients: NutrientDto[];
  measures: RecipeMeasureDto[];
  exchange: ExchangeDto | null;
}
export interface ProductDto {
  food: FoodDto;
  grams: number;
  id: number;
  measure: IdDto;
  isPlaceholder?: undefined;
}

export interface ProductPlaceholder {
  id: number;
  isPlaceholder: true;
}

interface ImageDto {
  url: string;
}

interface FoodDto {
  id: number;
  measures: MeasureDto[];
  name: string;
  nutrients: NutrientDto[];
  translations: TranslationDto[];
  tags: TagResourceDto[];
  image: ImageDto | null;
}

interface MeasureDto {
  id: number;
  grams: number;
}
interface RecipeMeasureDto {
  id: number;
  description: string;
  descriptionEn: string;
  grams: number;
  sortOrder: number;
}

interface TagResourceDto {
  id: number;
  descriptionPl: string;
  descriptionEn: string;
  clinicId: number | null;
  tagCategoryId: number;
}

interface ExchangeDto {
  foodIdTo: number;
  foodFrom: {
    id: number;
    description: string;
    descriptionEn: string;
  };
}

const measuresSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

const tagResourceSchema = yup.object().shape({
  id: yup.number().required(),
  descriptionPl: yup.string().required(),
  descriptionEn: yup.string().required(),
  clinicId: yup.number().nullable().default(null),
  tagCategoryId: yup.number().required(),
});

const imageSchema = yup.object().shape({
  url: yup.string().required(),
});

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  measures: yup.array().of(measuresSchema).required(),
  name: yup.string().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  translations: yup.array().of(translationSchema).required(),
  tags: yup.array().of(tagResourceSchema).required(),
  image: imageSchema.nullable().default(null),
});

export const productSchema = yup.object().shape({
  food: foodSchema.required(),
  grams: yup.number().required(),
  id: yup.number().required(),
  measure: idSchema,
});

const exchangeDto = yup.object().shape({
  foodIdTo: yup.number().required(),
  foodFrom: yup.object().shape({
    id: yup.number().required(),
    description: yup.string().required(),
    descriptionEn: yup.string().required(),
  }),
});

const measureSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

const recipeMeasureSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  grams: yup.number().required(),
  sortOrder: yup.number().required(),
});

const foodRecipeDetailsSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().nullable().default(null),
  descriptionPl: yup.string().nullable().default(null),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(recipeMeasureSchema).required(),
  exchange: exchangeDto.nullable().default(null),
});

const foodRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().nullable().default(null),
  foodMeasureId: yup.number().required(),
  food: foodRecipeDetailsSchema.required(),
});

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
  descriptionEn: yup.string().nullable().default(null),
  reviewed: yup.boolean().required(),
  isComplex: yup.boolean().required(),
  servings: yup.number().required(),
  tags: yup.array().of(tagResourceSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  foodRecipe: yup.array().of(foodRecipeSchema).required(),
  version: yup.number().required(),
  media: mediaExtendedSchema.nullable().default(null),
  actions: actionsSchema.required(),
});

export const recipeWrapperSchema = yup.object().shape({
  id: yup.number().required(),
  recipe: recipeSchema.required(),
  servings: yup.number().required(),
});

const mediaSchema = yup.object().shape({
  url: yup.string().required(),
  s3Url: yup.string().nullable().default(null),
});

export const fetchMealSchema = yup.object().shape({
  actions: actionsSchema.required(),
  clinic: clinicIdSchema.required(),
  id: yup.number().required(),
  numberOfItems: yup.number().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  products: yup.array().of(productSchema).required(),
  recipes: yup.array().of(recipeWrapperSchema).required(),
  reviewed: yup.boolean().required(),
  tags: yup.array().of(tagExtendedNewSchema).required(),
  title: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  version: yup.number().required(),
  favorite: yup.array().of(yup.number().required()).required(),
  media: mediaSchema.nullable().default(null),
});

export const fetchMealResponseSchema = yup.object().shape({
  data: fetchMealSchema.required(),
});
