import { ReactNode } from "react";

import { createTheme, ThemeProvider as ThemeProviderNew } from "@mui/material";
import { enUS as coreEnUS, plPL as corePlPL } from "@mui/material/locale";
import { enUS, plPL } from "@mui/x-data-grid";
import {
  enUS as pickersEnUS,
  plPL as pickersPlPL,
} from "@mui/x-date-pickers/locales";

import {
  CheckboxChecked,
  CheckboxUnChecked,
  CheckedIcon,
  ExclamationMarkInCircle,
  InfoInRound,
} from "@assets/icons";
import { CheckboxIndeterminate } from "@assets/icons/Checkbox";
import { DeleteIcon } from "@assets/icons/Chip";
import { CheckInCircle } from "@assets/icons/DesignSystem";
import { RadioChecked, RadioEmpty } from "@assets/icons/Radio";
import { Spinner } from "@components/Spinner";
import { useAppTranslation } from "@hooks";
import { getHexColorOpacity } from "@utils";

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      tableBackground: string;
      tableHeader: string;
      submitButtonHover: string;
      cancelButtonHover: string;
      cancelButton: string;
      whiteBackground: string;
      tableBorder: string;
      textDisabled: string;
      eventBorder: string;
      border: string;
      focusedBorder: string;
      gray000: string;
      gray200: string;
      gray300: string;
      gray500: string;
      labelGray: string;
      alertTomato: string;
      acceptGreen: string;
      black087: string;
      lightPurple: string;
      hoverBtnPurple: string;
      greyBlue: string;
      yellow: string;
      yellowLight: string;
      chartPurple: string;
      chartOrange: string;
      chartGreen: string;
      solidLightPurple: string;
      borderGray: string;
      subtlePurple: string;
      chartLightPurple: string;
      primaryLightNew: string;
      orangeDark: string;
      // --------------- new colors --------------- //
      neutral: {
        dark: {
          700: string;
          800: string;
          900: string;
        };
        medium: {
          400: string;
          600: string;
          800: string;
        };
        light: {
          100: string;
          200: string;
        };
      };
      survey: {
        light: string;
        yellow: {
          light: string;
          dark: string;
        };
        green: {
          light: string;
          dark: string;
        };
        pink: {
          light: string;
          dark: string;
        };
      };
      orange: {
        light: string;
        dark: string;
      };
    };
    fontFamily: {
      regular: string;
      rockSalt: string;
      sueEllenFrancisco: string;
    };
    boxShadows: {
      light: string;
      tooltip: string;
      card: string;
      cardDark: string;
      mobileNavigation: string;
    };
    gradients: {
      survey: string;
      surveySemiTransparent: string;
    };
  }

  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    small: true;
    smallNew: true;
    tablet: true;
    smallMax: true;
    medium: true;
    mediumMax: true;
    laptop: true;
    laptopMax: true;
    desktop: true;
    desktopMax: true;
    largeDesktop: true;
  }
  interface PaletteColor {
    base?: string;
    medium?: string;
    light2?: string;
  }

  interface SimplePaletteColorOptions {
    base?: string;
    medium?: string;
    light2?: string;
  }

  interface ThemeOptions {
    colors: {
      tableBackground: string;
      tableHeader: string;
      cancelButton: string;
      cancelButtonHover: string;
      submitButtonHover: string;
      whiteBackground: string;
      tableBorder: string;
      textDisabled: string;
      eventBorder: string;
      border: string;
      focusedBorder: string;
      gray000: string;
      gray200: string;
      gray300: string;
      gray500: string;
      labelGray: string;
      alertTomato: string;
      acceptGreen: string;
      black087: string;
      lightPurple: string;
      hoverBtnPurple: string;
      greyBlue: string;
      yellow: string;
      yellowLight: string;
      chartPurple: string;
      chartOrange: string;
      chartGreen: string;
      solidLightPurple: string;
      borderGray: string;
      subtlePurple: string;
      chartLightPurple: string;
      primaryLightNew: string;
      orangeDark: string;
      // --------------- new colors --------------- //
      neutral: {
        dark: {
          700: string;
          800: string;
          900: string;
        };
        medium: {
          400: string;
          600: string;
          800: string;
        };
        light: {
          100: string;
          200: string;
        };
      };
      survey: {
        light: string;
        yellow: {
          light: string;
          dark: string;
        };
        green: {
          light: string;
          dark: string;
        };
        pink: {
          light: string;
          dark: string;
        };
      };
      orange: {
        light: string;
        dark: string;
      };
    };
    fontFamily: {
      regular: string;
      rockSalt: string;
      sueEllenFrancisco: string;
    };
    boxShadows: {
      light: string;
      tooltip: string;
      card: string;
      cardDark: string;
      mobileNavigation: string;
    };
    gradients: {
      survey: string;
      surveySemiTransparent: string;
    };
    breakpoints: {
      values: {
        mobile: number;
        small: number;
        smallNew: number;
        tablet: number;
        smallMax: number;
        medium: number;
        mediumMax: number;
        laptop: number;
        laptopMax: number;
        desktop: number;
        desktopMax: number;
        largeDesktop: number;
      };
    };
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    newFilled: true;
    newOutlined: true;

    outlinedWhite: true;
  }
}

interface ThemeProviderProps {
  children: ReactNode;
}
export const ThemeProviderWrapperNew = ({ children }: ThemeProviderProps) => {
  const { isPolishChosen } = useAppTranslation();

  const theme = createTheme(
    {
      colors: {
        tableBackground: "#f1f1f1",
        tableHeader: "#f8f5fd",
        cancelButton: "#E0E0E0",
        cancelButtonHover: "#b8b7b3",
        submitButtonHover: "#a46bfc",
        whiteBackground: "#ffffff",
        tableBorder: "#e0dfde",
        textDisabled: "#8E8E8E",
        eventBorder: "#b38bff",
        border: "#E2E1DEFF",
        focusedBorder: "#a46bfc",
        gray000: "#000000",
        gray200: "#FAF9F9",
        gray300: "#F1F1F1CC",
        gray500: "#666666",
        labelGray: "#00000099",
        alertTomato: "#F25C5A",
        acceptGreen: "#46BB63",
        black087: "rgba(0, 0, 0, 0.87)",
        lightPurple: "rgba(128, 31, 255, 0.03)",
        hoverBtnPurple: "rgba(128, 31, 255, 0.3)",
        greyBlue: "#677099",
        yellow: "#FFBB33",
        yellowLight: "#FFF6E2",
        chartPurple: "#CFAAFF",
        chartOrange: "#FEA745",
        chartGreen: "#87D89C",
        solidLightPurple: "#FAF6FF",
        borderGray: "#EEEEEE",
        subtlePurple: "#F5EDFF",
        chartLightPurple: "#B379FF",
        primaryLightNew: "#FBFAFC",
        orangeDark: "#E5A117",

        // --------------- new colors --------------- //
        neutral: {
          dark: {
            700: "#727272",
            800: "#333333",
            900: "#141414",
          },
          medium: {
            400: "#D0D0D0",
            600: "#B4B4B4",
            800: "#4D516E",
          },
          light: {
            100: "#FFFFFF",
            200: "#F1F1F1",
          },
        },
        survey: {
          light: "#F7F5FF",
          yellow: {
            light: "#FFFEEF",
            dark: "#FDD835",
          },
          green: {
            light: "#E4F1F0",
            dark: "#00897B",
          },
          pink: {
            light: "#FAF0F3",
            dark: "#EC407A",
          },
        },
        orange: {
          light: "#FEF5EB",
          dark: "#FB931F",
        },
      },
      breakpoints: {
        values: {
          mobile: 360,
          smallNew: 740,
          small: 848,
          tablet: 1128,
          smallMax: 1199.5,
          medium: 1200,
          mediumMax: 1439.5,
          laptop: 1440,
          laptopMax: 1619.5,
          desktop: 1620,
          desktopMax: 1919.5,
          largeDesktop: 1920,
        },
      },
      fontFamily: {
        regular: "Figtree, sans-serif",
        rockSalt: "Rock Salt, Figtree, sans-serif",
        sueEllenFrancisco: "Sue Ellen Francisco, Figtree, sans-serif",
      },
      palette: {
        primary: {
          dark: "#59379E",
          main: "#7448D0",
          base: "#F2EFFB",
          medium: "#F5F1FE",
          light2: "#FAF7FF",
          light: "#FBFAFC",
        },
        secondary: {
          main: "#FFAA00",
          dark: "#EC7100",
          light: "#FFBB33",
          contrastText: "#FFFFFF",
        },
        error: {
          dark: "#B53A45",
          main: "#D44450",
          light: "#FEF0F1",
        },
        success: {
          dark: "#2A856C",
          main: "#3AB795",
          medium: "#46BB63",
          light: "#EFF9F7",
        },
        info: {
          dark: "#4242B8",
          main: "#5252E5",
          light: "#F2F2FF",
        },
        warning: {
          dark: "#BF8000",
          main: "#FAB569",
          medium: "#FDE9D2",
          light: "#FFF6ED",
        },
      },
      boxShadows: {
        light: "0px 2px 10px 0px #0000000f",
        tooltip: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
        card: "0px 0px 10px 0px #0000000D",
        cardDark: "0px 0px 10px 0px #0000001A",
        mobileNavigation: "6px 3px 12px 0px #00000040;",
      },
      gradients: {
        survey:
          "radial-gradient(100% 1757.1% at 0% 0%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
        surveySemiTransparent:
          "radial-gradient(100% 1757.1% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%)",
      },
      components: {
        MuiAlert: {
          defaultProps: {
            iconMapping: {
              info: <InfoInRound size="w-[14px] h-[14px]" />,
              error: <ExclamationMarkInCircle size="w-[14px] h-[14px]" />,
              warning: <ExclamationMarkInCircle size="w-[14px] h-[14px]" />,
              success: <CheckInCircle size="w-[14px] h-[14px]" />,
            },
          },
          styleOverrides: {
            root: {
              "boxShadow": "unset",

              ".MuiAlert-icon": {
                marginRight: "0.25rem",
              },
            },
          },
          variants: [
            {
              props: { severity: "info" },
              style: ({ theme }) => ({
                color: theme.palette.info.main,
                backgroundColor: "unset",
                alignItems: "center",
                padding: "unset",
              }),
            },
            {
              props: { severity: "error" },
              style: ({ theme }) => ({
                color: theme.palette.error.main,
                alignItems: "center",
                padding: "unset",
              }),
            },
            {
              props: { severity: "success" },
              style: ({ theme }) => ({
                color: theme.palette.success.main,
                alignItems: "center",
                padding: "unset",
              }),
            },
            {
              props: { severity: "warning" },
              style: ({ theme }) => ({
                color: theme.palette.warning.main,
                alignItems: "center",
                padding: "unset",
              }),
            },
          ],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: ({ theme }) => ({
              border: `1px solid ${theme.palette.primary.medium}`,
              borderRadius: "0.5rem",
              top: "-0.5rem",
              paddingLeft: "0.75rem",
            }),
          },
        },
        MuiSelect: {
          defaultProps: {
            IconComponent: CheckedIcon,
            MenuProps: { disableScrollLock: true },
          },
          styleOverrides: {
            root: ({ theme }) => ({
              "background": theme.colors.neutral.light[100],
              "borderRadius": "0.5rem",
              "& .MuiSelect-icon": {
                right: "0.8rem",
              },
              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                {
                  paddingRight: "2.75rem",
                },

              "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                padding: "0.532rem 0.75rem",
              },
            }),
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            popupIcon: <CheckedIcon />,
            loadingText: <Spinner />,
          },
          styleOverrides: {
            root: () => ({
              "backgroundColor": "#fff",
              "height": "100%",
              "borderRadius": "0.5rem",
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                paddingLeft: "0.75rem",
              },
              ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
                {
                  paddingLeft: 0,
                },
              ".MuiAutocomplete-popper": {
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
              },
            }),
            paper: {
              "& .MuiAutocomplete-listbox li span": {
                marginRight: "unset",
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: ({ theme }) => ({
              "boxShadow": "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
              "borderRadius": "8px",
              "& .MuiAutocomplete-listbox": {
                "display": "grid",
                "padding": "4px",
                "gap": "4px",
                ".MuiAutocomplete-option": {
                  "&.Mui-focused": {
                    "background": theme.palette.primary.light,
                    "&[aria-selected='true']": {
                      background: theme.palette.primary.light,
                    },
                  },
                  "&[aria-selected='true']": {
                    background: theme.palette.primary.light,
                  },
                },
                "& li": {
                  "borderRadius": "8px",
                  "minHeight": "2.5rem",
                  "&:hover": {
                    background: theme.palette.primary.light,
                  },
                  "& span": {
                    marginRight: "0.5rem",
                  },
                },
              },
            }),
          },
        },
        MuiBadge: {
          styleOverrides: {
            root: ({ theme }) => ({
              "& .MuiBadge-badge.MuiBadge-dot": {
                border: `1px solid ${theme.colors.neutral.light[100]}`,
              },
            }),
          },
          variants: [
            {
              props: { variant: "dot", role: "tooltip" },
              style: () => ({
                "& .MuiBadge-badge.MuiBadge-dot": {
                  top: "1px",
                },
              }),
            },
          ],
        },

        MuiButton: {
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              textTransform: "none",
              borderRadius: "0.5rem",
            },
            sizeMedium: {
              height: "2.5rem",
              fontSize: "1rem",
              MuiIconButton: {
                height: "1rem",
              },
            },
            outlined: ({ theme }) => ({
              border: "1px solid currentColor",
              background: theme.colors.neutral.light[100],
            }),
            startIcon: {
              marginLeft: 0,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              padding: 0,
              marginRight: "0.75rem",
            },
          },
          defaultProps: {
            checkedIcon: <CheckboxChecked />,
            icon: <CheckboxUnChecked />,
            indeterminateIcon: <CheckboxIndeterminate />,
          },
        },
        MuiChip: {
          styleOverrides: {
            root: ({ theme }) => ({
              "height": "unset",
              "fontSize": "12px",
              "fontWeight": 600,
              "letterSpacing": "0.4px",

              "& .MuiChip-label": {
                padding: "4px 8px",
                overflow: "visible",
                lineHeight: "20px",
              },
              "& .MuiChip-deleteIcon": {
                color: getHexColorOpacity(theme.colors.neutral.light[100], 70),
              },
            }),
          },
          defaultProps: {
            deleteIcon: <DeleteIcon />,
          },

          variants: [
            {
              props: { color: "success" },
              style: ({ theme }) => ({
                background: theme.palette.success.light,
                color: theme.palette.success.dark,
              }),
            },
            {
              props: { color: "warning" },
              style: ({ theme }) => ({
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark,
              }),
            },
            {
              props: { color: "info" },
              style: ({ theme }) => ({
                background: theme.palette.info.light,
                color: theme.palette.info.dark,
              }),
            },
            {
              props: { color: "primary" },
              style: ({ theme }) => ({
                background: theme.palette.primary.medium,
                color: theme.palette.primary.main,
              }),
            },
            {
              props: { color: "default" },
              style: ({ theme }) => ({
                background: theme.colors.neutral.light[200],
                color: theme.colors.neutral.dark[700],
              }),
            },
            {
              props: { color: "error" },
              style: ({ theme }) => ({
                background: theme.palette.error.light,
                color: theme.palette.error.dark,
              }),
            },
            {
              props: { variant: "outlinedWhite" },
              style: ({ theme }) => ({
                background: theme.colors.neutral.light[100],
                color: theme.colors.neutral.dark[700],
                border: `1px solid ${theme.palette.primary.medium}`,
              }),
            },
          ],
        },
        MuiIconButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              "padding": "0.75rem",
              "textTransform": "none",
              "borderRadius": "0.5rem",
              "&.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                {
                  height: "unset",
                  width: "unset",
                },
              "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                {
                  height: "unset",
                  width: "unset",
                },
              "&.MuiButtonBase-root.MuiIconButton-root:hover": {
                backgroundColor: theme.colors.neutral.light[100],
              },
            }),
            sizeMedium: {
              height: "2.5rem",
              width: "2.5rem",
              svg: {
                height: "1rem",
                width: "1rem",
              },
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              padding: "unset",
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              lineHeight: "1.5rem",
              margin: "unset",
              whiteSpace: "nowrap",
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: "currentcolor",
              minWidth: 0,
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              "padding": "0.375rem 0.5rem",
              "borderRadius": "0.25rem",
              ":hover": {
                background: "#F5F1FE",
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: ({ theme }) => ({
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.primary.medium,
                },
                "&:hover:not(.Mui-disabled) fieldset": {
                  borderColor: theme.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary,
                  borderWidth: "1px",
                },
                "& .MuiInputBase-input.MuiInputBase-inputSizeSmall": {
                  height: "24px",
                },
              },
            }),
          },
        },
        MuiTextField: {
          defaultProps: {
            InputLabelProps: {
              shrink: true,
            },
          },
          styleOverrides: {
            root: ({ theme }) => ({
              "backgroundColor": "#fff",
              "height": "100%",
              "borderRadius": "0.5rem",
              "& input": {
                "&::placeholder": {
                  color: theme.colors.neutral.dark[700],
                  opacity: 1,
                },
              },
            }),
          },
        },
        MuiTooltip: {
          styleOverrides: {
            popper: {
              "&[data-popper-placement*='top'] .MuiTooltip-arrow::before": {
                transformOrigin: "125% 0 !important",
              },
            },
            tooltip: ({ theme }) => ({
              "maxWidth": "30rem",
              "maxHeight": "21.875rem",
              "fontSize": "0.75rem",
              "color": theme.colors.neutral.light[100],
              "backgroundColor": theme.colors.neutral.dark[900],
              "lineHeight": "150%",
              "letterSpacing": "0.4px",
              "boxShadow": "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
              "borderRadius": "0.375rem",
              "padding": "0.25rem 0.5rem",
              "whiteSpace": "pre-line",

              "& .MuiTooltip-arrow": {
                "color": theme.colors.neutral.dark[900],
                "&:before": {
                  borderRadius: "0.125rem",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
                },
              },
              "&.MuiTooltip-tooltipPlacementRight": {
                left: "-0.5rem",
                top: "-0.2rem",
              },
              "&.MuiTooltip-tooltipPlacementTop": {
                top: "0.875rem",
              },
            }),
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: "#D0D0D0",
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              "& .MuiPaper-root": {
                borderRadius: "0.75rem",
              },
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              padding: "1rem",
              fontSize: "1.125rem",
              lineHeight: "1.25rem",
              fontWeight: 600,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: "0.5rem 1rem 1.5rem",
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: ({ theme }) => ({
              background: theme.palette.primary.light,
              padding: "0.75rem 1rem",
            }),
          },
        },
        MuiRadio: {
          defaultProps: {
            icon: <RadioEmpty />,
            checkedIcon: <RadioChecked />,
          },
          styleOverrides: {
            root: ({ theme }) => ({
              color: theme.colors.neutral.medium[400],
            }),
          },
        },
        MuiSwitch: {
          defaultProps: {
            disableRipple: true,
            disableFocusRipple: true,
            disableTouchRipple: true,
          },
          styleOverrides: {
            root: {
              "&.MuiSwitch-root": {
                height: "1rem",
                width: "1.75rem",
                padding: 0,
              },
            },
            thumb: ({ theme }) => ({
              width: "0.75rem",
              height: "0.75rem",
              boxShadow: "unset",
              background: theme.colors.neutral.light[100],
            }),
            track: ({ theme }) => ({
              borderRadius: "1rem",
              opacity: 0.3,
              background: theme.palette.primary.main,
            }),
            switchBase: ({ theme }) => ({
              "width": "1rem",
              "height": "1rem",
              "padding": 0,

              "&.Mui-checked": {
                "transform": "translateX(0.75rem)",

                "& + .MuiSwitch-track": {
                  opacity: 1,
                  background: theme.palette.primary.main,
                },

                "&.Mui-disabled+.MuiSwitch-track": {
                  opacity: 0.4,
                  background: theme.palette.primary.main,
                },
              },

              "&.Mui-disabled+.MuiSwitch-track": {
                opacity: 0.4,
                background: theme.palette.primary.medium,
              },
            }),
          },
        },
        MuiSlider: {
          variants: [
            {
              props: {
                color: "primary",
              },
              style: ({ theme }) => ({
                "& .MuiSlider-rail": {
                  background: theme.palette.primary.medium,
                  opacity: "1",
                },
                "& .MuiSlider-valueLabel": {
                  background: theme.palette.primary.main,
                  padding: "4px 12px",
                  borderRadius: "4px",
                },
                "& .MuiSlider-valueLabelLabel": {
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "20px",
                },
              }),
            },
          ],
        },
        MuiTab: {
          styleOverrides: {
            root: () => ({
              "&.MuiButtonBase-root": {
                textTransform: "unset",
                fontSize: "1rem",
                lineHeight: "24px",
                fontWeight: 500,
                minWidth: "unset",
              },
              "&.MuiTab-root": {
                padding: "6px 0",
                minHeight: "unset",
              },
            }),
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: () => ({
              "&.MuiTabs-root": {
                minHeight: "unset",
              },
              "& .MuiTabs-flexContainer": {
                gap: "24px",
              },
            }),
          },
        },
      },

      typography: {
        fontFamily: ["Figtree", "system-ui", "sans-serif"].join(","),
      },
    },
    isPolishChosen ? plPL : enUS,
    isPolishChosen ? pickersPlPL : pickersEnUS,
    isPolishChosen ? corePlPL : coreEnUS,
  );

  return <ThemeProviderNew theme={theme}>{children}</ThemeProviderNew>;
};
