import { Stack } from "@mui/material";
import { ItemImage } from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { SyntheticEvent, useCallback } from "react";
import NestedAccordion from "@views/dietician/PatientMonitoring2/components/MealsBox/NestedAccordion";
import Macros from "@views/dietician/PatientMonitoring2/components/MealsBox/Macros";
import MealItemName from "@views/dietician/PatientMonitoring2/components/MealsBox/MealItemName";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { useAppTranslation } from "@hooks";
import { ItemRecipe } from "@client/resources/PatientMealsMonitoringResource";

type MealItemRecipeProps = {
  item: ItemRecipe;
  onClickRecipe: (id: number) => void;
  expanded: boolean;
  handleChangeExpanded: (event: SyntheticEvent, isExpanded: boolean) => void;
};
const MealItemRecipe = ({
  item,
  onClickRecipe,
  expanded,
  handleChangeExpanded,
}: MealItemRecipeProps) => {
  const { currentLanguage } = useAppTranslation();
  const itemRecipe = item.item;
  const getNutrientValue = useCallback(
    (id: number) =>
      Math.round(
        itemRecipe.recipe.nutrients.find(n => n.id === id)?.value ?? 0,
      ),
    [itemRecipe],
  );

  const name =
    itemRecipe.recipe.translations.find(t => t.lang === currentLanguage)
      ?.name ?? itemRecipe.recipe.name;

  return (
    <Stack direction="column" spacing="8px">
      <MealItemName
        name={name}
        rating={item.rating}
        onClick={() => onClickRecipe(itemRecipe.recipe.id)}
      />
      <NestedAccordion
        expanded={expanded}
        handleChangeExpanded={handleChangeExpanded}
        sx={{
          "&.MuiPaper-root": {
            margin: "0",
          },
        }}
      >
        <Stack direction="column" spacing="8px">
          <Macros
            kcal={getNutrientValue(ENERGY_ID)}
            protein={getNutrientValue(PROTEIN_ID)}
            fat={getNutrientValue(FATS_ID)}
            carb={getNutrientValue(CARBS_ID)}
          />
          <ItemImage src={itemRecipe.recipe.image ?? undefined} />
        </Stack>
      </NestedAccordion>
    </Stack>
  );
};

export default MealItemRecipe;
