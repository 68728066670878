import { EnergyDecomposition } from "@components/EnergyDecomposition";
import { NutrientsSection } from "@components/PreviewDrawer/common";
import { useCallback, useMemo } from "react";
import NutrientValue from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/NutrientValue";
import {
  getAverageNutrientValue,
  getSumNutrientValue,
} from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValuesUtil";
import { Nutrients } from "@client/dictionaries";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import PatientNutrientsTabs from "@components/Nutrients2/PatientNutrientsTabs";

type SumNutrientsValuesProps = {
  data: NutrientsMonitoringResource[];
  nutrients: Nutrients[];
};

const SumNutrientsValues = ({ data, nutrients }: SumNutrientsValuesProps) => {
  const { patientId } = useMonitoring();
  const getNutrient = useCallback(
    (nutrientId: number) => {
      if (!data?.length) {
        return {
          value: 0,
          target: 0,
        };
      }

      return {
        value: getSumNutrientValue(data, nutrientId, "patientNutrients"),
        target: getSumNutrientValue(data, nutrientId, "dietitianNutrients"),
      };
    },
    [data],
  );

  const dataNutrients = useMemo(() => {
    return nutrients.map(nutrient => ({
      id: nutrient.id,
      value: getSumNutrientValue(data, nutrient.id, "patientNutrients"),
      target: getSumNutrientValue(data, nutrient.id, "dietitianNutrients"),
    }));
  }, [data, nutrients]);

  return (
    <NutrientValue
      macro={
        <EnergyDecomposition
          kcal={getNutrient(ENERGY_ID)}
          protein={getNutrient(PROTEIN_ID)}
          fat={getNutrient(FATS_ID)}
          carb={getNutrient(CARBS_ID)}
        />
      }
      nutrients={
        <PatientNutrientsTabs patientId={patientId} values={dataNutrients} />
      }
    />
  );
};

export default SumNutrientsValues;
