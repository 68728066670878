import { useEffect, useMemo } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";

import { capitalize } from "@mui/material";

import { LangDto } from "@client";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation } from "@hooks";
import { useCreatorDietLanguageVersion } from "@hooks/queries/diets/creator";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { getTranslation } from "@utils/translations";
import { useUpdateDietMealMutation } from "@views/dietician/DietCreator/hooks";

import { LanguageTextField } from "../../DietNameModal/LanguageTextField";
import {
  OwnTypeModalInputs,
  mapOwnTypeForm,
  useOwnTypeModalForm,
} from "./useOwnTypeModalForm";
import { ScheduleRowInputs, mapScheduleRequest } from "./useScheduleRowForm";

interface OwnTypeModalProps {
  open: boolean;
  onClose: () => void;
  mealId: number;
}
export const OwnTypeModal = ({
  onClose,
  mealId,
  ...rest
}: OwnTypeModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { getValues } = useFormContext<ScheduleRowInputs>();
  const { languages } = useCreatorDietLanguageVersion(dietId);
  const { mutate, isLoading } = useUpdateDietMealMutation();

  const onSubmit = (values: OwnTypeModalInputs) => {
    mutate(
      {
        dietId,
        mealId: mealId.toString(),
        payload: mapScheduleRequest({ ...getValues(), ...values }, undefined),
      },
      { onSuccess: handleClose },
    );
  };

  const defaultValues = useMemo(() => mapOwnTypeForm(getValues()), []);
  const { control, reset, handleSubmit } = useOwnTypeModalForm(defaultValues);
  const {
    field: { onChange: changeNamePl },
  } = useController<OwnTypeModalInputs, "namePl">({ name: "namePl", control });
  const {
    field: { onChange: changeNameEn },
  } = useController<OwnTypeModalInputs, "nameEn">({ name: "nameEn", control });
  const value = useWatch<OwnTypeModalInputs, "mealTypeId">({
    name: "mealTypeId",
    control,
  });

  const { getTagCategoryOptions } = useTagsNew();
  const options = getTagCategoryOptions(TagCategoryType.MEAL) ?? [];
  const selectedOption = useMemo(
    () => options.find(o => o.id === value),
    [options, value],
  );

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSubmitWrapper = () =>
    handleSubmit(
      d => onSubmit(d),
      e => console.log(e),
    )();

  useEffect(() => {
    if (value) {
      changeNamePl(getTranslation(selectedOption?.translations, LangDto.PL));
      changeNameEn(getTranslation(selectedOption?.translations, LangDto.EN));
    }
  }, [value]);

  return (
    <ModalWrapper
      title={t("diet.add_own_meal.title")}
      onSubmit={handleSubmitWrapper}
      onClose={handleClose}
      loading={isLoading}
      {...rest}
    >
      <div className="grid gap-[24px]">
        <OuterLabel label={t("diet.meal_type")}>
          <FormAutocomplete
            fullWidth
            size="small"
            disableClearable
            options={options}
            control={control}
            name="mealTypeId"
            id="mealTypeId"
            placeholder={t("diet.add_own_meal.type_placeholder")}
          />
        </OuterLabel>
        <OuterLabel label={t("diet.add_own_meal.meal_name")}>
          {languages.map(langId => (
            <LanguageTextField
              fullWidth
              size="small"
              control={control}
              name={`name${capitalize(langId)}`}
              langPrefix={langId}
              placeholder={t("diet.add_own_meal.name_placeholder")}
              disabled={!value}
            />
          ))}
        </OuterLabel>
      </div>
    </ModalWrapper>
  );
};
