import DietitiansField from "@components/ProgramsFilters/Filters/Fields/DietitiansField";
import ProgramDaysField from "@components/ProgramsFilters/Filters/Fields/ProgramDaysField";
import ProgramDurationField from "@components/ProgramsFilters/Filters/Fields/ProgramDurationField";
import ProgramTabContent from "@components/ProgramsFilters/Filters/ProgramTabContent";

const ProgramFilterTabContent = () => {
  return (
    <ProgramTabContent>
      <DietitiansField />
      <ProgramDaysField />
      <ProgramDurationField />
    </ProgramTabContent>
  );
};

export default ProgramFilterTabContent;
