import { fetchUsedRecipes } from "@client/schedule";
import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "../common";

export const fetchUsedRecipesQueryKey = "fetchUsedRecipes";

export const useFetchUsedRecipesQuery = (programId: number) => {
  const { data, ...rest } = useQuery(
    [fetchUsedRecipesQueryKey],
    () => fetchUsedRecipes(programId),
    buildDefaultQueryOptions({
      refetchOnWindowFocus: false,
    }),
  );
  return { recipes: data?.data, ...rest };
};
