import { RecipeTabPanels } from "@components/PreviewDrawer/Recipe";
import { useFetchRecipeQuery } from "@hooks/queries";
import { CircularProgress } from "@mui/material";
import { RecipeNutrientsTab } from "../RecipeNutrientsTab/RecipeNutrientsTab";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { useAppTranslation, useMealClientContext } from "@hooks";
import { useItemsContext } from "../ItemsContext";
import { useFetchCollectionSearchPreviewRecipeQuery } from "@hooks/queries/collections";
import { getTranslation } from "@utils/translations";

interface RecipeCollectionContentProps {
  id: number;
  updateServings: (servings: number) => void;
  collectionId: number;
  itemId: number;
}
export const RecipeCollectionContent = ({
  id,
  updateServings,
  collectionId,
  itemId,
}: RecipeCollectionContentProps) => {
  const { data: recipe, isLoading } =
    useFetchCollectionSearchPreviewRecipeQuery(
      collectionId.toString(),
      itemId.toString(),
    );
  const { currentLanguage } = useAppTranslation();
  const { getRecipeServings } = useItemsContext();

  if (isLoading) return <CircularProgress />;
  if (!recipe) return null;

  const { nutrients, description, products, tags } = recipe.recipe;
  return (
    <ServingContextProvider
      totalServings={recipe.servings}
      servings={getRecipeServings(id) ?? undefined}
    >
      <RecipeTabPanels
        nutrients={nutrients}
        description={description ?? ""}
        descriptionEn={""}
        products={products.map(({ grams, id, measure, product }) => ({
          grams: grams,
          id: id,
          foodId: product.id,
          measureId: measure.id,
          measures: product.measures,
          name: getTranslation(product.translations, currentLanguage),
          nutrients: product.nutrients,
        }))}
        tags={tags.map(t => t.id)}
        NutrientsTab={props => (
          <RecipeNutrientsTab {...props} onServingsChange={updateServings} />
        )}
        padding="8px"
      />
    </ServingContextProvider>
  );
};
