import { Button, Card, styled } from "@mui/material";

export const LaunchBannerSmallWrapper = styled(Card)`
  display: flex;
  align-items: center;
  height: 100%;
  background: ${({ theme }) => theme.palette.primary.medium};
  box-shadow: 0px 4px 4px 0px #00000025;
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  justify-content: space-between;
`;

export const LaunchBannerTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fontFamily.sueEllenFrancisco};
  font-size: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
  letter-spacing: 1.2px;
`;

export const LaunchBannerContent = styled("p")`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  margin-top: 12px;
`;

export const StyledImg = styled("img")`
  width: 55px;
  height: 55px;
`;

export const ArrowImg = styled("img")`
  width: 39px;
  height: 39px;
`;

export const QrCodeSectionWrapper = styled("div")`
  display: grid;
  grid-template-columns: 40px 156px;
  gap: 12px;
  margin-left: auto;
`;

export const QrCodeSection = styled("div")`
  position: relative;
  display: flex;
  gap: 12px;
`;

export const QrCodeWrapperSmall = styled("div")`
  display: grid;
  grid-template-rows: 22px 1fr;
  gap: 4px;
  width: 72px;
  justify-items: center;
  justify-content: center;
`;
