import * as yup from "yup";
import { fetchData } from "@utils/api";
import {
  APIMethods,
  MetadataSchema,
  PaginationApiResponse,
} from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import { FetchMonitoringIrrigationParams } from "@client/patient/monitoring/fetchMonitoringIrrigation";
import {
  DietitianMealsMonitoringResource,
  dietitianMealsMonitoringSchema,
} from "@client/resources/DietitianMealsMonitoringResource";

export type FetchMonitoringMealsDietitianParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringMealsDietitianResponse = PaginationApiResponse<
  DietitianMealsMonitoringResource[]
>;

export const fetchMonitoringMealsDietitian = async (
  params: FetchMonitoringMealsDietitianParams,
): Promise<FetchMonitoringMealsDietitianResponse> => {
  const resource = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/meals-dietitian`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(resource)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/meals-dietitian",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringIrrigationParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(dietitianMealsMonitoringSchema).required(),
  meta: MetadataSchema.defined(),
});
