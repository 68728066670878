import "./sentry";
import React from "react";

import { toast, ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { pdfjs } from "react-pdf";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LogRocket from "logrocket";

import "./assets/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import "@services/axios";
import "@services/i18n";
import "./lib/dayjsConfig";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { ThemeProviderWrapper } from "./theme";
import { ValidationError } from "yup";
import { useAppTranslation } from "@hooks";
import { createRoot } from "react-dom/client";
import { TourTriggerContextProvider } from "@context";
import { FeatureFlagsContextProvider } from "@utils";
import { ALLOWEAT_API_ERROR } from "@utils";
import { VisibleNutrientsContextProvider } from "@context/VisibleNutrientsContext";
import { AxiosError } from "axios";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CheckInCircleIcon from "@icons/checkInCircle.svg?react";
import ExclamationMarkInCircleIcon from "@icons/exclamationMarkInCircle.svg?react";
import ExclamationMarkInTriangleIcon from "@icons/exclamationMarkInTriangle.svg?react";
import IInCircle from "@icons/iInCircle.svg?react";

// DON'T DELETE!!!! For libraries that use the global object, even though it doesn't exist in the browser
if (typeof window !== "undefined") {
  window.global = window;
}

const ErrWarn = () => {
  const { t } = useAppTranslation();

  return <span>{t("errors.unexpected")}</span>;
};
const LogoutWarn = () => {
  const { t } = useAppTranslation();

  return <span>{t("common.session_expired")}</span>;
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        console.log(e);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!window.subscriptionOK) return;

        if (e?.code === AxiosError.ERR_NETWORK) return;
        else if (e?.response?.status === 401)
          toast.error(<LogoutWarn />, { toastId: "logout" });
        else toast.error(<ErrWarn />, { toastId: "error" });

        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          import.meta.env.VITE_APP_ENVIRONMENT === "prod" &&
          typeof e?.response.status === "number" &&
          e.response.status >= 400
        )
          LogRocket.track(ALLOWEAT_API_ERROR);
      },
      retry: (failureCount, error: any) => {
        if (error.code === AxiosError.ERR_NETWORK) return false;

        if (error instanceof ValidationError) {
          return false;
        }

        if (typeof error?.code === "number") {
          if (error.code >= 400 && error.code < 500) {
            return false;
          }
        }

        if (typeof error?.response.status === "number") {
          if (
            (error.response.status >= 400 && error.response.status < 500) ||
            error.response.status === 503
          ) {
            return false;
          }
        }

        return failureCount < 3;
      },
    },
    mutations: {
      onError: (e: any) => {
        console.log(e);
        if (e === "Already confirmed") {
          return false;
        }
        toast.error(<ErrWarn />);
      },
    },
  },
});

createRoot(document.getElementById("root")!).render(
  <ThemeProviderWrapper>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <TourTriggerContextProvider>
          <FeatureFlagsContextProvider>
            <VisibleNutrientsContextProvider>
              <App />
            </VisibleNutrientsContextProvider>
          </FeatureFlagsContextProvider>
        </TourTriggerContextProvider>
        {(import.meta.env.VITE_APP_ENVIRONMENT ?? "") === "local" ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
      </QueryClientProvider>
      <ToastContainer
        icon={({ type }) => {
          if (type === "success") return <CheckInCircleIcon />;
          if (type === "error") return <ExclamationMarkInCircleIcon />;
          if (type === "warning") return <ExclamationMarkInTriangleIcon />;
          if (type === "info") return <IInCircle />;
        }}
      />
    </LocalizationProvider>
  </ThemeProviderWrapper>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
