import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FetchMealResponse, fetchCreatorMealNutrients } from "@client/meals";
import { QueryOptionsTyped, fetchCreatorMealQueryKey } from "@hooks/queries";
import { NutrientDto } from "@client";
import { ApiResponse } from "@typeDefinitions";
import { update } from "lodash";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const fetchCreatorMealNutrientsQueryKey =
  "fetchCreatorMealNutrientsQueryKey";

export const useFetchCreatorMealNutrientsQuery = (
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<NutrientDto[]>>,
) => {
  const queryClient = useQueryClient();
  const version = useMealVersion();

  const { data, ...rest } = useQuery(
    [fetchCreatorMealNutrientsQueryKey, mealId],
    () => fetchCreatorMealNutrients(mealId, version),
    {
      ...options,
      onSuccess: data => {
        options?.onSuccess && options.onSuccess(data);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return update(old, "data.nutrients", () => data.data);
          },
        );
      },
    },
  );

  return {
    nutrients: data?.data,
    ...rest,
  };
};
