import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppTranslation, useClientParams, useModal } from "@hooks";

import { TabContext } from "@mui/lab";
import {
  StyledBox,
  StyledTab,
  StyledTabList,
  TabsWrapper,
} from "./ClientLayout.styled";
import { useFetchClientQuery } from "@hooks/queries";
import { ThemeProviderWrapperNew } from "themeNew";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ClientTitle } from "./components/ClientTitle";
import { ExtraButtons } from "./components/ExtraButtons";
import { AddVisitModal } from "@views/dietician/Diet/components/AddVisitModal";
import { lazy, Suspense, useContext, useEffect } from "react";
import { SendQuestionnaireModal } from "@views/dietician/Questionnaires/SendQuestionnaireModal";
import { Tooltip } from "@mui/material";
import { Error404 } from "@views/emptyStates/Error404";
import { Spinner } from "@components/Spinner";
import { ChatboxContext } from "@context/ChatboxContext";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

const MonitoringOnboardingButton = lazy(
  () =>
    import("@views/dietician/PatientMonitoring2/onboarding/OnboardingButton"),
);
const PatientProgramsOnboardingButton = lazy(
  () =>
    import("@views/dietician/PatientProgramsNew/onboarding/OnboardingButton"),
);

export const ClientLayout = () => {
  const { t } = useAppTranslation();
  const newMobileAppFeatureEnabled = useNewMobileAppFeatureEnabled();
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = useClientParams();
  const { openChat } = useContext(ChatboxContext);
  const { client, isLoading } = useFetchClientQuery(clientId, {
    onError: e => {
      if (e === 404) return;
    },
  });
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const {
    modalOpened: sendModalOpened,
    onModalClose: onSendModalClose,
    onModalOpen: onSendModalOpen,
  } = useModal();
  const chat = client?.profile.applicationModules.chat;
  const hasEmail = !!client?.email;
  const isActive = !!client?.active;

  enum ProfileTabs {
    HEALTH = "1",
    INFO = "2",
    APPOINTMENTS = "3",
    ENERGY = "4",
    PROGRAMS = "5",
    PROGRAMS_NEW = "5_n",
    PROGRESS = "6",
    MONITORING = "7",
  }
  const clientProfileTabs: Record<string, string> = {
    [ProfileTabs.HEALTH]: "health-and-goals",
    [ProfileTabs.INFO]: "info-and-services",
    [ProfileTabs.APPOINTMENTS]: "appointments",
    [ProfileTabs.ENERGY]: "energy-balance",
    [ProfileTabs.PROGRAMS]: "nutritional-programs",
    [ProfileTabs.PROGRAMS_NEW]: "programs",
    [ProfileTabs.PROGRESS]: "progress-tracking",
    [ProfileTabs.MONITORING]: "monitoring",
  };

  const checkedTab = (path: string): string => {
    for (const [key, value] of Object.entries(clientProfileTabs)) {
      if (path.includes(value)) {
        return key;
      }
    }
    return ProfileTabs.INFO;
  };

  useEffect(() => {
    modalOpened && navigate(clientProfileTabs[3]);
  }, [modalOpened]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) =>
    navigate(clientProfileTabs[newValue]);

  const handleOpenChat = () => {
    if (!client) return;

    const { id, email, firstName, lastName } = client;

    if (id && email && (firstName || lastName))
      openChat({
        id: id.toString(),
        email,
        name: [firstName, lastName].join(" "),
      });
  };

  if (!client && !isLoading) return <Error404 />;
  if (isLoading) return <Spinner />;

  return (
    <ThemeProviderWrapperNew>
      <div id="clientLayout">
        {client && (
          <SingleCardPageLayout
            rebranding
            title={<ClientTitle client={client} />}
            childrenClassName="flex flex-col gap-8"
            extra={
              <ExtraButtons
                id={clientId}
                chat={chat}
                onVisitModal={onModalOpen}
                onQuestionnaireModal={onSendModalOpen}
                hasEmail={hasEmail}
                isActive={isActive}
                onChatOpen={handleOpenChat}
              />
            }
          >
            <TabsWrapper>
              <TabContext value={checkedTab(location.pathname)}>
                <StyledBox>
                  <StyledTabList
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                  >
                    <StyledTab
                      label={t("client_profile.tabs.health_and_goals")}
                      value={ProfileTabs.HEALTH}
                    />
                    <StyledTab
                      label={t("client_profile.tabs.info_and_services")}
                      value={ProfileTabs.INFO}
                    />
                    <StyledTab
                      label={t("client_profile.tabs.appointments")}
                      value={ProfileTabs.APPOINTMENTS}
                    />
                    {!isActive ? (
                      <Tooltip
                        title={t("client_profile.tabs.activate_client_tooltip")}
                        disableHoverListener={isActive}
                        arrow
                        placement="right-end"
                      >
                        <div>
                          <StyledTab
                            label={t("client_profile.tabs.energy_balance")}
                            value={ProfileTabs.ENERGY}
                            disabled={!isActive}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <StyledTab
                        label={t("client_profile.tabs.energy_balance")}
                        value={ProfileTabs.ENERGY}
                      />
                    )}
                    <StyledTab
                      label={t("client_profile.tabs.programs")}
                      value={ProfileTabs.PROGRAMS_NEW}
                    />
                    {!newMobileAppFeatureEnabled &&
                      (!isActive ? (
                        <Tooltip
                          title={t(
                            "client_profile.tabs.activate_client_tooltip",
                          )}
                          disableHoverListener={isActive}
                          arrow
                          placement="right-end"
                        >
                          <div>
                            <StyledTab
                              label={t("client_profile.tabs.progress_tracking")}
                              value={ProfileTabs.PROGRESS}
                              disabled={!isActive}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <StyledTab
                          label={t("client_profile.tabs.progress_tracking")}
                          value={ProfileTabs.PROGRESS}
                        />
                      ))}
                    {newMobileAppFeatureEnabled &&
                      (!isActive ? (
                        <Tooltip
                          title={t(
                            "client_profile.tabs.activate_client_tooltip",
                          )}
                          disableHoverListener={isActive}
                          arrow
                          placement="right-end"
                        >
                          <div>
                            <StyledTab
                              label={t("client_profile.tabs.progress_tracking")}
                              value={ProfileTabs.MONITORING}
                              disabled={true}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <StyledTab
                          label={t("client_profile.tabs.progress_tracking")}
                          value={ProfileTabs.MONITORING}
                        />
                      ))}
                  </StyledTabList>
                  {checkedTab(location.pathname) === "7" && (
                    <Suspense fallback={null}>
                      <MonitoringOnboardingButton />
                    </Suspense>
                  )}
                  {checkedTab(location.pathname) === "5_n" && (
                    <Suspense fallback={null}>
                      <PatientProgramsOnboardingButton />
                    </Suspense>
                  )}
                </StyledBox>
                <Outlet />
              </TabContext>
            </TabsWrapper>
          </SingleCardPageLayout>
        )}
      </div>
      {modalOpened && (
        <AddVisitModal
          onOpen={modalOpened}
          onClose={onModalClose}
          defaultClient={clientId}
          navigateToVisit
        />
      )}
      <SendQuestionnaireModal
        onClose={onSendModalClose}
        open={sendModalOpened}
      />
    </ThemeProviderWrapperNew>
  );
};
