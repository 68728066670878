import React from "react";

import { range } from "lodash";
import classnames from "classnames";

import { useAppTranslation } from "@hooks";
import { Select } from "@components/Select";
import { SelectOption } from "@components/SelectOption";

export const RecipeServingsEditor = ({
  servings,
  totalServings,
  setServings,
  className,
}: RecipeServingsEditorProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  const handleServingsChange = (s?: string | number) => {
    setServings(s as number);
  };

  return (
    <div className={classnames("w-1/8 grid", className)}>
      <span className="font-semibold text-xs">{t("products.portions")}</span>
      <Select
        onChange={handleServingsChange}
        value={servings}
        fullWidth
        textCenter
      >
        {range(1, totalServings + 1).map((option, index) => (
          <SelectOption textCenter key={`option-${index}`} value={option}>
            {`${option.toString()} ${translateNumOfServings(
              option,
              isPolishChosen,
            )}`}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};

export interface RecipeServingsEditorProps {
  servings: number;
  totalServings: number;
  setServings: (servings: number) => void;
  className?: string;
}

const translateNumOfServings = (
  numOfServings: number,
  isPolishChosen: boolean,
): string => {
  if (isPolishChosen) {
    if (numOfServings === 1) return "porcja";
    if ([2, 3, 4].includes(numOfServings)) return "porcje";
    return "porcji";
  }
  if (numOfServings === 1) return "portion";
  return "portions";
};
