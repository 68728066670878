import {
  BuilderWrapper,
  DrawerActions,
  DrawerHeader,
  IconButtonStyled,
  StyledDrawer,
  TabPanelStyled,
  TabsStyled,
  TabStyled,
  TitleStyled,
} from "@components/Filters/FiltersDrawer.styled";
import { Close } from "@assets/icons";
import { Button } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { useState } from "react";
import ProgramFilterTabContent from "@components/ProgramsFilters/Filters/ProgramFilterTabContent";
import ProgramDietTabContent from "@components/ProgramsFilters/Filters/ProgramDietTabContent";
import ProgramNutrientsTabContent from "@components/ProgramsFilters/Filters/ProgramNutrientsTabContent";
import { TabContext } from "@mui/lab";
import { useFormContext } from "react-hook-form";
import { ProgramsFiltersFormDefaultValues } from "@views/dietician/Programs/ProgramsFiltersFormType";

type ProgramFiltersDrawerProps = {
  open: boolean;
  onClose: () => void;
  total: number;
};
const ProgramFiltersDrawer = ({
  open,
  onClose,
  total,
}: ProgramFiltersDrawerProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState("1");

  return (
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            background: "transparent",
          },
        },
      }}
      PaperProps={{
        className: "scrollbar-thin",
      }}
    >
      <TabContext value={tab}>
        <IconButtonStyled onClick={onClose} size="small">
          <Close size="w-[12px] h-[12px]" />
        </IconButtonStyled>
        <DrawerHeader>
          <TitleStyled>{t("filters.filters")}</TitleStyled>

          <TabsStyled value={tab} onChange={(_, v) => setTab(v)}>
            <TabStyled label={t("common.nutrition_program")} value="1" />
            <TabStyled label={t("common.diet")} value="2" />
            <TabStyled label={t("recipes.filters.nutrients")} value="3" />
          </TabsStyled>
        </DrawerHeader>

        <TabPanelStyled value="1">
          <BuilderWrapper>
            <ProgramFilterTabContent />
          </BuilderWrapper>
        </TabPanelStyled>
        <TabPanelStyled value="2">
          <BuilderWrapper>
            <ProgramDietTabContent />
          </BuilderWrapper>
        </TabPanelStyled>
        <TabPanelStyled value="3">
          <BuilderWrapper>
            <ProgramNutrientsTabContent />
          </BuilderWrapper>
        </TabPanelStyled>

        <DrawerActions>
          <ClearButton onClose={onClose} />
          <Button variant="contained" onClick={onClose}>
            {t("common.show")} {`(${total})`}
          </Button>
        </DrawerActions>
      </TabContext>
    </StyledDrawer>
  );
};

type ClearButtonType = {
  onClose: () => void;
};
const ClearButton = ({ onClose }: ClearButtonType) => {
  const { t } = useAppTranslation();
  const { reset } = useFormContext();
  const handleClearFormValues = () => {
    reset(ProgramsFiltersFormDefaultValues);
    onClose();
  };

  return (
    <Button variant="outlined" onClick={handleClearFormValues}>
      {t("filters.clean_all")}
    </Button>
  );
};

export default ProgramFiltersDrawer;
