import { MouseEventHandler, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Alert } from "@mui/material";

import { AutosaveOnBlurProvider } from "@components/AutosaveOnBlur";
import { TabSwitch } from "@components/TabSwitch";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import {
  useFetchCreatorDietNutrientsQuery,
  useUpdateCreatorDietTargetNutrientsMutation,
} from "@hooks/queries/diets/creator";
import { Nutrient } from "@typeDefinitions";
import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";

import { CardWrapper } from "../common";
import { EnergyComponent } from "./EnergyComponent";
import { MacroComponent } from "./MacroComponent";
import {
  IconButtonStyled,
  MacrosListWrapper,
  TabSwitchWrapper,
} from "./MacrosCard.styled";
import { Unit, sumMacros } from "./macrosUtils";
import {
  MacrosFormInput,
  mapMacrosForm,
  mapMacrosRequest,
  useMacrosForm,
} from "./useMacrosForm";
import {
  useFetchDietNutrientsNormQuery,
  useUpdateDietNutrientsNormCombinedMutation,
} from "../../hooks";
import { mapNutrientsNormRequest } from "../NutrientsCard/useNutrientsNormForm";
import { Eye } from "@assets/icons";
import { useNutrientsSidePanelContext } from "../RecipesTab/NutrientsSidePanel";

interface MacrosCardProps {
  target: Nutrient[];
}
export const MacrosCard = ({ target }: MacrosCardProps) => {
  const { t } = useAppTranslation();
  const { dietId, patientId } = useAppParams();

  const { nutrients } = useFetchCreatorDietNutrientsQuery(dietId);
  const { mutate, isLoading } = useUpdateCreatorDietTargetNutrientsMutation();
  const { norm } = useFetchDietNutrientsNormQuery(dietId);
  const { mutate: mutateNorm, isLoading: isMutatingNorm } =
    useUpdateDietNutrientsNormCombinedMutation();
  const { onOpen } = useNutrientsSidePanelContext();
  const [unit, setUnit] = useState(Unit.GRAMS);

  const unitList = [
    {
      id: Unit.GRAMS,
      label: Unit.GRAMS.toLowerCase(),
    },
    {
      id: Unit.PERCENTAGE,
      label: Unit.PERCENTAGE,
    },
  ];

  const mappedForm = useMemo(() => mapMacrosForm(target), [target]);

  const form = useMacrosForm(mappedForm);

  const onSubmit = (data: MacrosFormInput) => {
    const macrosArray = mapMacrosRequest(data);

    const updatedNormNutrients = norm?.nutrients?.map(nutrient => {
      const matchingMacro = macrosArray.nutrients.find(
        macro => macro.id === nutrient.nutrient.id,
      );

      if (matchingMacro) {
        return {
          id: nutrient.nutrient.id,
          value: matchingMacro.value.toString(),
          show: true,
        };
      }
      return {
        id: nutrient.nutrient.id,
        value: nutrient.value.toString(),
        show: nutrient.show,
      };
    });

    mutate({
      dietId,
      payload: macrosArray,
    });

    if (norm && updatedNormNutrients?.length) {
      const normId = norm.norm?.id.toString() ?? null;
      mutateNorm({
        dietId,
        payload: mapNutrientsNormRequest({
          normId,
          nutrients: updatedNormNutrients,
        }),
      });
    }
  };

  const isSumHundred = () => {
    const { kcal = 1, protein, carbs, fat } = form.getValues();

    const totalMacros = sumMacros(fat, carbs, protein);
    const percentage = (totalMacros / kcal) * 100;

    return percentage >= 99 && percentage <= 101;
  };

  const showErrorSum = useMemo(() => !isSumHundred(), [form.getValues()]);

  const handleOpenNutrients: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpen(nutrients);
  };

  return (
    <CardWrapper
      title={
        patientId ? t("diet.nutritonal_values") : t("diet.energy_and_nutrients")
      }
      extras={
        patientId ? (
          <IconButtonStyled
            size="small"
            color="primary"
            onClick={handleOpenNutrients}
          >
            <Eye size="w-[16px] h-[16px]" />
          </IconButtonStyled>
        ) : undefined
      }
    >
      <AutosaveOnBlurProvider>
        <FormProvider {...form}>
          <AutosaveOnBlurProvider.Watch
            onSubmit={onSubmit}
            isLoading={isLoading || isMutatingNorm}
          />
          <MacrosListWrapper>
            <EnergyComponent />

            <TabSwitchWrapper>
              <TabSwitch
                tabs={unitList}
                value={unit}
                onChange={v => setUnit(v)}
              />
            </TabSwitchWrapper>

            <MacroComponent
              label={t("common.protein")}
              name="protein"
              unit={unit}
              multiplier={ENERGY_PER_GRAM_OF_PROTEIN}
              warning={showErrorSum}
            />

            <MacroComponent
              label={t("common.fats")}
              name="fat"
              unit={unit}
              multiplier={ENERGY_PER_GRAM_OF_FAT}
              warning={showErrorSum}
            />

            <MacroComponent
              label={t("common.carbs")}
              name="carbs"
              unit={unit}
              multiplier={ENERGY_PER_GRAM_OF_CARB}
              warning={showErrorSum}
            />
          </MacrosListWrapper>
          {showErrorSum ? (
            <Alert severity="error">{t("diet.sums_up_error")}</Alert>
          ) : (
            <Alert severity="success">{t("diet.sums_up_success")}</Alert>
          )}
        </FormProvider>
      </AutosaveOnBlurProvider>
    </CardWrapper>
  );
};
