import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  updateCreatorMealProduct,
  UpdateCreatorMealProductRequest,
} from "@client/meals";
import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useUpdateCreatorMealProductMutation = (
  mealId: string,
  productId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const version = useMealVersion();

  const mutation = useMutation<
    unknown,
    unknown,
    UpdateCreatorMealProductRequest
  >(payload => updateCreatorMealProduct(mealId, productId, payload, version), {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
      queryClient.invalidateQueries([
        fetchCreatorMealNutrientsQueryKey,
        mealId,
      ]);
    },
    onMutate: async productRequest => {
      await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
      queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
        [fetchCreatorMealQueryKey, mealId],
        old => {
          if (!old) return;
          return {
            ...old,
            data: {
              ...old.data,
              version: old.data.version + 1,
              products: old.data.products.map(p => {
                if (p.id.toString() !== productId) return p;
                else
                  return {
                    ...p,
                    grams: productRequest.grams,
                    measure: productRequest.foodMeasure,
                  };
              }),
            },
          };
        },
      );
    },
    onError: () => {
      queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
    },
  });

  return mutation;
};
