import { DividerStyled } from "@components/PreviewDrawer/Recipe/NutrientsTab/NutrientsTab.styled";
import { Box, Stack } from "@mui/material";
import { NutrientBoxSubtitle } from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSection.styled";
import { useAppTranslation } from "@hooks";

type NutrientValueProps = {
  macro: JSX.Element;
  nutrients: JSX.Element;
};

const NutrientValue = ({ macro, nutrients }: NutrientValueProps) => {
  const { t } = useAppTranslation();
  return (
    <Stack spacing="24px">
      {macro}
      <DividerStyled />
      <Box display="flex" flexDirection="column" gap="12px">
        <NutrientBoxSubtitle>{t("common.micronutrients")}</NutrientBoxSubtitle>
        {nutrients}
      </Box>
    </Stack>
  );
};

export default NutrientValue;
