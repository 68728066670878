import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Header } from "./components";
import { DrawerMenu } from "./components/DrawerMenu";
import { ThemeProviderWrapperNew } from "themeNew";
import { DashboardContent, PageLayoutWrapper } from "./Dashboard.styled";
import { useLocalStorage } from "react-use";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useAppTranslation, useModal, useUserRoles } from "@hooks";
import { Error503 } from "@views/emptyStates/Error503";
import { useQueryClient } from "@tanstack/react-query";
import { SystemNotificationBanner } from "./components/Banners";
import { TrialModal } from "@components/TrialModal";
import { getSubscriptionInfo } from "@utils/subscription";
import { useMediaQuery, useTheme } from "@mui/material";
import { NotSupportedSizeLayout } from "@layouts/NotSupportedSizeLayout";
import { ChatboxContextProvider } from "@context/ChatboxContext/ChatboxContextProvider";
import { TasksContextProvider } from "@views/dietician/Tasks/Tasks.context";
import { TasksSuggestedContextProvider } from "@views/dietician/Tasks/components/TasksSuggested/TasksSuggestedPanel.context";
import { TasksViewSettingsContextProvider } from "@views/dietician/Tasks/components/TaskViewSettings.context";
import { TasksCreateModal } from "@views/dietician/Tasks/components/TasksCreateModal/TasksCreateModal";
import { NotesSidePanel } from "@components/NotesSidePanel/NotesSidePanel";
import { NotesSidePanelContextProvider } from "@components/NotesSidePanel/NotesSidePanel.context";

const SIDEBAR_STATE_KEY = "sidebarStateKey";

export const Dashboard = () => {
  const location = useLocation();
  const rootRef = useRef<HTMLDivElement>(null);
  const { account } = useFetchDietitianAccountQuery();
  const { hasSubscription, isTrial } = getSubscriptionInfo();
  const { i18n } = useAppTranslation();
  const { isClinicAdmin } = useUserRoles();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const [open, setOpen] = useLocalStorage(SIDEBAR_STATE_KEY, true);
  const [showError, setShowError] = useState(false);
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  const shouldOpenTrial =
    (hasSubscription !== undefined &&
      [0, 1, 2, 3, null].includes(hasSubscription) &&
      isTrial) ||
    (!isTrial && hasSubscription === null);
  useEffect(() => {
    const clientLayout = document.getElementById("clientLayout");
    if (rootRef.current && !clientLayout) {
      rootRef.current.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (shouldOpenTrial && isClinicAdmin) {
      onModalOpen();
    }
    i18n.changeLanguage(account?.language);
  }, []);
  const queryClient = useQueryClient();
  const queryOptions = queryClient.defaultQueryOptions();

  queryClient.setDefaultOptions({
    queries: {
      ...queryOptions,
      onError: (e: any) => {
        if (e?.response?.status === 503) setShowError(true);
        queryOptions.onError && queryOptions.onError(e);
      },
    },
  });

  if (downSmall) {
    return (
      <div className="w-full h-full">
        <NotSupportedSizeLayout />
      </div>
    );
  }

  return (
    <ChatboxContextProvider>
      <TasksContextProvider>
        <TasksSuggestedContextProvider>
          <TasksViewSettingsContextProvider>
            <NotesSidePanelContextProvider>
              <div className="w-full h-full">
                <ThemeProviderWrapperNew>
                  <Header />
                  <DrawerMenu open={open} setOpen={setOpen} />
                </ThemeProviderWrapperNew>
                <DashboardContent ref={rootRef} indentLeft={open}>
                  <PageLayoutWrapper>
                    <SystemNotificationBanner endsIn={hasSubscription} />
                    {showError ? <Error503 /> : <Outlet />}
                  </PageLayoutWrapper>
                </DashboardContent>
                <TrialModal
                  open={modalOpened}
                  onClose={onModalClose}
                  endsIn={hasSubscription}
                  isTrial={isTrial}
                />
                <ThemeProviderWrapperNew>
                  <TasksCreateModal />
                  <NotesSidePanel />
                </ThemeProviderWrapperNew>
              </div>
            </NotesSidePanelContextProvider>
          </TasksViewSettingsContextProvider>
        </TasksSuggestedContextProvider>
      </TasksContextProvider>
    </ChatboxContextProvider>
  );
};
