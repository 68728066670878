import { Stack } from "@mui/material";
import { useMemo } from "react";
import { BoxTitleContainer } from "@views/dietician/PatientMonitoring2/components/BoxWrapper";
import NutrientsChartsSection from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsChartsSection";
import MonitoringDivider from "@views/dietician/PatientMonitoring2/components/MonitoringDivider";
import {
  Type,
  SelectedDaysType,
  useMonitoring,
} from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringNutrients } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import NutrientsValuesSection from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSection";
import { useAppTranslation } from "@hooks";
import { NutrientValuesBoxWrapper } from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValuesBoxWrapper";
import { KcalMonitoring } from "@assets/icons/Monitoring";

const NutrientValuesBox = () => {
  const { patientId, type, selectedDays } = useMonitoring();
  const { t } = useAppTranslation();

  const { data } = useFetchMonitoringNutrients({
    patientId,
    ...getPeriod(type.type, selectedDays.selectDays),
  });

  const nutrientsValueByType = useMemo(() => {
    if (data === undefined) {
      return [];
    }

    if (type.type !== "day") {
      return data.data;
    }

    return data.data.filter(
      i => i.date === selectedDays.selectDays.from.format("YYYY-MM-DD"),
    );
  }, [type.type, selectedDays, data]);

  return (
    <NutrientValuesBoxWrapper id="">
      <Stack spacing="24px">
        <BoxTitleContainer
          icon={<KcalMonitoring fill="#727272" />}
          title={t("monitoring2.nutritional_box.title")}
        />
        <NutrientsChartsSection data={data?.data ?? []} />
        <MonitoringDivider />
        <NutrientsValuesSection data={nutrientsValueByType} type={type.type} />
      </Stack>
    </NutrientValuesBoxWrapper>
  );
};

const getPeriod = (type: Type, selectDays: SelectedDaysType) => {
  if (type === "day") {
    return {
      dateFrom: selectDays.from.clone().add(-3, "days").format("YYYY-MM-DD"),
      dateTo: selectDays.to.clone().add(3, "days").format("YYYY-MM-DD"),
    };
  }

  return mapSelectedDaysToRequestQueryParams(selectDays);
};

export default NutrientValuesBox;
