import * as yup from "yup";

import { fetchData } from "@utils/api";
import { Roles } from "@utils/settings";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { LangDto } from "@client";

export const fetchDietitianAccount = async (): Promise<
  ApiResponse<FetchDietitianAccountResponse>
> => {
  const response = await fetchData("/dietitian/account", APIMethods.GET);
  return fetchDietitianAccountSchema.validate(response);
};

export interface FetchDietitianAccountResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: Roles[];
  phone: PhoneDto;
  language: LangDto;
  clinic: ClinicDto | null;
  avatar: string | null;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface ClinicDto {
  id: number;
  subscription: SubscriptionDto;
  subscriptionNew: SubscriptionNewDto | null;
}

export interface SubscriptionDto {
  finishedAt: string | null;
  type: TypeDto;
}

export interface SubscriptionNewDto {
  finishedAt: string | null;
  startedAt: string;
  subscription: SubscriptionNameDto;
  programPdfType?: string;
}

export interface SubscriptionNameDto {
  id: number;
  name: string;
}

interface TypeDto {
  id: number;
}

const phoneSchema = yup.object().shape({
  prefix: yup.string().nullable().default(null),
  number: yup.string().nullable().default(null),
});

const typeSchema = yup.object().shape({
  id: yup.number().required(),
});

const subscriptionSchema = yup.object().shape({
  finishedAt: yup.string().nullable().default(null),
  type: typeSchema.required(),
});

const subscriptionNameSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const subscriptionNewSchema = yup.object().shape({
  finishedAt: yup.string().nullable().default(null),
  startedAt: yup.string().required(),
  subscription: subscriptionNameSchema.required(),
});

const clinicSchema = yup.object().shape({
  id: yup.number().required(),
  subscription: subscriptionSchema.required(),
  subscriptionNew: subscriptionNewSchema.nullable().default(null),
});

const dietitianAccountSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  avatar: yup.string().nullable().default(null),
  roles: yup
    .array()
    .of(yup.mixed<Roles>().oneOf(Object.values(Roles)).required())
    .required(),
  phone: phoneSchema.required(),
  language: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  clinic: clinicSchema.nullable().default(null),
});

const fetchDietitianAccountSchema = yup.object().shape({
  data: dietitianAccountSchema.required(),
});
