import { useController } from "react-hook-form";

import { isArray, uniq } from "lodash";

import { ExpandableTagAutocomplete } from "@components/ExpandableTagAutocomplete";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { OptionIf, useTagsNew } from "@hooks/useTagsNew";
import { RECIPE_TAG_CATEGORIES_TYPE, TagCategoryType } from "@utils/tagsNew";

import { RecipeEditInput } from "../useRecipeEditForm";

export const TagsPanel = () => {
  const { t } = useAppTranslation();
  const { getTagCategoryOptions } = useTagsNew();
  const {
    field: { value: tags, onChange },
  } = useController<RecipeEditInput, "tags">({ name: "tags" });

  const onChangeTyped = (v: number[]) => onChange(v);

  const handleChangeSimplified = (
    selected: string[],
    options: OptionIf[] | undefined,
  ) => {
    const filteredValue = tags?.filter(
      v => !(options ?? []).map(o => Number(o.id)).includes(v),
    );
    if (!selected) return;

    if (isArray(selected))
      return onChangeTyped(
        uniq([...(filteredValue ?? []), ...selected.map(s => Number(s))]),
      );

    return onChangeTyped(uniq([...(filteredValue ?? []), Number(selected)]));
  };

  const restCategories = RECIPE_TAG_CATEGORIES_TYPE.filter(
    c =>
      ![
        TagCategoryType.MEAL,
        TagCategoryType.PREPARATION_TIME,
        TagCategoryType.DIFFICULTY_OF_PREPARATION,
      ].includes(c),
  );

  const restOptions = restCategories
    .flatMap(c => getTagCategoryOptions(c))
    .filter((v): v is OptionIf => !!v);

  return (
    <>
      <OuterLabel label={t("diet.recipe_sidepanel.tags_tab")}>
        <ExpandableTagAutocomplete
          onChange={values => handleChangeSimplified(values, restOptions)}
          selectedTags={restOptions
            .filter(o => tags?.includes(Number(o.id)))
            .map(t => t.id)}
          tagCategories={restCategories}
          defaultExpanded
          searchFilter
          readOnlyCategories={[
            TagCategoryType.ALLERGENS,
            TagCategoryType.BASE_PRODUCT,
          ]}
        />
      </OuterLabel>
    </>
  );
};
