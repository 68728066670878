import { TabContext } from "@mui/lab";
import { MouseEvent, useMemo, useState } from "react";
import {
  CustomTabPanel,
  CustomToggleButton,
  CustomToggleButtonGroup,
} from "@views/dietician/PatientMonitoring2/components/TabPanel.styled";
import AverageNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/AverageNutrientsValues";
import SumNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/SumNutrientsValues";
import { useFetchDictionaryNutrients } from "@hooks/queries/dictionaries";
import { Box } from "@mui/material";
import { useAppTranslation } from "@hooks";
import BalanceNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/BalanceNutrientsValues";
import { Type } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import {
  ProgramDayChip,
  ProgramDayName,
} from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSection.styled";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";

type NutrientsValuesSectionProps = {
  data: NutrientsMonitoringResource[];
  type: Type;
};

const NutrientsValuesSection = ({
  data,
  type,
}: NutrientsValuesSectionProps) => {
  const [tab, setTab] = useState("1");
  const { t } = useAppTranslation();

  const { data: nutrients } = useFetchDictionaryNutrients();

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    newValue && setTab(newValue);
  };

  const programDay = useMemo(() => {
    if (type !== "day") {
      return "";
    }

    return data[0]?.programDay?.name ?? "";
  }, [data, type]);

  return (
    <TabContext value={tab}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <CustomToggleButtonGroup value={tab} exclusive onChange={handleChange}>
          <CustomToggleButton value="1">
            {t("monitoring2.nutritional_box.values_tabs.average")}
          </CustomToggleButton>
          <CustomToggleButton value="2">
            {t("monitoring2.nutritional_box.values_tabs.sum")}
          </CustomToggleButton>
          <CustomToggleButton value="3">
            {t("monitoring2.nutritional_box.values_tabs.energy_balance")}
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {type === "day" && programDay && (
          <ProgramDayChip
            variant="outlined"
            label={<ProgramDay color="#5252E5" name={programDay} />}
          />
        )}
      </Box>
      <CustomTabPanel value="1">
        <AverageNutrientsValues data={data} nutrients={nutrients ?? []} />
      </CustomTabPanel>
      <CustomTabPanel value="2">
        <SumNutrientsValues data={data} nutrients={nutrients ?? []} />
      </CustomTabPanel>
      <CustomTabPanel value="3">
        <BalanceNutrientsValues data={data} nutrients={nutrients ?? []} />
      </CustomTabPanel>
    </TabContext>
  );
};

type ProgramDayProps = {
  name: string;
  color: string;
};
const ProgramDay = ({ name, color }: ProgramDayProps) => {
  return (
    <Box display="flex" flexDirection="row" gap="4px" alignItems="center">
      <Box
        width="8px"
        height="8px"
        borderRadius="16px"
        sx={{ background: color }}
      />
      <ProgramDayName>{name}</ProgramDayName>
    </Box>
  );
};

export default NutrientsValuesSection;
