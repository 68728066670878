import { NutrientsSection } from "@components/PreviewDrawer/common";
import { Nutrients } from "@client/dictionaries";
import { useMemo } from "react";
import { getSumNutrientValue } from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValuesUtil";
import Macros from "@components/EnergyDecomposition/Macros";
import { CARBS_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import NutrientValue from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/NutrientValue";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import PatientNutrientsTabs from "@components/Nutrients2/PatientNutrientsTabs";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";

type BalanceNutrientsProps = {
  data: NutrientsMonitoringResource[];
  nutrients: Nutrients[];
};

const BalanceNutrientsValues = ({ data, nutrients }: BalanceNutrientsProps) => {
  const { patientId } = useMonitoring();
  const dataNutrients = useMemo(() => {
    return nutrients.map(nutrient => ({
      id: nutrient.id,
      value:
        getSumNutrientValue(data, nutrient.id, "patientNutrients") -
        getSumNutrientValue(data, nutrient.id, "dietitianNutrients"),
    }));
  }, [data, nutrients]);

  const mapNutrient = (id: number) => {
    const nutrient = dataNutrients.find(i => i.id === id);
    return {
      value: nutrient ? nutrient.value : 0,
    };
  };

  return (
    <NutrientValue
      macro={
        <Macros
          protein={mapNutrient(PROTEIN_ID)}
          fat={mapNutrient(FATS_ID)}
          carb={mapNutrient(CARBS_ID)}
        />
      }
      nutrients={
        <PatientNutrientsTabs patientId={patientId} values={dataNutrients} />
      }
    />
  );
};

export default BalanceNutrientsValues;
