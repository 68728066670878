import { Box, Dialog } from "@mui/material";
import {
  CancelButton,
  CloseButton,
  ModalWrapper,
  StyledDialogActions,
  StyledLoadingButton,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  Title,
  TitleWrapper,
} from "./BuyPlanModal.styled";
import { Close } from "@assets/icons";
import { TabContext } from "@mui/lab";
import { useAppTranslation, useModal } from "@hooks";
import { ThemeProviderWrapperNew } from "themeNew";
import { SyntheticEvent, useEffect, useState } from "react";
import imgPl from "@assets/img/ChoosePlanPl.png";
import imgEn from "@assets/img/ChoosePlanEn.png";
import { SubscriptionTabView } from "./components/SubscriptionTabView";
import { SubscriptionType } from "@client";
import { usePostSubscriptionPurchase } from "@hooks/queries";
import { SubscriptionTrialModalWrapper } from "@components/ConfirmSubscriptionModals";
import img from "@assets/img/TabletWithCheckedRound.png";
import { SubscriptionPackage } from "@utils";

interface BuyPlanModalProps {
  open: boolean;
  onClose: () => void;
  endsIn?: number | null;
  isTrial?: boolean;
}

export const BuyPlanModal = ({
  onClose,
  open,
  endsIn,
  isTrial,
}: BuyPlanModalProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { subscriptionPurchaseMutate, isLoading } =
    usePostSubscriptionPurchase();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const OneDayOrMore = endsIn && endsIn >= 1;
  const [continueTrial, setContinueTrial] = useState(OneDayOrMore);
  const [flexValue, setFlexValue] = useState<number>(10);
  const [type, setType] = useState("");
  enum Tabs {
    MONTHLY = "month",
    ANNUAL = "year",
  }
  const [tab, setTab] = useState(Tabs.MONTHLY);
  const extendTitle = isTrial
    ? t("subscription.trial.confirm_extend.title")
    : t("subscription.confirm_extend");

  const title = continueTrial
    ? t("subscription.trial.confirmation.title")
    : extendTitle;
  const content = continueTrial
    ? t("subscription.trial.confirmation.content")
    : t("subscription.trial.confirm_extend.content");

  const onSubmit = () => {
    subscriptionPurchaseMutate(
      {
        paymentType: tab,
        subscriptionType:
          type === SubscriptionPackage.UNLIMITED
            ? SubscriptionPackage.UNLIMITED
            : (`flex_${flexValue}` as SubscriptionType),
      },
      {
        onSuccess: () => {
          onModalOpen();
          onClose();
        },
      },
    );
  };

  const handleChange = (event: SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  useEffect(() => {
    open && setTab(Tabs.MONTHLY);
  }, [open]);

  return (
    <>
      <ThemeProviderWrapperNew>
        <Dialog open={open} disableEscapeKeyDown>
          <ModalWrapper>
            <TitleWrapper>
              <Title>{t("subscription.trial.step_two.title")}</Title>
              {endsIn !== null && (
                <CloseButton size="small" onClick={onClose}>
                  <Close size="w-3 h-3" />
                </CloseButton>
              )}
            </TitleWrapper>
            <div className="grid gap-[24px]">
              <div className="grid gap-[16px]">
                {(endsIn || endsIn === 0) && (
                  <p>{t("subscription.trial.step_two.content_one")}</p>
                )}
                <p>{t("subscription.trial.step_two.content_two")}</p>
              </div>

              <div className="flex justify-center">
                <img src={isPolishChosen ? imgPl : imgEn} />
              </div>
            </div>

            <TabContext value={tab}>
              <Box className="mb-2">
                <StyledTabList onChange={handleChange}>
                  <StyledTab
                    label={t("subscription.trial.step_two.monthly")}
                    value={Tabs.MONTHLY}
                  />
                  <StyledTab
                    label={t("subscription.trial.step_two.annually")}
                    value={Tabs.ANNUAL}
                  />
                </StyledTabList>
              </Box>
              <StyledTabPanel value={Tabs.MONTHLY}>
                <SubscriptionTabView
                  paymentType={tab}
                  setFlexValue={setFlexValue}
                  setType={setType}
                />
              </StyledTabPanel>
              <StyledTabPanel value={Tabs.ANNUAL}>
                <SubscriptionTabView
                  paymentType={tab}
                  setFlexValue={setFlexValue}
                  setType={setType}
                />
              </StyledTabPanel>
            </TabContext>
          </ModalWrapper>
          <StyledDialogActions>
            {endsIn !== null && (
              <CancelButton variant="outlined" onClick={onClose}>
                {t("common.cancel")}
              </CancelButton>
            )}
            <StyledLoadingButton
              variant="contained"
              loading={isLoading}
              onClick={onSubmit}
            >
              {t("subscription.trial.step_two.place_order")}
            </StyledLoadingButton>
          </StyledDialogActions>
        </Dialog>
      </ThemeProviderWrapperNew>
      <SubscriptionTrialModalWrapper
        title={title}
        content={content}
        width={180}
        height={163}
        open={modalOpened}
        onClose={onModalClose}
        img={img}
        onClick={onModalClose}
        buttonText={t("common.close")}
      />
    </>
  );
};
