import { styled } from "@mui/material";

interface ContainerProps {
  height?: number;
}
export const Container = styled("div")(({ height = 8 }: ContainerProps) => ({
  width: "100%",
  display: "flex",
  borderRadius: `${height * 2}px`,
  height: `${height}px`,
}));

interface TargetValueBarProps {
  width: number;
  color: string;
  height?: number;
}
export const TargetValueBar = styled("div")(
  ({ width, color, height = 8 }: TargetValueBarProps) => ({
    width: `${width}%`,
    background: color,
    borderRadius: `${height * 2}px`,
    height: `${height}px`,
  }),
);

interface ValueBarProps {
  width: number;
  color: string;
  height?: number;
}
export const ValueBar = styled("div")(
  ({ width, color, height = 8 }: ValueBarProps) => ({
    width: `${width}%`,
    background: color,
    borderRadius: `${height * 2}px`,
    height: `${height}px`,
  }),
);
