import { useAppTranslation } from "@hooks";

import { ProductIf } from "../NutrientsTab/ProductsTable";
import {
  DividerStyled,
  PreparationText,
  Title,
  TitleWrapper,
} from "./PreparationTab.styled";
import { ShoppingListElement } from "./ShoppingListElement";

interface PreparationTabProps {
  products: ProductIf[];
  preparation?: string | null;
}
export const PreparationTab = ({
  products,
  preparation,
}: PreparationTabProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="grid gap-[16px]">
      <TitleWrapper>
        <Title>{t("common.shopping_list")}</Title>
      </TitleWrapper>
      <div className="grid gap-[8px]">
        {products.map(p => (
          <ShoppingListElement key={p.id} {...p} />
        ))}
      </div>
      <DividerStyled />
      <Title>{t("recipe.preparation_instructions")}</Title>
      <PreparationText>{preparation}</PreparationText>
    </div>
  );
};
