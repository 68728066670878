import { styled } from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const KcalContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const KcalLabel = styled("span")`
  color: #333333;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

export const KcalTargetValue = styled("span")`
  color: #4d516e;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
`;

export const KcalValue = styled("span")`
  color: #333333;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;

export const EnergyDecompositionBarContainer = styled("div")`
  margin-top: 10px;
`;

export const MacrosContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const MacroContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MacroLabelContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Circle = styled("div")`
  background: #ffc66d;
  width: 12px;
  height: 12px;
  border-radius: 20px;
`;
export const MacroLabel = styled("span")`
  color: #333333;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 4px;
`;

export const MacroTargetValue = styled("span")`
  color: #4d516e;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
`;

export const MacroValue = styled("span")`
  color: #333333;
  font-family: Figtree, serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
`;
