import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updateCreatorMealRecipe,
  UpdateCreatorMealRecipeRequest,
} from "@client/meals/updateCreatorMealRecipe";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";
import { FetchMealResponse } from "@client/meals";
import {
  fetchCreatorRecipeQueryKey,
  useFetchCreatorRecipeQuery,
} from "./useFetchCreatorRecipeQuery";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useUpdateCreatorMealRecipeMutation = (
  mealId: string,
  wrapperId: string,
  options?: MutationOptions,
) => {
  const { recipe, refetch } = useFetchCreatorRecipeQuery(mealId, wrapperId, {
    enabled: false,
  });
  const queryClient = useQueryClient();
  const version = useMealVersion();

  const mutation = useMutation<
    unknown,
    unknown,
    UpdateCreatorMealRecipeRequest
  >(payload => updateCreatorMealRecipe(mealId, wrapperId, payload, version), {
    ...options,
    onSuccess: async (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
      queryClient.invalidateQueries([
        fetchCreatorMealNutrientsQueryKey,
        mealId,
      ]);
      await refetch();
      await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
      queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
        [fetchCreatorMealQueryKey, mealId],
        old => {
          if (!old) return;
          return {
            ...old,
            data: {
              ...old.data,
              recipes: old.data.recipes.map(r => {
                if (r.id.toString() !== wrapperId) return r;
                else return recipe ?? r;
              }),
            },
          };
        },
      );
    },
    onMutate: async () => {
      queryClient.invalidateQueries([
        fetchCreatorRecipeQueryKey,
        mealId,
        wrapperId,
      ]);
      await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
      queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
        [fetchCreatorMealQueryKey, mealId],
        old => {
          if (!old) return;
          return {
            ...old,
            data: {
              ...old.data,
              version: old.data.version + 1,
            },
          };
        },
      );
    },
    onError: () => {
      queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
    },
  });

  return mutation;
};
