import { Link } from "react-router-dom";

import overlayMobileRight from "@assets/img/roadShowBanner/overlayMobileRight.png";
import overlayMobileLeft from "@assets/img/roadShowBanner/clientContext/overlayMobileLeft.png";
import { CountdownTimer } from "@components/CountdownTimer";
import { useAppTranslation } from "@hooks";
import { ROADSHOW_DATE, ROADSHOW_LINK, ROADSHOW_TITLE } from "./RoadShowCard";
import { GradientText, Text } from "./RoadShowCard.styled";
import {
  LaunchBannerSmallWrapper,
  LaunchBannerTitle,
  OverlayImg,
  OverlayWrapper,
  StyledButton,
} from "./RoadShowCardMobile.styled";

export const RoadShowCardMobile = () => {
  const { t } = useAppTranslation();
  return (
    <LaunchBannerSmallWrapper>
      <div className="flex flex-col h-full relative">
        <LaunchBannerTitle>{ROADSHOW_TITLE}</LaunchBannerTitle>
        <Link to={ROADSHOW_LINK} target="_blank" rel="noopener noreferrer">
          <StyledButton variant="contained">
            {t("common.show_more")}
          </StyledButton>
        </Link>
      </div>
      <OverlayWrapper>
        <OverlayImg src={overlayMobileLeft} />
        <OverlayImg src={overlayMobileRight} />
      </OverlayWrapper>

      <div className="grid gap-[12px] place-items-center">
        <Text>
          A kolejna odsłona <GradientText>już za:</GradientText>
        </Text>
        <CountdownTimer targetDate={ROADSHOW_DATE} />
      </div>
    </LaunchBannerSmallWrapper>
  );
};
