import i18next from "i18next";
import dayjs from "dayjs";
import { Button } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useLogoutMutation } from "@hooks/queries";
import { getSubscription } from "./subscriptionUtils";

import {
  PurpleStarStyled,
  SpanStyled,
  StyledMuiButton,
  TrialBanner,
  TrialBannerContent,
  TrialExpiredBanner,
  UnlimitedGrowthBanner,
} from "../Dashboard.styled";
import { ThemeProviderWrapperNew } from "themeNew";
import { getSubscriptionInfo } from "@utils/subscription";

export const TrialCountdownBanner = () => {
  const { account } = useFetchDietitianAccountQuery();
  const { logout } = useLogoutMutation();
  const { t } = useAppTranslation();

  dayjs.locale(i18next.language);

  const {
    isIndefinite,
    trialDaysLeft,
    isGrowth,
    isStarter,
    isGrowth10,
    isGrowth30,
    isGrowth60,
    isStudent,
    isEnterprise,
  } = getSubscription(account?.clinic?.subscription);
  const { hasSubscription, isTrial, isUnlimitedGrowth } = getSubscriptionInfo();

  const isSomeGrowth = isGrowth || isGrowth10 || isGrowth30 || isGrowth60;

  const twoWeeksInDays = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const shouldOpenTrial =
    hasSubscription !== null &&
    twoWeeksInDays.includes(hasSubscription) &&
    isTrial;

  if (!!hasSubscription && isIndefinite) return <></>;

  if (shouldOpenTrial)
    return (
      <ThemeProviderWrapperNew>
        <TrialBanner warning={hasSubscription <= 3}>
          <TrialBannerContent>
            {t("common.trial_duration")}: {hasSubscription}{" "}
            {t("common.days", { count: hasSubscription })}
          </TrialBannerContent>
        </TrialBanner>
      </ThemeProviderWrapperNew>
    );

  if (!!hasSubscription && isUnlimitedGrowth)
    return (
      <UnlimitedGrowthBanner>
        <PurpleStarStyled />
        <SpanStyled bold>Unlimited Growth</SpanStyled>
        <SpanStyled>{t("common.until")}:</SpanStyled>
        <SpanStyled>
          {dayjs(account?.clinic?.subscription?.finishedAt).format("L")}
        </SpanStyled>
      </UnlimitedGrowthBanner>
    );

  if (!!hasSubscription && isSomeGrowth)
    return (
      <UnlimitedGrowthBanner>
        <PurpleStarStyled />
        <SpanStyled bold>Growth</SpanStyled>
      </UnlimitedGrowthBanner>
    );

  if (!!hasSubscription && (isStarter || isStudent || isEnterprise))
    return <></>;

  return (
    <>
      {isTrial && hasSubscription === null && (
        <TrialExpiredBanner>
          <p>{t("common.trial_has_expired")}</p>
          <a href="mailto:hello@alloweat.com">
            <StyledMuiButton variant="outlined">
              {t("common.contact_us")}
            </StyledMuiButton>
          </a>
        </TrialExpiredBanner>
      )}

      {isUnlimitedGrowth && hasSubscription === null && (
        <UnlimitedGrowthBanner>
          <PurpleStarStyled />
          <SpanStyled bold>Unlimited Growth</SpanStyled>
          <SpanStyled>{t("common.until")}:</SpanStyled>
          <SpanStyled>{t("common.ended")}</SpanStyled>
          <a href="mailto:hello@alloweat.com" className="ml-2">
            <Button variant="outlined" color="primary">
              {t("common.contact_us")}
            </Button>
          </a>
        </UnlimitedGrowthBanner>
      )}

      {isSomeGrowth && (
        <UnlimitedGrowthBanner>
          <PurpleStarStyled />
          <SpanStyled bold>Growth</SpanStyled>
          <SpanStyled>{t("common.until")}:</SpanStyled>
          <SpanStyled>{t("common.ended")}</SpanStyled>
          <a href="mailto:hello@alloweat.com" className="ml-2">
            <Button variant="outlined" color="primary">
              {t("common.contact_us")}
            </Button>
          </a>
        </UnlimitedGrowthBanner>
      )}
    </>
  );
};
