import { LanguagesSlugs } from "@typeDefinitions";
import * as yup from "yup";
import {
  NutrientValueResource,
  nutrientValueSchema,
} from "@client/resources/NutrientValueResource";

export interface PatientMealsMonitoringResource {
  id: number;
  date: string;
  comment: string | null;
  meals: Meal[];
}

interface Meal {
  id: number;
  name: string;
  time: string | null;
  comment: string | null;
  items: MealItem[];
  tags: Tag[];
  isCustom: boolean;
  isEdited: boolean;
  isEaten: boolean;
}

interface Item {
  id: number;
  eaten: boolean;
  rating: number | null;
}

interface Translation {
  lang: LanguagesSlugs;
  name: string | null;
}

interface Recipe {
  id: number;
  servings: number;
  recipe: {
    id: number;
    name: string;
    translations: Translation[];
    image: string | null;
    nutrients: NutrientValueResource[];
  };
}
export interface ItemRecipe extends Item {
  type: "food_diary_meal_item_recipe";
  item: Recipe;
}

interface Product {
  id: number;
  grams: number;
  measure: {
    id: number;
  };
  product: {
    id: number;
    name: string;
    translations: Translation[];
    nutrients: NutrientValueResource[];
  };
}

export interface ItemProduct extends Item {
  type: "food_diary_meal_item_product";
  item: Product;
}

export type MealItem = ItemRecipe | ItemProduct;

interface Tag {
  id: number;
}

const itemSchema = yup.object({
  id: yup.number().required(),
  eaten: yup.boolean().required(),
  rating: yup.number().required().nullable(),
});

const recipeItemSchema = yup
  .object({
    type: yup.mixed<"food_diary_meal_item_recipe">().required(),
    item: yup
      .object({
        id: yup.number().required(),
        servings: yup.number().required(),
        recipe: yup
          .object({
            id: yup.number().required(),
            name: yup.string().required(),
            translations: yup
              .array()
              .of(
                yup.object({
                  lang: yup
                    .mixed<LanguagesSlugs>()
                    .oneOf(
                      Object.values(LanguagesSlugs).map(
                        e => e as LanguagesSlugs,
                      ),
                    )
                    .required(),
                  name: yup.string().required().nullable(),
                }),
              )
              .required(),
            image: yup.string().required().nullable(),
            nutrients: yup.array().of(nutrientValueSchema).required(),
          })
          .required(),
      })
      .required(),
  })
  .concat(itemSchema);

const productItemSchema = yup
  .object({
    type: yup.mixed<"food_diary_meal_item_product">().required(),
    item: yup
      .object({
        id: yup.number().required(),
        grams: yup.number().required(),
        measure: yup
          .object({
            id: yup.number().required(),
          })
          .required(),
        product: yup
          .object({
            id: yup.number().required(),
            name: yup.string().required(),
            translations: yup
              .array()
              .of(
                yup.object({
                  lang: yup
                    .mixed<LanguagesSlugs>()
                    .oneOf(
                      Object.values(LanguagesSlugs).map(
                        e => e as LanguagesSlugs,
                      ),
                    )
                    .required(),
                  name: yup.string().required().nullable(),
                }),
              )
              .required(),
            nutrients: yup.array().of(nutrientValueSchema).required(),
          })
          .required(),
      })
      .required(),
  })
  .concat(itemSchema);

const mealSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  time: yup.string().required().nullable(),
  comment: yup.string().required().nullable(),
  items: yup
    .array()
    .of(
      yup.lazy(item => {
        if (item.type === "food_diary_meal_item_recipe")
          return recipeItemSchema;
        if (item.type === "food_diary_meal_item_product")
          return productItemSchema;
        throw new Error("Not implemented");
      }),
    )
    .required(),
  tags: yup
    .array()
    .of(yup.object({ id: yup.number().required() }))
    .required(),
  isCustom: yup.boolean().required(),
  isEdited: yup.boolean().required(),
  isEaten: yup.boolean().required(),
});

export const patientMealsMonitoringSchema = yup.object({
  id: yup.number().required(),
  date: yup.string().required(),
  comment: yup.string().required().nullable(),
  meals: yup.array().of(mealSchema).required(),
});
