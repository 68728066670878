import { useAppParams, useAppTranslation } from "@hooks";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import CancelIcon from "@assets/icons_new/cancel.svg?react";
import PhoneIcon from "@assets/icons_new/phone.svg?react";
import InfoIcon from "@assets/icons_new/iInCircle.svg?react";
import { LoadingButtonStyled } from "./ShowInAppButton.styled";
import { useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { fetchPatientProgramQueryKey } from "@hooks/queries";

interface ShowInAppButtonProps {
  shared: boolean;
}
export const ShowInAppButton = ({ shared }: ShowInAppButtonProps) => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const { patientId, programId } = useAppParams();
  const { colors } = useTheme();
  const { mutate, isLoading } = useChangeSharedProgramMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        fetchPatientProgramQueryKey,
        patientId,
        programId,
      ]);
    },
  });

  const handleToggle = () => {
    mutate({
      patientId: Number(patientId),
      programId: Number(programId),
      payload: { shared: !shared },
    });
  };

  if (shared)
    return (
      <LoadingButtonStyled loading={isLoading} onClick={handleToggle}>
        <CancelIcon />
        {t("program_list.hide_in_app")}
        <InfoIcon color={colors.neutral.dark[700]} />
      </LoadingButtonStyled>
    );

  return (
    <LoadingButtonStyled loading={isLoading} onClick={handleToggle}>
      <PhoneIcon />
      {t("program_list.show_in_app")}
      <InfoIcon color={colors.neutral.dark[700]} />
    </LoadingButtonStyled>
  );
};
