export type PaymentType = "month" | "year";

export enum SubscriptionPackage {
  FLEX_10 = "flex_10",
  FLEX_25 = "flex_25",
  FLEX_40 = "flex_40",
  FLEX_60 = "flex_60",
  UNLIMITED = "unlimited_growth",
}

export enum FLEX_VALUES {
  TEN = 10,
  TWENTY_FIVE = 25,
  FORTY = 40,
  SIXTY = 60,
}

export enum FLEX_PRICES_MONTH {
  TEN = 169,
  TWENTY_FIVE = 239,
  FORTY = 299,
  SIXTY = 399,
}

export enum FLEX_PRICES_YEAR {
  TEN = 139,
  TWENTY_FIVE = 199,
  FORTY = 249,
  SIXTY = 333,
}

export const UNLIMITED_YEAR = 290;
export const UNLIMITED_MONTH = 399;
