import { FIRST_RENDER_PARAM } from "@utils";

/** public routes */
export const AUTH = "/auth";
export const PUBLIC_VISIT = "/visit";
export const QUESTIONNAIRE = "/questionnaire";

export const LOGIN = `${AUTH}/login`;
export const ACTIVATION = `${AUTH}/activation`;
export const LINK_RESEND = `${AUTH}/resend`;
export const RESET = `${AUTH}/reset`;
export const PIN = `${AUTH}/pin`;
export const RETRIEVE = `${AUTH}/retrieve`;

export const CHAT_MAIN = "/chat";
export const CHAT = `${CHAT_MAIN}/:clientId`;

export const REGISTER = `${AUTH}/secret_registration_page`;
export const REGISTER_CLINIC = `${AUTH}/registration`;
export const REGISTER_PATIENT = `${AUTH}/register/patient`;

export const VISIT_CONFIRM = `${PUBLIC_VISIT}/confirm`;
export const VISIT_CANCEL = `${PUBLIC_VISIT}/cancel`;

/** dietician-dashboard routes */
export const DASHBOARD = "/";

export const CALENDAR = `${DASHBOARD}calendar`;

export const SETTINGS = `${DASHBOARD}settings`;
export const SETTINGS_PASSWORD = `${SETTINGS}/password`;

export const DIETICIAN_SETTINGS = `${DASHBOARD}dietician-settings`;

export const PATIENTS = `${DASHBOARD}patients`;
export const PATIENTS_FIRST_RENDER = `${DASHBOARD}patients${FIRST_RENDER_PARAM}`;

export const PATIENT = `${PATIENTS}/:patientId`;
export const PATIENT_ENERGY_BALANCE = `${PATIENT}/balance/*`;

// --- new client tabs routes ---
export const CLIENT = `${PATIENTS}/:patientId`;
export const HEALTH_AND_GOALS_VIEW = `${CLIENT}/health-and-goals`;
export const INFO_AND_SERVICES_VIEW = `${CLIENT}/info-and-services`;
export const APPOINTMENTS_VIEW = `${CLIENT}/appointments`;
export const ENERGY_BALANCE_VIEW = `${CLIENT}/energy-balance/*`;
export const NUTRITIONAL_PROGRAMS_VIEW = `${CLIENT}/nutritional-programs`;
export const CLIENT_PROGRAMS_NEW = `${CLIENT}/programs`;
export const PROGRESS_TRACKING_VIEW = `${CLIENT}/progress-tracking`;
export const QUESTIONNAIRE_ANSWERS = `${CLIENT}/questionnaire/:questionnaireId`;
export const PATIENT_MONITORING = `${CLIENT}/monitoring`;
export const PATIENT_CHOICE_PROGRAM = `${CLIENT}/programs/choice`;
//

export const PATIENT_NEW_PROGRAM = `${CLIENT}/nutritional-programs/new`;
export const PATIENT_PROGRAM = `${CLIENT}/nutritional-programs/:programId`;
export const PATIENT_PROGRAM_EDIT = `${PATIENT_PROGRAM}/edit`;
export const PATIENT_PROGRAM_DAYS = `${PATIENT_PROGRAM}/days`;
export const PATIENT_PROGRAM_DAYS_ALL = `${PATIENT_PROGRAM}/days/*`;
export const PATIENT_PROGRAM_DAY = `${PATIENT_PROGRAM_DAYS}/:dayId`;
export const PATIENT_PROGRAM_DAY_DIET = `${PATIENT_PROGRAM_DAY}/diet`;
export const PATIENT_PROGRAM_DAY_DIET_EDIT = `${PATIENT_PROGRAM_DAY}/diet/:dietId/edit`;
export const PATIENT_PROGRAM_DAY_DIET_NEW = `${PATIENT_PROGRAM_DAY_DIET}/new`;
export const PATIENT_PROGRAM_SCHEDULE = `${PATIENT_PROGRAM}/schedule`;
export const PATIENT_PROGRAM_SCHEDULE_RECIPE_EDIT = `${PATIENT_PROGRAM_SCHEDULE}/day/:dayId/meal/:mealId/edit`;

export const PATIENT_PROGRAM_DAY_DIET_EDIT_SETTINGS = `${PATIENT_PROGRAM_DAY_DIET_EDIT}/settings`;
export const PATIENT_PROGRAM_DAY_DIET_EDIT_RECIPES = `${PATIENT_PROGRAM_DAY_DIET_EDIT}/recipes`;
export const PATIENT_PROGRAM_DAY_DIET_EDIT_PRODUCTS = `${PATIENT_PROGRAM_DAY_DIET_EDIT}/products`;
export const PATIENT_PROGRAM_DAY_DIET_EDIT_TAGS = `${PATIENT_PROGRAM_DAY_DIET_EDIT}/tags`;

export const PRODUCTS = `${DASHBOARD}products`;
export const PRODUCT = `${PRODUCTS}/:productId`;
export const NEW_PRODUCT = `${PRODUCTS}/new`;
export const EDIT_PRODUCT = `${PRODUCT}/edit`;
export const CLONE_PRODUCT = `${PRODUCT}/copy`;

export const RECIPES = `${DASHBOARD}recipes`;
export const RECIPE = `${RECIPES}/:recipeId`;
export const NEW_RECIPE = `${RECIPES}/new`;
export const EDIT_RECIPE = `${RECIPE}/edit`;
export const COPY_RECIPE = `${RECIPE}/copy`;

export const MEAL = `${DASHBOARD}meals/:mealId`;
export const MEALS = `${DASHBOARD}meals`;
export const MEAL_NEW = `${MEALS}/new`;
export const MEAL_EDIT = `${MEALS}/:mealId/edit`;
export const MEAL_COPY = `${MEALS}/:mealId/copy`;

export const DIETS = `${DASHBOARD}diets`;
export const DIET = `${DIETS}/:dietId`;
export const NEW_DIET = `${DIETS}/new`;
export const COPY_DIET = `${DIET}/copy`;
export const EDIT_DIET = `${DIET}/edit`;
export const EDIT_DIET_SETTINGS = `${EDIT_DIET}/settings`;
export const EDIT_DIET_RECIPES = `${EDIT_DIET}/recipes`;
export const EDIT_DIET_PRODUCTS = `${EDIT_DIET}/products`;
export const EDIT_DIET_TAGS = `${EDIT_DIET}/tags`;

export const PROGRAMS = `${DASHBOARD}programs`;
export const PROGRAMS2 = `${DASHBOARD}programs2`;
export const PROGRAM = `${PROGRAMS}/:programId`;
export const PROGRAM_EDIT = `${PROGRAM}/edit`;
export const PROGRAM_COPY = `${PROGRAM}/copy`;
export const PROGRAM_NEW = `${PROGRAMS}/new`;
export const PROGRAM_DAYS_ALL = `${PROGRAM}/days/*`;
export const PROGRAM_DAYS = `${PROGRAM}/days`;
export const PROGRAM_SCHEDULE = `${PROGRAM}/schedule`;
export const PROGRAM_SCHEDULE_RECIPE_EDIT = `${PROGRAM_SCHEDULE}/day/:dayId/meal/:mealId/edit`;

export const PROGRAM_DIET_NEW = `${PROGRAM_DAYS}/:dayId/diet/new`;
export const PROGRAM_DIET_EDIT = `${PROGRAM_DAYS}/:dayId/diet/:dietId/edit`;

export const PROGRAM_DIET_EDIT_SETTINGS = `${PROGRAM_DIET_EDIT}/settings`;
export const PROGRAM_DIET_EDIT_RECIPES = `${PROGRAM_DIET_EDIT}/recipes`;
export const PROGRAM_DIET_EDIT_PRODUCTS = `${PROGRAM_DIET_EDIT}/products`;
export const PROGRAM_DIET_EDIT_TAGS = `${PROGRAM_DIET_EDIT}/tags`;

export const SECTIONS = `${DASHBOARD}sections`;
export const SECTION = `${SECTIONS}/:sectionId`;
export const SECTION_NEW = `${SECTIONS}/new`;
export const SECTION_EDIT = `${SECTION}/edit`;
export const SECTION_COPY = `${SECTION}/copy`;
export const FACILITY = `${DASHBOARD}facility`;

export const QUESTIONNAIRES = `${DASHBOARD}questionnaires`;
export const QUESTIONNAIRE_EDIT = `${QUESTIONNAIRES}/:questionnaireId/edit`;

/** patient-dashboard routes */
export const PATIENT_DASHBOARD = `${DASHBOARD}patient`;
export const PATIENT_SETTINGS = `${PATIENT_DASHBOARD}/settings`;
export const PATIENT_SETTINGS_PASSWORD = `${PATIENT_SETTINGS}/password`;

export const INTEGRATIONS = `${DASHBOARD}integrations`;
export const GOOGLE_CALLBACK = `${DASHBOARD}oauth/google/callback`;

export const WORK_IN_PROGRESS = `${DASHBOARD}work-in-progress`;
