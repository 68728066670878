import React, { useContext, useMemo, useRef, useState } from "react";

import classnames from "classnames";
import { useQueryClient } from "@tanstack/react-query";
import { ClickAwayListener, MenuItem } from "@mui/material";
import dayjs from "dayjs";

import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { ScheduleClipboardContext } from "@views/dietician/ProgramSchedule/ScheduleClipboardContext";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
  useDeleteProgramDayMealRecipeMutation,
  useProgramDayMealFoodMutation,
  useProgramDayMealRecipeMutation,
  useProgramDaySwapMutation,
} from "@hooks/queries/schedule";
import { useCloneScheduleMealMutation } from "@hooks/queries";
import {
  DietitianProgramScheduleDay,
  DietitianProgramScheduleMeal,
} from "@client/schedule";
import { DropMenuMui } from "@components/DropMenuMui";
import { BulletMenu } from "@assets/icons";
import { Card } from "@components/Card";
import { DraggedRecipeData } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid";
import { RecipeColor } from "@typeDefinitions";
import { PopMenu } from "@components/PopMenu";

import { ScheduleGridRecipe } from "../ScheduleGridRecipe/ScheduleGridRecipe";
import { mealCopyMap, mealPasteMap } from "../../../../scheduleUtils";
import { AddIconStyled, AddIconWrapper } from "./ScheduleGridItem.styled";
import { dropEndOptimisticUpdate } from "./gridItemUtils";
import { Spinner } from "@components/Spinner";
import { RecipeChangeSidePanel } from "../RecipeChangeModal/RecipeChangeSidePanel";
import { ThemeProviderWrapperNew } from "themeNew";
import { ItemsListCallback } from "@views/dietician/DietCreator/components/RecipesTab/ItemsContext";
import { RecipePreviewDrawer } from "@views/dietician/DietCreator/components/RecipeDrawer/RecipePreviewDrawer/RecipePreviewDrawer";
import { ScheduleRecipeEditDrawer } from "@views/dietician/DietCreator/components/RecipeDrawer/RecipeEditDrawer";
import { AddElementSidePanelProxy } from "../AddElementSidePanelProxy/AddElementSidePanelProxy";

export interface ScheduleGridItemProps {
  dietId: number;
  dayId: number;
  programId: number;
  programDayId: number;
  dayDate: string;
  color: string;
  meal: DietitianProgramScheduleMeal;
  draggedRecipe?: DraggedRecipeData;
  recipeColor?: RecipeColor;
  readOnly?: boolean;
  setDraggedRecipe?: (draggedRecipe?: DraggedRecipeData) => void;
  usedRecipes?: UsedRecipe[];
  pastDate?: boolean;
  canCopy: boolean;
}

export const ScheduleGridItem = (props: ScheduleGridItemProps) => {
  const {
    dietId,
    dayId,
    programId,
    programDayId,
    meal,
    color,
    draggedRecipe,
    usedRecipes,
    setDraggedRecipe,
    recipeColor,
    readOnly,
    dayDate,
    pastDate,
    canCopy,
  } = props;

  const queryClient = useQueryClient();

  const { id: mealId, dietMeal, recipe, servings } = meal;

  const draggable = !!setDraggedRecipe;

  const { t, isPolishChosen } = useAppTranslation();
  const { patientId } = useAppParams();
  const [openAddItem, onOpenAddItem, onCloseAddItem] = useModalState();
  const [openReplaceItem, onOpenReplaceItem, onCloseReplaceItem] =
    useModalState();
  const [openRecipePreview, onOpenRecipePreview, onCloseRecipePreview] =
    useModalState();
  const [openEditRecipe, onOpenEditRecipe, onCloseEditRecipe] = useModalState();
  const [hover, setHover] = useState(false);
  const [isDraggedOver, setDraggedOver] = useState(false);

  const { meal: copiedMeal, copyMeal } = useContext(ScheduleClipboardContext);
  const cardClass = useMemo(() => `meal-card-${dietId}`, [dietId]);

  const { mutate: swapDayMeals } = useProgramDaySwapMutation(programId);

  const { mutate: removeDayMealRecipe, isLoading } =
    useDeleteProgramDayMealRecipeMutation(programId, dayId);
  const { mutate: pasteDayMeal } = useCloneScheduleMealMutation(programId);
  const { mutate: updateDayMealRecipe, isLoading: isLoadingDayMealRecipe } =
    useProgramDayMealRecipeMutation(programId, dayId);
  const { mutate: updateDayMealProduct, isLoading: isLoadingDayMealProduct } =
    useProgramDayMealFoodMutation(programId, dayId);
  const handleDragStart = () => {
    if (!recipe || !mealId) return;
    setDraggedRecipe && setDraggedRecipe({ dayId, dietId, mealId });
  };

  const handleDropEnter = () => {
    if (draggedRecipe?.dietId === dietId) {
      setDraggedOver(true);
      return;
    }

    if (!isDraggedOver) colorRecipes();
  };

  const handleDropLeave = () => {
    if (isDraggedOver) {
      setDraggedOver(false);
      return;
    }

    colorRecipes(true);
  };

  const handleDragEnd = (e: MouseEvent | PointerEvent | TouchEvent) => {
    if (!(e.target as HTMLElement)?.closest(`.${cardClass}`))
      setDraggedRecipe && setDraggedRecipe(undefined);
  };

  const handleRecipeRemoval = (e: React.MouseEvent<HTMLElement>) => {
    if (!mealId) return;
    removeDayMealRecipe(mealId);
    onCloseAddItem();
  };

  const handleRecipeEdit = () => {
    if (recipe) onOpenEditRecipe();
  };

  const handleDropEnd = () => {
    if (!mealId) return;
    if (isDraggedOver && draggedRecipe) {
      swapDayMeals({ mealsId: [mealId, draggedRecipe.mealId] });
      queryClient.setQueryData(
        [fetchDietitianProgramScheduleKey, programId],
        (input?: { data: DietitianProgramScheduleDay[] }) =>
          dropEndOptimisticUpdate(input, dayId, mealId, draggedRecipe),
      );
      queryClient.setQueryData(
        [
          fetchDietitianClientProgramScheduleKey,
          patientId,
          programId.toString(),
        ],
        (input?: { data: DietitianProgramScheduleDay[] }) =>
          dropEndOptimisticUpdate(input, dayId, mealId, draggedRecipe),
      );

      setDraggedOver(false);
    }
    setDraggedRecipe && setDraggedRecipe(undefined);
  };

  const colorRecipes = (clear = false) => {
    if (!recipe?.id) return;
    setHover(!clear);
  };

  const handleCopyMeal = () => {
    if (meal && recipe) copyMeal(mealCopyMap(mealId, recipe));
  };

  const handlePasteMeal = () => {
    if (copiedMeal) {
      pasteDayMeal(mealPasteMap(copiedMeal.id, mealId), {
        onSuccess: () => copyMeal(undefined),
      });
    }
  };

  const handleUpdateDayMeal: ItemsListCallback = values => {
    if (values.length < 1) return;

    const [firstItem] = values;

    if (!firstItem || !mealId) return;

    const { id, type } = firstItem;

    if (type === "recipe")
      return updateDayMealRecipe(
        {
          mealId,
          id,
          servings: firstItem.servings,
        },
        { onSuccess: onCloseReplaceItem },
      );

    updateDayMealProduct(
      {
        mealId,
        id,
        foodMeasureId: firstItem.measure,
        grams: firstItem.grams,
      },
      { onSuccess: onCloseReplaceItem },
    );
  };

  return (
    <>
      <Card
        headerSize="small"
        noContainerPaddings
        header={
          <>
            {dietMeal?.title?.length > 11 ? (
              <NameHover name={dietMeal?.title} />
            ) : (
              <span className="text-sm">{dietMeal?.title || ""}</span>
            )}
            <span className="text-sm opacity-50 ml-1">
              {dietMeal?.hour
                ? dietMeal.hour?.slice(0, 5)
                : t("common.arbitrarily")}
            </span>
          </>
        }
        headerClassName="justify-center"
        className={classnames(
          cardClass,
          "mx-1.5 h-full w-48 relative overflow-hidden",
          recipeColor ? `recipe-${recipeColor.index}` : undefined,
          !pastDate ? "cursor-pointer" : undefined,
        )}
        containerClassName="p-3 flex flex-col justify-between"
        action={
          !readOnly &&
          !pastDate && (
            <div>
              <DropMenuMui
                icon={
                  isLoading ? (
                    <Spinner size="h-5 w-5" />
                  ) : (
                    <BulletMenu className="step-four" />
                  )
                }
                size="small"
              >
                <MenuItem onClick={recipe ? onOpenAddItem : onOpenAddItem}>
                  {t(recipe ? "patient.schedule.change" : "common.add")}
                </MenuItem>
                {recipe && (
                  <MenuItem onClick={handleRecipeEdit}>
                    {t("recipe_edit.title")}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleCopyMeal}
                  disabled={!recipe || !canCopy}
                >
                  {t("patient.schedule.copy_recipe")}
                </MenuItem>
                {copiedMeal && (
                  <MenuItem
                    onClick={handlePasteMeal}
                    disabled={dayjs().isAfter(dayDate, "day")}
                  >
                    {t("patient.schedule.paste_recipe")}:{" "}
                    {isPolishChosen ? copiedMeal?.namePl : copiedMeal.nameEn}
                  </MenuItem>
                )}
                {recipe && (
                  <MenuItem onClick={handleRecipeRemoval}>
                    {t("common.delete")}
                  </MenuItem>
                )}
              </DropMenuMui>
            </div>
          )
        }
        initial={false}
        drag={!!recipe && draggable && !readOnly && !pastDate}
        dragSnapToOrigin
        dragMomentum={false}
        onDragStart={pastDate ? undefined : handleDragStart}
        onDragEnd={pastDate ? undefined : handleDragEnd}
        onMouseEnter={pastDate ? undefined : handleDropEnter}
        onMouseLeave={pastDate ? undefined : handleDropLeave}
        onMouseUp={pastDate ? undefined : handleDropEnd}
        whileDrag={{
          scale: 1.03,
          zIndex: 100,
          pointerEvents: "none",
          opacity: 0.6,
        }}
        style={
          isDraggedOver
            ? { outlineStyle: "solid", outlineColor: color, outlineWidth: 2 }
            : undefined
        }
        onClick={recipe ? onOpenRecipePreview : onOpenAddItem}
      >
        {hover && !!recipeColor && !pastDate && (
          <style>
            {`.recipe-${recipeColor.index} { 
					  box-shadow: 0px 0px 15px 0px ${
              recipeColor?.color !== "#FFFFFF"
                ? recipeColor?.color
                : recipeColor?.backgroundColor
            };
					  outline: 2px solid ${
              recipeColor?.color !== "#FFFFFF"
                ? recipeColor?.color
                : recipeColor?.backgroundColor
            };
				  }`}
          </style>
        )}
        {recipe ? (
          <ScheduleGridRecipe
            recipe={recipe}
            onChange={!readOnly && !pastDate ? onOpenRecipePreview : undefined}
            colors={recipeColor}
            usedServings={servings}
          />
        ) : (
          <AddIconWrapper
            onClick={
              !recipe && !readOnly && !pastDate ? onOpenAddItem : undefined
            }
          >
            <AddIconStyled />
          </AddIconWrapper>
        )}
      </Card>

      {openAddItem && (
        <RecipeChangeSidePanel
          mealId={mealId}
          dietMeal={dietMeal}
          usedRecipes={usedRecipes}
          dayId={dayId}
          programId={programId}
          programDayId={programDayId}
          dayDate={dayDate}
          onClose={onCloseAddItem}
          onOpenReplaceItemSidePanel={onOpenReplaceItem}
        />
      )}

      <ThemeProviderWrapperNew>
        {openReplaceItem && (
          <AddElementSidePanelProxy
            mealId={dietMeal.id}
            onClose={onCloseReplaceItem}
            mealName={dietMeal.title}
            dietId={dietId.toString()}
            onSubmit={handleUpdateDayMeal}
            loading={isLoadingDayMealRecipe || isLoadingDayMealProduct}
          />
        )}

        {openRecipePreview && (
          <ClickAwayListener onClickAway={onCloseRecipePreview}>
            <div>
              <RecipePreviewDrawer
                open={openRecipePreview}
                onClose={onCloseRecipePreview}
                onEdit={() => {
                  onCloseRecipePreview();
                  onOpenEditRecipe();
                }}
                recipeId={recipe?.id?.toString() ?? null}
              />
            </div>
          </ClickAwayListener>
        )}

        {mealId && (
          <ScheduleRecipeEditDrawer
            open={openEditRecipe}
            onClose={onCloseEditRecipe}
            mealId={mealId.toString()}
            dayId={dayId.toString()}
          />
        )}
      </ThemeProviderWrapperNew>
    </>
  );
};

const NameHover = (props: { name: string }) => {
  const [hover, setHover] = useState(false);

  const anchor = useRef<HTMLSpanElement>(null);

  const handleHover = () => setHover(s => !s);

  return (
    <>
      <span
        className="text-sm"
        ref={anchor}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {props.name.slice(0, 11) + "..."}
      </span>
      <PopMenu
        open={hover}
        anchor={anchor}
        placement="bottom"
        className="font-roman text-black"
        innerContainerClassName="py-1 px-3 w-max min-w-max bg-gray-100"
        showArrow={true}
        offset={15}
      >
        {props.name}
      </PopMenu>
    </>
  );
};

export interface UsedRecipe {
  title: string;
  quantity: number;
}
