import { useMemo } from "react";

import { ViewError } from "@components/ViewError";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { percentageFromNutrients } from "@components/MacrosSliders";
import { Food } from "@assets/icons/Menu";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchRecipeQuery } from "@hooks/queries";
import { GroupedNutrients } from "@components/GroupedNutrients";
import { MacrosBarChart } from "@components/MacrosBarChart";
import { RecipeCompositionSection } from "@components/RecipeCompositionSection";

import { FullSpinner, RecipeWrapper, StyledCard } from "./Recipe.styled";
import { RecipeDetails } from "./components/RecipeDetails";
import { RecipeDescription } from "./components/RecipeDescription";

export const Recipe = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { recipeId } = useAppParams();
  const { recipe, isLoading, isError } = useFetchRecipeQuery(Number(recipeId));

  const recipeName =
    (isPolishChosen ? recipe?.name : recipe?.nameEn ?? recipe?.name) ?? "";

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <Food />,
        backTo: "/recipes",
        title: t("recipes.title"),
      },
      {
        backTo: `/recipes/${recipe?.id}`,
        title: recipeName,
      },
    ],
    [recipe, recipeName],
  );

  const macrosPercentage = useMemo(
    () => percentageFromNutrients(recipe?.nutrients),
    [recipe?.nutrients],
  );

  if (isLoading) return <FullSpinner />;

  if (isError || !recipe) return <ViewError />;

  return (
    <RecipeWrapper>
      <BreadcrumbsPath breadcrumbs={breadcrumbs} />

      <StyledCard>
        <RecipeDetails
          name={recipeName}
          mediaUrl={recipe.media?.url}
          productsCount={recipe.foodRecipe.length}
          servings={recipe.servings}
          tags={recipe.tags.map(tag => tag.descriptionPl)}
          nutrients={recipe.nutrients}
          actions={recipe.actions}
        />
      </StyledCard>

      <StyledCard>
        <RecipeCompositionSection
          numOfPortions={recipe.servings}
          nutrients={recipe.nutrients}
          rows={recipe.foodRecipe}
        />
      </StyledCard>

      <StyledCard>
        <MacrosBarChart {...macrosPercentage} />
      </StyledCard>

      <StyledCard>
        <RecipeDescription
          descriptionPl={recipe.description}
          descriptionEn={recipe.descriptionEn}
        />
      </StyledCard>

      <StyledCard>
        <GroupedNutrients
          title={t("recipe.nutrients_title")}
          nutrients={recipe.nutrients}
        />
      </StyledCard>
    </RecipeWrapper>
  );
};
