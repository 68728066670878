import {
  Container,
  TargetValueBar,
  ValueBar,
} from "./EnergyDecompositionBar.styled";
import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";
import { useMemo } from "react";

interface NutrientProps {
  value: number;
  target?: number;
}

interface EnergyDecompositionBarProps {
  protein: NutrientProps;
  fat: NutrientProps;
  carb: NutrientProps;
}

export const EnergyDecompositionBar = ({
  protein,
  fat,
  carb,
}: EnergyDecompositionBarProps) => {
  const getTargetValue = (nutrient: NutrientProps) =>
    nutrient.target ? nutrient.target : nutrient.value;
  const getValue = (nutrient: NutrientProps) => {
    const value = getTargetValue(nutrient);
    return Math.max(value, 1);
  };

  const kcalMacro = useMemo(() => {
    return (
      getTargetValue(protein) * ENERGY_PER_GRAM_OF_PROTEIN +
      getTargetValue(fat) * ENERGY_PER_GRAM_OF_FAT +
      getTargetValue(carb) * ENERGY_PER_GRAM_OF_CARB
    );
  }, [protein, fat, carb]);

  const getTargetWidth = (nutrient: NutrientProps, macroKcal: number) =>
    ((getTargetValue(nutrient) * macroKcal) / Math.max(kcalMacro, 1)) * 100;
  const getValueWidth = (nutrient: NutrientProps) =>
    (nutrient.value / getValue(nutrient)) * 100;

  return (
    <Container>
      <TargetValueBar
        width={getTargetWidth(protein, ENERGY_PER_GRAM_OF_PROTEIN)}
        color="#FFFDE0"
      >
        <ValueBar
          width={Math.min(getValueWidth(protein), 100)}
          color="#FADA69"
        ></ValueBar>
      </TargetValueBar>
      <TargetValueBar
        width={getTargetWidth(fat, ENERGY_PER_GRAM_OF_FAT)}
        color="#FFF5E1"
      >
        <ValueBar
          width={Math.min(getValueWidth(fat), 100)}
          color="#FAB569"
        ></ValueBar>
      </TargetValueBar>
      <TargetValueBar
        width={getTargetWidth(carb, ENERGY_PER_GRAM_OF_CARB)}
        color="#E4F1F0"
      >
        <ValueBar
          width={Math.min(getValueWidth(carb), 100)}
          color="#3AB795"
        ></ValueBar>
      </TargetValueBar>
    </Container>
  );
};
