import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  DashboardEmptyStateWrapper,
  TilesListWrapper,
} from "./DashboardEmptyState.styled";
import image from "@assets/img/dashboardEmptyState.png";
import { useAppTranslation, useUserRoles } from "@hooks";
import { ButtonTile } from "./ButtonTile";
import { People } from "@assets/icons/DrawerMenu";
import { EmptyCalendar, Gear, Checklist } from "@assets/icons";
import { EmptyStateCard, StyledSubTitle, StyledTitle } from "../common.styled";
import type { FC } from "react";
import { Tasks } from "@views/dietician/Tasks";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useFetchClientsQuery } from "@hooks/queries";

interface DashboardEmptyStateProps {
  onAddClient: () => void;
  onAddVisit: () => void;
  onClickCompanyData: () => void;
}

export const DashboardEmptyState: FC<DashboardEmptyStateProps> = ({
  onAddClient,
  onAddVisit,
  onClickCompanyData,
}) => {
  const { t } = useAppTranslation();
  const { isClinicAdmin } = useUserRoles();
  const { handleOpenCreateModal } = useTasksContext();
  const { account } = useFetchDietitianAccountQuery();

  const { clients } = useFetchClientsQuery({
    enabled: !!account?.clinic?.id,
    keepPreviousData: true,
  });

  return (
    <ThemeProviderWrapperNew>
      <EmptyStateCard>
        {clients?.length ? (
          <Tasks />
        ) : (
          <DashboardEmptyStateWrapper>
            <ContentWrapper>
              <img src={image} alt={t("empty_states.alt.no_dashboard")} />
              <div className="grid gap-3">
                <StyledTitle>{t("empty_states.dashboard")}</StyledTitle>
                <StyledSubTitle maxWidth="40rem">
                  {t("empty_states.dashboard_info")}
                </StyledSubTitle>
              </div>
            </ContentWrapper>
            <TilesListWrapper>
              <ButtonTile
                icon={<People />}
                title={t("empty_states.client_list")}
                info={t("empty_states.client_list_info")}
                buttonText={t("empty_states.add_client")}
                onClick={onAddClient}
              />
              {isClinicAdmin && (
                <ButtonTile
                  icon={<Gear />}
                  title={t("empty_states.settings")}
                  info={t("empty_states.settings_info")}
                  buttonText={t("empty_states.fill_data")}
                  onClick={onClickCompanyData}
                />
              )}
              <ButtonTile
                icon={<EmptyCalendar />}
                title={t("empty_states.visits_calendar")}
                info={t("empty_states.visits_calendar_info")}
                buttonText={t("empty_states.add_visit")}
                onClick={onAddVisit}
              />
              <ButtonTile
                icon={<Checklist className="text-2xl" />}
                title={t("empty_states.tasks")}
                info={t("empty_states.tasks_info")}
                buttonText={t("empty_states.add_task")}
                onClick={handleOpenCreateModal}
              />
            </TilesListWrapper>
          </DashboardEmptyStateWrapper>
        )}
      </EmptyStateCard>
    </ThemeProviderWrapperNew>
  );
};
