import { FetchMealResponse } from "@client/meals";
import { useAppParams } from "@hooks";
import { fetchCreatorMealQueryKey } from "@hooks/queries";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const useMealVersion = () => {
  const queryClient = useQueryClient();
  const { mealId } = useAppParams();
  const meal = queryClient.getQueryData<ApiResponse<FetchMealResponse>>([
    fetchCreatorMealQueryKey,
    mealId,
  ]);
  const version = meal?.data.version ?? 1;

  return version;
};
