import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { PaymentType, SubscriptionPackage } from "@utils";

export type SubscriptionType =
  | SubscriptionPackage.FLEX_10
  | SubscriptionPackage.FLEX_25
  | SubscriptionPackage.FLEX_40
  | SubscriptionPackage.FLEX_60
  | SubscriptionPackage.UNLIMITED;

export const postSubscriptionPurchase = async (
  payload: postSubscriptionPurchaseRequest,
) => {
  return await fetchData(
    `/dietitian/clinic/subscription-purchase`,
    APIMethods.POST,
    payload,
  );
};

export interface postSubscriptionPurchaseRequest {
  subscriptionType: SubscriptionType;
  paymentType: PaymentType;
}
