import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { RecipeDto, recipeWrapperSchema } from "./";

export const fetchCreatorRecipe = async (
  mealId: string,
  recipeId: string,
  version: number,
): Promise<ApiResponse<FetchCreatorRecipeResponse>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/recipes/${recipeId}`,
    APIMethods.GET,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );

  return apiResponseSchema.validate(response);
};

const apiResponseSchema = yup.object().shape({
  data: recipeWrapperSchema.required(),
});

export interface FetchCreatorRecipeResponse {
  id: number;
  recipe: RecipeDto;
  servings: number;
}
