import { Outlet } from "react-router-dom";

import { ProgramEmptyDietProvider } from "@context";

import { NavigationHeader } from "./NavigationHeader";
import { useAppParams } from "@hooks";

export const PatientProgramLayout = () => {
  const { patientId } = useAppParams();
  const returnLink = `patients/${patientId}/programs`;

  return (
    <div className="grid gap-7">
      <NavigationHeader returnTo={returnLink} />

      <ProgramEmptyDietProvider>
        <Outlet />
      </ProgramEmptyDietProvider>
    </div>
  );
};
