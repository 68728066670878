import { useNavigate } from "react-router-dom";

import { ArrowLeft } from "@assets/icons/Arrows";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchClientQuery, useFetchProgramQuery } from "@hooks/queries";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  IconButtonStyled,
  SubTitleStyled,
  TitleStyled,
} from "./NavigationHeader.styled";

interface NavigationHeaderProps {
  returnTo: string;
}

export const NavigationHeader = ({ returnTo }: NavigationHeaderProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { patientId, programId } = useAppParams();
  const navigate = useNavigate();
  const { client } = useFetchClientQuery(Number(patientId));
  const { program } = useFetchProgramQuery(Number(programId), {
    enabled: !!programId,
  });

  const handleGoBack = () => navigate(returnTo);
  const fullName = client
    ? [client.firstName, client.lastName].join(" ")
    : undefined;
  return (
    <ThemeProviderWrapperNew>
      <div className="flex items-start gap-[4px]">
        <IconButtonStyled color="primary" size="small" onClick={handleGoBack}>
          <ArrowLeft size="w-[16px] h-[16px]" />
        </IconButtonStyled>

        <div className="grid flex-1">
          {program && (
            <TitleStyled>
              {isPolishChosen ? program.name : program.nameEn}
            </TitleStyled>
          )}
          {fullName && <SubTitleStyled>{fullName}</SubTitleStyled>}
        </div>
      </div>
    </ThemeProviderWrapperNew>
  );
};
