import { EnergyDecompositionBar } from "./EnergyDecompositionBar";
import {
  Container,
  EnergyDecompositionBarContainer,
  KcalContainer,
  KcalLabel,
  KcalTargetValue,
  KcalValue,
  MacrosContainer,
} from "./EnergyDecomposition.styled";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { getMacroKcal } from "@components/EnergyDecomposition/EnergyUtils";
import Macros from "@components/EnergyDecomposition/Macros";
import { Box } from "@mui/material";

interface NutrientProps {
  value: number;
  target?: number;
}

interface EnergyDecompositionProps {
  kcal: NutrientProps;
  protein: NutrientProps;
  fat: NutrientProps;
  carb: NutrientProps;
}

export const EnergyDecomposition = ({
  kcal,
  protein,
  fat,
  carb,
}: EnergyDecompositionProps) => {
  const { t } = useAppTranslation();
  const macroKcal = useMemo(
    () => getMacroKcal({ protein, fat, carb }),
    [protein, fat, carb],
  );

  return (
    <Container>
      <KcalContainer>
        <KcalLabel>{t("components.energy_decomposition.title")}</KcalLabel>
        <KcalTargetValue>
          <KcalValue>{Math.round(kcal.value)}</KcalValue>
          {kcal.target ? ` / ${Math.round(kcal.target)}` : ""} kcal
        </KcalTargetValue>
      </KcalContainer>
      <EnergyDecompositionBarContainer>
        <EnergyDecompositionBar protein={protein} fat={fat} carb={carb} />
      </EnergyDecompositionBarContainer>
      <Box marginTop="20px">
        <Macros protein={protein} fat={fat} carb={carb} />
      </Box>
    </Container>
  );
};
