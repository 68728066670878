import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { MediaDto } from "./common";

export async function requestUpdateRecipe(
  id: number,
  recipe: CreateRecipeRequest,
) {
  await fetchData(`/dietitian/recipes/${id}`, APIMethods.PUT, recipe);
}

export async function requestCreateRecipe(
  recipe: CreateRecipeRequest,
): Promise<ApiResponse<RequestCreateRecipeResponse>> {
  const response = await fetchData(
    `/dietitian/recipes`,
    APIMethods.POST,
    recipe,
  );

  return requestCreateRecipeResponseSchema.validate(response);
}

export interface CreateRecipeFoodRecipeRequest {
  id: number | null;
  foodId: number;
  grams: number;
  foodMeasureId: number;
  sortOrder: number;
}

export interface CreateRecipeRequest {
  id: number | null;
  name: string;
  nameEn: string;
  description: string;
  descriptionEn?: string;
  isComplex: boolean;
  servings: number;
  reviewed: boolean;
  foodRecipe: CreateRecipeFoodRecipeRequest[];
  tags: number[];
  version: number | null;
  media: MediaDto | null;
}

interface RequestCreateRecipeResponse {
  id: number;
}

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
});

const requestCreateRecipeResponseSchema = yup.object().shape({
  data: recipeSchema.required(),
});
