import { Dispatch, FC, SetStateAction } from "react";
import {
  ArrowHorizontal,
  Calendar,
  Carrot,
  ClosedBook,
  Doc,
  DocWithApple,
  ExclamationMarkTriangle,
  Home,
  OpenBook,
  PaperPlane,
  PenInSquare,
  People,
  QuestionMarkCircle,
  Survey,
} from "@assets/icons/DrawerMenu";
import { useAppTranslation, useWhatsNew } from "@hooks";
import { SvgIconProps, Tooltip } from "@mui/material";
import {
  CALENDAR,
  DASHBOARD,
  DIETS,
  MEALS,
  PATIENTS,
  PRODUCTS,
  PROGRAMS,
  QUESTIONNAIRES,
  RECIPES,
  SECTIONS,
} from "@routes";
import {
  CollapseStyled,
  DrawerStyled,
  ExpandButtonStyled,
} from "./DrawerMenu.styled";
import { MenuGroup } from "./MenuGroup";
import { WhatsNewActionWrapper } from "../WhatsNewWrapper";
const triggerJira = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.showCollectorDialog && window.showCollectorDialog();
};

export interface MenuGroupIf {
  label: string;
  links: MenuOptionIf[];
}

export interface MenuOptionIf {
  link: string | null;
  label: string;
  icon: (props: SvgIconProps) => JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  wrapper?: FC<any>;
  activePaths?: string[];
}

export const NEWS_UPDATE_KEY = "newsUpdateKey";

interface DrawerMenuProps {
  open: boolean | undefined;
  setOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

export const DrawerMenu = ({ open, setOpen }: DrawerMenuProps) => {
  const { t } = useAppTranslation();
  const { handleButtonClick } = useWhatsNew();

  const groupedMenuButtons: MenuGroupIf[] = [
    {
      label: "sidemenu.dashboard",
      links: [
        {
          link: DASHBOARD,
          label: "sidemenu.dashboard",
          icon: Home,
        },
      ],
    },
    {
      label: "sidemenu.office",
      links: [
        {
          link: PATIENTS,
          label: "sidemenu.clients",
          icon: People,
        },
        {
          link: CALENDAR,
          label: "sidemenu.agenda",
          icon: Calendar,
        },
        {
          link: QUESTIONNAIRES,
          label: "sidemenu.surveys",
          icon: Survey,
        },
        {
          link: PROGRAMS,
          label: "sidemenu.programs",
          icon: OpenBook,
        },
      ],
    },
    {
      label: "sidemenu.bases",
      links: [
        {
          link: DIETS,
          label: "sidemenu.diets",
          icon: ClosedBook,
        },
        {
          link: RECIPES,
          label: "sidemenu.recipes",
          icon: Doc,
          activePaths: [RECIPES, MEALS],
        },
        {
          link: PRODUCTS,
          label: "sidemenu.products",
          icon: Carrot,
        },
      ],
    },
    {
      label: "sidemenu.knowledge",
      links: [
        {
          link: SECTIONS,
          label: "sidemenu.sections",
          icon: PenInSquare,
        },
      ],
    },
    {
      label: "sidemenu.others",
      links: [
        {
          link: null,
          label: "sidemenu.referral",
          icon: PaperPlane,
          disabled: true,
        },
        {
          link: null,
          label: "sidemenu.knowledge_base",
          icon: QuestionMarkCircle,
          wrapper: WhatsNewActionWrapper,
          onClick: handleButtonClick,
        },
        {
          link: null,
          label: "sidemenu.report",
          icon: ExclamationMarkTriangle,
          onClick: triggerJira,
        },
      ],
    },
  ];

  return (
    <DrawerStyled variant="permanent">
      <CollapseStyled orientation="horizontal" in={open} collapsedSize={40}>
        <Tooltip
          key={String(open)}
          title={open ? t("common.collapse") : t("common.expand")}
          placement="right"
        >
          <div>
            <ExpandButtonStyled onClick={() => setOpen(!open)} size="small">
              <ArrowHorizontal
                className={open ? "rotate-180" : ""}
                size="w-6 h-6"
              />
            </ExpandButtonStyled>
          </div>
        </Tooltip>

        {groupedMenuButtons.map((group, id) => {
          const isDashboard = id === 0;

          return (
            <MenuGroup
              key={id}
              isDashboard={isDashboard}
              open={!!open}
              group={group}
            />
          );
        })}
      </CollapseStyled>
    </DrawerStyled>
  );
};
