import ProgramsSearch from "@views/dietician/Programs/ProgramsSearch";
import PatientProgramChoiceGrid from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGrid";
import ProgramFiltersDrawer from "@components/ProgramsFilters/ProgramFiltersDrawer";
import { Dispatch, useState } from "react";
import useFormDataMapper from "@views/dietician/Programs/hooks/useFormDataMapper";
import useClearProgramsSearchQuery from "@views/dietician/Programs/hooks/useClearProgramsSearchQuery";
import { useSearchProgramsDefaultQueryNew } from "@hooks/queries";
import useFetchSearchFilter from "@views/dietician/Programs/hooks/useFetchSearchFilter";
import usePaginationFields from "@views/dietician/Programs/hooks/usePaginationFields";
import { SelectedProgramType } from "@views/dietician/PatientProgramChoice/PatientProgramChoice";

type PatientProgramChoiceGridFilterProps = {
  selectedProgram: SelectedProgramType | null;
  setSelectedProgram: Dispatch<SelectedProgramType>;
};
const PatientProgramChoiceGridFilter = ({
  selectedProgram,
  setSelectedProgram,
}: PatientProgramChoiceGridFilterProps) => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const { requestParams } = useFormDataMapper();

  useClearProgramsSearchQuery();

  const {
    data: programs,
    isFetching,
    refetch,
    isFetched,
  } = useSearchProgramsDefaultQueryNew(requestParams, {
    keepPreviousData: true,
    enabled: false,
  });

  useFetchSearchFilter({ requestParams, isFetching, refetch, isFetched });
  const { pageField, perPageField } = usePaginationFields();

  return (
    <>
      <ProgramsSearch
        open={filterDrawerOpen}
        setOpen={() => setFilterDrawerOpen(true)}
      />
      <PatientProgramChoiceGrid
        programs={programs}
        isFetching={isFetching}
        pageField={pageField}
        perPageField={perPageField}
        selectedProgram={selectedProgram}
        setSelectedProgram={setSelectedProgram}
      />
      <ProgramFiltersDrawer
        total={programs?.meta.total ?? 0}
        onClose={() => setFilterDrawerOpen(false)}
        open={filterDrawerOpen}
      />
    </>
  );
};

export default PatientProgramChoiceGridFilter;
